
import React, { Component, useEffect, useRef  } from 'react';
class Vijesti
 extends Component {
    constructor(props) {
        super(props);
    }
    state = { 
      

     }





    render() { 
        return ( 
            <div>
            <div style={{ float: "left" }}>{this.state.vijesti}</div>
            <div style={{ float: "left" }}>{this.state.notifikacije}</div>
            </div>
            );
    }
}
 
export default Vijesti
;