import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import "firebase/compat/database";
import "firebase/compat/storage";

/*var firebaseConfig = {
  apiKey: "AIzaSyBeO6UxsonkrGc3qq9RNQuWT4vxnuclC_0",
  authDomain: "vjezba-8a0fd.firebaseapp.com",
  databaseURL: "https://vjezba-8a0fd.firebaseio.com",
  projectId: "vjezba-8a0fd",
  storageBucket: "vjezba-8a0fd.appspot.com",
  messagingSenderId: "130665193368",
  appId: "1:130665193368:web:db516caebfa86c393055ed"
};*/
// Initialize Firebase
var firebaseConfig = {
  apiKey: "AIzaSyBP2izPPfqalSALHEzpEv5XSrcOEgTArLw",
  authDomain: "kdsrs-f1c69.firebaseapp.com",
  databaseURL: "https://kdsrs-f1c69-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "kdsrs-f1c69",
  storageBucket: "kdsrs-f1c69.appspot.com",
  messagingSenderId: "1098403368611",
  appId: "1:1098403368611:web:3a2f14c55e4cd2aa76f916"

};
const drugi = firebase.initializeApp(firebaseConfig, "drugi");


//secondUser.auth().signOut();

export default drugi;
