import React, { Component } from 'react';
import logo from "../logo_komora_800.png";
import google from "../googleplay.png";
import Clanovi from "./clanovi";
import Jedan from "./jedan";
import Edukacije from "./edukacije";
import Izvjestaji from "./izvjestaji";
import Vijesti from "./vijesti";
import { login } from "../funkcije.js";
import firebase from "../firebase.js";
import md5 from "md5";


class Glavna extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            menuHtml: "",
            bodyHtml: "",
            logoHtml: <img src={logo}  />, 
         }
    }

    header = {
        height: "110px",
        width: "100%",
        border: "1px solid red",
    
        //background: "#eeeeee",
        background: "#f7f7ed",
        position: "fixed",
        "z-index": "10",
        "vertical-align": "middle",
        "text-align": "center",
      };

      header2 = {
        height: "100%",
        width: "100%",
        background: "#f7f7ed",
        position: "fixed",
        overflow: "scroll",
        "z-index": "10",
        "vertical-align": "middle",
        "text-align": "center",
      };

      slika2 = {
        "margin-top": "120px",
        "margin-bottom": "10px",
        //height: "300px",
        width: "300px",
      };

    

      button = {
        width: "180px",
      };

      download = {
        //height: "80px",
        width: "200px",
      };

      log = {
        height: "37px",
        width: "200px",
        "vertical-align": "middle",
        "text-align": "center",
        margin: "10px",
      };

    logoStyle = {
        height: "90px",
        width: "350px",
        //marginLeft: "-30px",
      };

   
      componentDidMount() {
      
        //this.login();
        this.ss();
        //alert(md5(""));

      }
      ss = () => {
      
        this.setState({
          menuHtml: (
            <div style={this.header2}>
              <img src={logo} style={this.slika2} />
              <br />
              <br />
              <br />
              <input
                style={this.log}
                type="text"
                id="korisnik"
                placeholder="korisnik"
              />
    
              <input
                style={this.log}
                type="password"
                id="lozinka"
                placeholder="lozinka"
              />
              <button
                className="btn btn-danger m-1"
                onClick={() => this.login()}
                style={this.button}
              >
                Prijavi se
              </button>
              <br />
               
              <br />
              <br />
              <br />
              <a href="https://play.google.com/store/apps/details?id=com.bitdental.kdsrs"><img className="m-3" src={google} style={this.download}  /></a>
    
            </div>
          ),
        });
      };
    
      
      login = () => {

        let korisnik = document.getElementById("korisnik").value;

        let email = korisnik + "@komorastomatologa.com";
    
        let lozinka = document.getElementById("lozinka").value;
      

          login(email, lozinka)
        
            .then((rezultat) => {
        
              if(md5(rezultat.user.email)=="6136c048323f2e24ee84fffa0ee63c3b"){ this.start(); } else{this.start2(rezultat.user.uid)}
              
              
            })
            .catch(function (error) {
              alert("Pogrešan korisnik ili lozinka");
              
            });
    
      };

    

      start = () => {
        this.setState({
            menuHtml: (
            <div>
              <div style={this.header}>
               
                <img className="m-2 mr-5" src={logo} style={this.logoStyle} />
    
                <button
                  className="btn btn-danger m-1"
                  onClick={() => this.promeni(<Clanovi />)}
                  style={this.button}
                >
                  Članovi
                </button>
                <button
                  className="btn btn-danger m-1"
                  onClick={() => this.promeni(<Edukacije />)}
                  style={this.button}
                >
                  Novi unosi
                </button>
    
                <button
                  className="btn btn-danger m-1"
                  style={this.button}
                  onClick={() => this.promeni(<Izvjestaji />)}
                >
                  Izvještaji
                </button>
                <label className="m-2">
                  V2
                  </label>
               
    
              </div>
              
            </div>
          ),
        });
        this.setState({ bodyHtml: <Clanovi /> });
      };

      start2 = (uid) => {
        this.setState({
            menuHtml: (
            <div>
              <div style={this.header}>
                <img className="m-2 mr-5" src={logo} style={this.logoStyle} />
    
               
                
    
              </div>
            </div>
          ),
        });
        this.setState({ bodyHtml: <Jedan user={uid}/> });
      };

      promeni = (pp) => {
        this.setState({ bodyHtml: pp });
        //alert("Klikammmm")
      };
    
    render() { 
        return (  
            <div>
            <div>{this.state.menuHtml}</div>
            <div
              style={{
                clear: "both",
                position: "relative",
                top: "105px",
                "z-index": "9",
              }}
            >
              {this.state.bodyHtml}
            </div>
          </div>
            
        );
    }
}
 
export default Glavna;