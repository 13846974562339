
import React, { Component, useEffect, useRef  } from 'react';
import Form from "react-bootstrap/Form";
import firebase from "../firebase.js";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Input from "react-bootstrap/InputGroup";
import Resizer from "react-image-file-resizer";
import ReactToPrint from "react-to-print";
import stomatologLogo from "../stomlogo.png";
import logoa4 from "../logoa4.png";
import "moment/locale/sr";
import { DatePickerRC, DatePickerInput } from "rc-datepicker";
import "./myStyles.css";
import "rc-datepicker/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-medium-image-zoom/dist/styles.css'
import {
    upisiClana,
    dajSveClanove,
    ordinacije,
    mjesta,
    regije,
    specijalizacije,
    titule,
    statusi,
    statusiZaposlenje,
    obrisiDogadjajN,
    bodoviN,
    kongresiSifre,
    potvrdiBodove,
    upisiEdukaciju,
    obrisiEdukaciju,
    dodajKorisnika,
    upisiLozinku,
    izmjeniOrdinaciju,
    obrisiOrdinaciju,
    upisiOrdinaciju,
    upisiMjesto

  } from "../funkcije.js";

import ReactExport from "react-data-export";
var ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class Edukacije extends Component {
    
        state = { 
          
            brojClanova: "",
            brojOrdinacija: "",
            baza: [],
            bazaP: [],
            lista: "",
            clan: "",
            profil: "",
            uplate: "",
            uplateN: "",
            uplateO: [],
            oruzjeObjekat: [],         
            uplateSifre: [],
            datumUplate: "",
            datumLicence: "",
            dateEdukacije: "",
            datumRodjenja: "",
            day: "",
            kongresiEkran: "",
            noviClanEkran: "",
            ordinacijeEkran: "",
            zanimanje: [],
            funkcija: [],
            bodoviNoviO: [],
            kongresiSifreO: [],
            sprema: [],
            dokumenti: [],
            im: "",
            pictures: [],
            uriSlike:"",
            uriDokumenta: logoa4,
            pocetak: "",
            kraj: "",
            month: 10,
            year: 2021,
            statusiO: [],
            statusiZaposlenjeO: [],
            tituleO: [],
            specijalizacijeO: [],
            sudCastiO: [],
            skupstinaO: [],
            nadzorniOdborO: [],
            komisijeO: [],
            izvrsniOdborO: [],
            disciplinskiTuzilacO: [],
            ordinacijeO: [],
            ordinacijeOfilter: [],
            mjestaO: [],
            regijeO: [],
            zoomSlika: "",
            zaposleniO: [],
            ordinacijeOZaposleni: [],
            ordinacijeOfilterZaposleni: [],
            najvecaLicenca: 0,
         }

         styles6 = {
            border: "1px solid red",
            width: "1260px",
            height: "600px",
            background: "#f7f7ed",
            //left: "50%",
            "vertical-align": "middle",
            "text-align": "center",
            position: "absolute",
          };

          styles7 = {
            border: "1px solid red",
            width: "1260px",
            height: "550px",
            background: "#f5cbcb",
            //left: "50%",
            "vertical-align": "middle",
            "text-align": "center",
            position: "absolute",
          };

          styles8 = {
            border: "1px solid red",
            width: "1260px",
            height: "700px",
            background: "#f7f7ed",
            //left: "50%",
            "vertical-align": "middle",
            "text-align": "center",
            position: "absolute",
          };
          styles9 = {
            border: "1px solid red",
            width: "1260px",
            height: "200px",
            background: "#f5cbcb",
            //left: "50%",
            "vertical-align": "middle",
            "text-align": "center",
            position: "absolute",
          };

          stylesG = {
           
            width: "1260px",
            height: "600px",           
            left: "50%",
            "margin-left": "-630px",
           
            position: "absolute",
        
            //left: "1%"
          };
          stylesG2 = {
           
            width: "1260px",
            height: "600px",           
            left: "50%",
            "margin-left": "-630px",
            "margin-top": "620px",
            position: "absolute",
        
            //left: "1%"
          };
          stylesG25 = {
                   
            left: "50%",
            "margin-left": "-630px",
            "margin-top": "1150px",
            position: "absolute",
        
        
            //left: "1%"
          };
          stylesG3 = {
           
            width: "1260px",
            height: "600px",           
            left: "50%",
            "margin-left": "-630px",
            "margin-top": "1190px",
            position: "absolute",
        
            //left: "1%"
          };
          stylesG4 = {
           
            width: "1260px",
            height: "200px",           
            left: "50%",
            "margin-left": "-630px",
            "margin-top": "1920px",
            position: "absolute",
        
            //left: "1%"
          };

          slikaDok = {
            width: "250px",
            height: "353px",
            "object-fit": "scale-down",
            //"margin-top": "10px",
          };


          styleHH = {
          
            height: "35px",
         
          };
          styleRR = {
            padding: "10px",
            background: "#f5cbcb",
            border: "1px solid red",
         
          };

          styleBroj = {
            border: "1px solid red",
            width: "70px",
            height: "35px",
            background: "#f7f7ed",
            float: "left",
            "text-align": "center",
            "vertical-align": "middle",
            padding: "5px",
          };

         componentDidMount() {

            this.danas();    
            this.dajKongresiSifre(); 
            this.statusiF();
            this.statusiZaposlenjeF();
            this.tituleF();
            this.specijalizacijeF();
            this.regijeF();          
            this.mjestaF();
            this.dajSveClanoveF();
            this.ordinacijeF();
            //this.dajBodoveNove();


            let filterMjesto = document.getElementById("filterMjesto");
            let filterSektor = document.getElementById("filterSektor");
            let filterVrsta = document.getElementById("filterVrsta");
            let filterSlova = document.getElementById("pretragaI");
            let filterRegija = document.getElementById("filterRegija");

            filterSlova.addEventListener("keyup", (ff) => {
              if (filterSlova.value.length > 2 || filterSlova.value == "") {
               
                let slova=filterSlova.value;
                let sektorF=filterSektor.value;
                let vrstaF=filterVrsta.value;
                let mjestoF=filterMjesto.value;
                let regijaF=filterRegija.value;
                this.pretraga(slova,sektorF,vrstaF,mjestoF,regijaF);               
              }
            });

            filterSektor.addEventListener("change", (ff) => {
              let slova=filterSlova.value;
                let sektorF=filterSektor.value;
                let vrstaF=filterVrsta.value;
                let mjestoF=filterMjesto.value;
                let regijaF=filterRegija.value;
                this.pretraga(slova,sektorF,vrstaF,mjestoF,regijaF);                
             });

             filterVrsta.addEventListener("change", (ff) => {
              let slova=filterSlova.value;
                let sektorF=filterSektor.value;
                let vrstaF=filterVrsta.value;
                let mjestoF=filterMjesto.value;
                let regijaF=filterRegija.value;
                this.pretraga(slova,sektorF,vrstaF,mjestoF,regijaF);                
             });

             filterMjesto.addEventListener("change", (ff) => {
              let slova=filterSlova.value;
                let sektorF=filterSektor.value;
                let vrstaF=filterVrsta.value;
                let mjestoF=filterMjesto.value;
                let regijaF=filterRegija.value;
                this.pretraga(slova,sektorF,vrstaF,mjestoF,regijaF);               
             });

             filterRegija.addEventListener("change", (ff) => {
              let slova=filterSlova.value;
                let sektorF=filterSektor.value;
                let vrstaF=filterVrsta.value;
                let mjestoF=filterMjesto.value;
                let regijaF=filterRegija.value;
                this.pretraga(slova,sektorF,vrstaF,mjestoF,regijaF);          
             });



          }

         danas = () => {
            let da = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
        
            this.setState({ day: da });
        
            this.setState({ datumUplate: da });
           
          };

          pretraga = (slova,sektorF,vrstaF,mjestoF,regijaF) => {

            let ordL = [];
            this.setState({ ordinacijeOfilter: [] });
            
            this.state.ordinacijeO.forEach(function (ss) {
             
              try {
               
                let naziv= ss.naziv;
                let sektor= ss.sektor;
                let vrsta= ss.vrsta;
                let mjesto= ss.mjestoId;
                let regija= ss.regijaId;
                
                if (
                  (naziv.toLowerCase().includes(slova.toLowerCase())
                
                  )
        
                  && ( sektorF == "sve" ||
                  sektorF == sektor
                 )
        
                  && ( vrstaF == "sve" ||
                  vrstaF == vrsta
                 )

                 && ( mjestoF == "sve" ||
                 mjestoF == mjesto
                )
                 && ( regijaF == "sve" ||
                regijaF == regija
                 )
    
                )
                  ordL.push(
                    ss
                  );
              } catch (er) {
                alert(ss.key);
              }
            });
        
            this.setState({ ordinacijeOfilter: ordL });
    
            this.setState({
              brojOrdinacija: (
                <div >
                  <label >{ordL.length}</label>
                  </div>
    
                  )});


                  let ordLZ = [];
                  this.setState({ ordinacijeOfilterZaposleni: [] });
                  
                  this.state.ordinacijeOZaposleni.forEach(function (zz) {
                   
                    try {
                     
                      let naziv= zz.naziv;
                      let sektor= zz.sektor;
                      let vrsta= zz.vrsta;
                      let mjesto= zz.mjestoId;
                      let regija= zz.regijaId;
                      
                      if (
                        (naziv.toLowerCase().includes(slova.toLowerCase())
                      
                        )
              
                        && ( sektorF == "sve" ||
                        sektorF == sektor
                       )
              
                        && ( vrstaF == "sve" ||
                        vrstaF == vrsta
                       )
      
                       && ( mjestoF == "sve" ||
                       mjestoF == mjesto
                      )
                       && ( regijaF == "sve" ||
                      regijaF == regija
                       )
          
                      )
                        ordLZ.push(
                          zz
                        );
                    } catch (er) {
                      alert(zz.key);
                    }
                  });
              
                  this.setState({ ordinacijeOfilterZaposleni: ordLZ });
          
                
          
          };


         kongresiForma = () => {

            //this.setState({ uriDokumenta: ""});
            let bodoviNovi = this.state.bodoviNoviO;
            let bodoviNoviX=[];
            if(bodoviNovi=="" || bodoviNovi==null || bodoviNovi==undefined)
            { 
              bodoviNoviX=[];
            }else{
              bodoviNoviX=bodoviNovi.slice().reverse();
             
            }

            let kongresiSifreL=this.state.kongresiSifreO;
            let kongresiSifreX=[];
            if(kongresiSifreL=="" || kongresiSifreL==null || kongresiSifreL==undefined)
            { 
                kongresiSifreX=[];
            }else{
                kongresiSifreX=kongresiSifreL.slice().reverse();
             
            }
            
        
            this.setState({
              kongresiEkran: (
                <div className=" mt-3 ps-2 pe-2 pb-2 pt-1" style={this.styles6}>     
              
                  <Form  className="text-center mt-1" onSubmit={this.dodajEdukaciju}>
                  <h3>Edukacije</h3>
                    <Row>
                      
                        <Form.Control
                          id="selektovanDogadjajI"
                          as="select"
    
                          multiple
                          style={{ height: "170px", width: "1250px",  overflow: "scroll", }}
                          className="ms-2"
                        >                 
                          {bodoviNoviX.map((d) => {
                     

                            if (d.potvrda == "ne") {

                            var dat=d.datum.split("-")[2]+"."+d.datum.split("-")[1]+"."+d.datum.split("-")[0]                    
                            return (
                              <option
                                className="text-danger"
                                onClick={() => this.slikeUbaci(d.slika)}
                                value={
                                  "nove;"+d.key+";"+d.slika+";"+d.uid
                                }
                              >
                                {dat+" | bodova-" +d.bodovi+ " | " +d.naziv+ " | " +d.drzava+ " | " +d.grad + " | " +d.ime + " "+d.prezime+ " | licenca-"+d.licenca}
                              </option>
                            );
                              }
    
    
                          
                          }) }
    

                        </Form.Control>
             
         
                      <Col sm="9">
                        <div className="mt-1">
                       
                        </div>

                        <div>
                        <Button
                         id="brisiBodove"
                         className="btn btn-outline-danger  centerButton me-3 mt-1"
                         onClick={() => this.brisiBodove()}
                         variant="outline-dark"
                         size="sm"
                        >
                            Briši edukaciju
                        </Button>

                        <Button
                         id="potvrdiI"
                         className="btn btn-outline-danger  centerButton ms-3 mt-1"
                         onClick={() => this.potvrdiDogadjaj()}
                         variant="outline-danger"
                         size="sm"
                        >
                            Potvrdi edukaciju
                        </Button>
                        </div>


                        <Input id="kongresSifraI" as="select" style={{ width: "950px",height: "180px" , overflow: "scroll"}} className="mr-1 mt-2 mb-3"  multiple defaultValue={"-"}>
                        <option value="-">-</option>
                      {kongresiSifreX.map((d) => {
    
                      var dat=d.datum.split("-")[2]+"."+d.datum.split("-")[1]+"."+d.datum.split("-")[0]

                          return (
                            
                            <option
                          
                              value={
                                d.datum+";*" + d.naziv + ";*" + d.bodovi+ ";*" + d.grad+ ";*" + d.drzava+ ";*" + d.sd+ ";*" + d.key
                              }
                            >
                              {dat+" | bodova-" +d.bodovi+" | " +d.naziv+ " | " +d.bodovi}
                            </option>
                          );
                      })}
                    </Input>
                    <div className="mt-3">
             
                <Row>
                    <Col sm="2">
                    <DatePickerInput                
                    id="datumEdukacijeI"
                    defaultValue={""}
                    onChange={this.dateEdukacije}
                    required
                    onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    </Col>

                    <Col sm="8">
                    <Form.Control
                    type="text"
                    id="nazivEdukacijeI"
                    placeholder="Naziv edukacije"
                    required
                    />
                    </Col>

                    <Col sm="2">
                    <Form.Control
                     placeholder="Broj bodova"
                     id="brojBodovaI"
                     type="number"
                     min="1"
                     step="1"
                     required
                    />
                    </Col>
                </Row>

                <Row className="mt-1 mb-2">
                    <Col sm="4">
                    <Form.Control
                    type="text"
                    id="drzavaEdukacijeI"
                    placeholder="Država"
                    required
                    />
                    </Col>

                    <Col sm="4">
                    <Form.Control
                    type="text"
                    id="gradEdukacijeI"
                    placeholder="Grad"
                    required
                    />
                    </Col>

                    <Col sm="4">
                    <Form.Control id="sdEdukacijeI" as="select">
                      <option value="d">domaći</option>
                      <option value="s">strani</option>
                   </Form.Control>
                    </Col>
                </Row>

                    </div>
    
                        <div>
    
                        <Button
                          id="dodajEdukacijuI"
                          className="btn btn-danger"
                          type="submit"
                          size="sm"                      
                        >
                          Dodaj novu edukaciju
                        </Button>
                     
    
                      <Button
                      id="brisiEdukacijuI"
                      className="btn btn-outline-danger  centerButton ms-2"
                      onClick={() => this.brisiEdukaciju()}
                  
                      variant="outline-dark"
                      size="sm"
                      >
                      Briši edukaciju
                      </Button>
    
                    
                      </div>
    
  
                      </Col>
                      
                      <Col sm="3">
                        
                        <img
                          id="sliketina"
                          style={this.slikaDok}
                          className=" ms-2 mt-1"
                          onClick={this.handleShowDialog}
                        />
                       
                   
                      </Col>
                    </Row>
        
    
                  </Form>
                </div>
              ),
            });
            
                this.slikeUbaci("");
            
          };

          
         noviClanForma = () => {

          let ordi=[];
          this.state.ordinacijeO.map((d) => {
            var naziv=d.naziv;
            naziv=naziv.replace("\"","")
            naziv=naziv.replace("\"","")
            naziv=naziv.replace("ZU stom.ambu.","")
            naziv=naziv.replace("ZU spec.stom.ambu.","")
            naziv=naziv.trim();
            naziv=naziv.trimStart();
            var id=d.id;
  
            ordi.push({
              naziv: naziv,
              id: id,
            })
  
           ordi= ordi.sort((a,b) => a.naziv > b.naziv ? 1 : b.naziv > a.naziv ? -1 : 0);
          })
  

          //this.setState({ uriDokumenta: ""});
          let bodoviNovi = this.state.bodoviNoviO;
          let bodoviNoviX=[];
          if(bodoviNovi=="" || bodoviNovi==null || bodoviNovi==undefined)
          { 
            bodoviNoviX=[];
          }else{
            bodoviNoviX=bodoviNovi.slice().reverse();
           
          }

          let kongresiSifreL=this.state.kongresiSifreO;
          let kongresiSifreX=[];
          if(kongresiSifreL=="" || kongresiSifreL==null || kongresiSifreL==undefined)
          { 
              kongresiSifreX=[];
          }else{
              kongresiSifreX=kongresiSifreL.slice().reverse();
           
          }
          
      
          this.setState({
            noviClanEkran: (
              <div className=" mt-3 ps-2 pe-2 pb-2 pt-1" style={this.styles7}>     
            
            <Form onSubmit={this.dodajClana}>
              <h3>Novi član</h3>
              <br/>
                <Row>
                  <Col sm="4">
                <h6>Broj licence:</h6>
                <Form.Control
                  type="text"
                  id="brojLicenceI"
                  placeholder="Broj licence"
                  required
                />
                  </Col>
                  <Col  sm="4">
                <h6>Datum licence:</h6>
                <DatePickerInput
                  id="datumLicenceI"
                  defaultValue={""}
                  required
                  onKeyDown={(e) => {
                    e.preventDefault();
                 }}
                  onChange={this.datumLicence}
                />
                  </Col>
           
                  <Col  sm="4">
                <h6>Titula:</h6>
                <Input style={this.styleHH} id="titulaI" as="select">
                  {this.state.tituleO.map((ss) => {
             
                      return <option value={ss.broj}>{ss.naziv}</option>;
                    
                  })}
                </Input>
                </Col>
                </Row>
               <Row>
               <Col sm="4">
                <h6>Ime: </h6>
                <Form.Control
                  required
                  type="text"
                  id="imeI"
                  placeholder="Ime člana"
                  
                />
                </Col>
                  <Col  sm="4">
                <h6>Prezime:</h6>
                <Form.Control
                  required
                  type="text"
                  id="prezimeI"
                  placeholder="Prezime člana"
               
                />
                 </Col>
                 <Col  sm="4">
                <h6>Prezime 2:</h6>
                <Form.Control
                  
                  type="text"
                  id="prezime2I"
                  placeholder="Drugo prezime člana"
               
                />
                 </Col>
               
                </Row>
                <Row>
                   <Col  sm="4">
                <h6>JMBG:</h6>
                <Form.Control
                  required
                  type="text"
                  id="jmbgI"
                  placeholder="JMBG člana"
                  
                />
                </Col>
                <Col  sm="4">
                <h6>Pol:</h6>
                <Input style={this.styleHH} id="polI" as="select">
                <option value="M">Muški</option>
                <option value="Z">Ženski</option>
                </Input>
                </Col>
                <Col  sm="4">
                <h6>Specijalizacija:</h6>
                <Input style={this.styleHH} id="specijalizacijaI" as="select">
                
                  {this.state.specijalizacijeO.map((ss) => {
                   
                      return <option value={ss.id}>{ss.spec}</option>;
                    
                  })}
                </Input>
                </Col>
              
                </Row>

                <Row>
                <Col  sm="4">
                <h6>Specijalizacija2:</h6>
                <Input style={this.styleHH} id="specijalizacijaI2" as="select">
                
                  {this.state.specijalizacijeO.map((ss) => {
                   
                      return <option value={ss.id}>{ss.spec}</option>;
                    
                  })}
                </Input>
                </Col>
                <Col>
              <h6>Adresa:</h6>
                <Form.Control
                  required
                  type="text"
                  id="adresaI"
                  placeholder="Adresa člana"
                  
                />
               </Col>
                <Col  sm="4">
              <h6>Mjesto:</h6>
                <Input style={this.styleHH} id="mjestoI" as="select">
                  {this.state.mjestaO.map((ss) => {
                   
                      return <option value={ss.id}>{ss.mjesto}</option>;
                    
                  })}
                </Input>
                </Col>
               
                </Row>
                <Row>
                   <Col  sm="4">              
              <h6>Regija:</h6>
                <Input style={this.styleHH} id="regijaI" as="select">
                  {this.state.regijeO.map((ss) => {
                   
                      return <option value={ss.broj}>{ss.naziv}</option>;
                    
                  })}
                </Input>
                </Col>
                  <Col sm="4">
                <h6>E-mail:</h6>
                <Form.Control
                  
                  type="text"
                  id="emailI"
                  placeholder="E-mail"
                  
                />
               </Col>
                <Col  sm="4">
               <h6>Telefon:</h6>
                <Form.Control
                  required
                  type="text"
                  id="telefonI"
                  placeholder="Telefon"
               
                />
               </Col>
               
                </Row>
                <Row>
                   <Col  sm="4">
              <h6>Status zaposlenja:</h6>
                <Input style={this.styleHH} id="statusZaposlenjaI" as="select">
                  {this.state.statusiZaposlenjeO.map((ss) => {
                   
                      return <option value={ss.broj}>{ss.naziv}</option>;
                    
                  })}
                </Input>
                </Col> 
                <Col  sm="4">
                <h6>Ordinacija:</h6>
                <Input style={this.styleHH} id="ordinacijaI" as="select">
                <option value="0">NEPOZNAT</option>
                  {ordi.map((ss) => {
                  
                      return <option value={ss.id}>{ss.naziv}</option>;
                    
                  })}
                </Input>
                </Col>
              </Row>

               
                <Button
                  id="dodajClanaI"
                  className="m-4 btn btn-danger centerButton"
                  size="sm"
                  type="submit"
                >
                  Dodaj novog člana
                </Button>
              </Form>
              </div>
            ),
          });
          
              
          
        };

 

        datumLicence = (date) => {
          let ddd =
            date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
          this.setState({ datumLicence: ddd });
          
        };



        dodajClana = (ev) => {
          ev.preventDefault();
          
       
          let broj = document.getElementById("brojLicenceI").value;
  
          let tit = document.getElementById("titulaI").options[
            document.getElementById("titulaI").selectedIndex
          ].value;
  
          let ime = document.getElementById("imeI").value;
          ime = ime.toLowerCase();
          let ime1 = ime.slice(0, 1);
          let ime2 = ime.slice(1);
          ime = ime1.toUpperCase() + ime2;
      
          let prez = document.getElementById("prezimeI").value;    
          prez = prez.toLowerCase();
          let prezime1 = prez.slice(0, 1);
          let prezime2 = prez.slice(1);
          prez = prezime1.toUpperCase() + prezime2;
      
          let prez2 = document.getElementById("prezime2I").value;    
          prez2 = prez2.toLowerCase().trim();
          let prezime12 = prez2.slice(0, 1);
          let prezime22 = prez2.slice(1);
          prez2 = prezime12.toUpperCase() + prezime22;

          prez=prez+" "+prez2;
         // let status = document.getElementById("statusI").options[
           // document.getElementById("statusI").selectedIndex
         // ].value;
  
          let spec_id = document.getElementById("specijalizacijaI").options[
            document.getElementById("specijalizacijaI").selectedIndex
          ].value;
  
          let spec_id2 = document.getElementById("specijalizacijaI2").options[
            document.getElementById("specijalizacijaI2").selectedIndex
          ].value;
  
          let jmbg = document.getElementById("jmbgI").value;
          let adr = document.getElementById("adresaI").value;
          let mail = document.getElementById("emailI").value;
          let tel = document.getElementById("telefonI").value;
      
          let mes_id = document.getElementById("mjestoI").options[
            document.getElementById("mjestoI").selectedIndex
          ].value;
  
          let regijaId = document.getElementById("regijaI").options[
            document.getElementById("regijaI").selectedIndex
          ].value;
  
          let statusZaposlenje = document.getElementById("statusZaposlenjaI").options[
            document.getElementById("statusZaposlenjaI").selectedIndex
          ].value;
  
          let ord_id = document.getElementById("ordinacijaI").options[
            document.getElementById("ordinacijaI").selectedIndex
          ].value;

          let pol = document.getElementById("polI").options[
            document.getElementById("polI").selectedIndex
          ].value;
  

      
  
          let dat_lic =this.state.datumLicence;
           
  
          var dupli = true;
          this.state.baza.forEach((ll) => {
            if (ll.jmbg == jmbg ) {
              dupli = false;
            }
          });
      
      
        
          
          if (jmbg.length == 13 && dupli) {
            if (window.confirm("Dodajte novog člana?")) {

              let dugme = document.getElementById("dodajClanaI");
              dugme.disabled = true;

            var result = '';
            var characters = 'abcdefghijklmnopqrstuvwxyz';
            var charactersLength = characters.length;
            for ( var i = 0; i < 3; i++ ) {
              result += characters.charAt(Math.floor(Math.random() * 
         charactersLength));
           }

           var rand1=(Math.floor(Math.random() * 10)).toString();
           var rand2=(Math.floor(Math.random() * 10)).toString();
           var rand3=(Math.floor(Math.random() * 10)).toString();
           var randAll=rand1+rand2+rand3;
           var pass=result+randAll;
            //alert(pass);  

          let imeSifra = document.getElementById("imeI").value;
          imeSifra = imeSifra.toLowerCase().trim();
          let prezimeSifra = document.getElementById("prezimeI").value;    
          prezimeSifra = prezimeSifra.toLowerCase().trim();
          let prezimeSifra2 = document.getElementById("prezime2I").value;    
          prezimeSifra2 = prezimeSifra2.toLowerCase().trim();
          if(prezimeSifra2=="" || prezimeSifra2==null || prezimeSifra2===undefined ){
            var prefiks=imeSifra+"."+prezimeSifra+"."+broj;
          }else{
            var prefiks=imeSifra+"."+prezimeSifra+"."+prezimeSifra2+"."+broj;
          }
     
          prefiks=prefiks.replace(/ć/g, "c"); 
          prefiks=prefiks.replace(/š/g, "s");
          prefiks=prefiks.replace(/č/g, "c");
          prefiks=prefiks.replace(/đ/g, "dj");
          prefiks=prefiks.replace(/ž/g, "z");
         // alert(prefiks+"@komorastomatologa.com")
             dodajKorisnika(prefiks+"@komorastomatologa.com", pass).then((uu) => {
              if(uu===undefined){

              }else{
              upisiClana(
                adr,
                "da",
                broj,
                dat_lic,
                "1",             
                ime,
                "1",
                jmbg,
                "1",
                "1",
                mail,
                mes_id,
                regijaId,
                "1",
                ord_id,
                pol,
                prez,
                "1",
                spec_id,
                spec_id2,
                "1",
                statusZaposlenje,
                "1",
                tel,
                tit,
                uu.user.uid
              )
              .then(() => {
              upisiLozinku(uu.user.uid,pass);
               alert(prefiks+" član je dodan!");
               dugme.disabled = false;
               document.getElementById("brojLicenceI").value = "";
               document.getElementById("imeI").value = "";
               document.getElementById("prezimeI").value = "";
               document.getElementById("prezime2I").value = "";
               document.getElementById("jmbgI").value = "";              
               document.getElementById("emailI").value = "";
               document.getElementById("telefonI").value = "";              
               document.getElementById("adresaI").value = "";

               document.getElementById("titulaI").value = 1;
               document.getElementById("mjestoI").value = 0;
               document.getElementById("regijaI").value = 1;              
               document.getElementById("statusZaposlenjaI").value = 1;
               document.getElementById("ordinacijaI").value = 0;              
               document.getElementById("polI").value = "M";
               document.getElementById("specijalizacijaI").value = 0;   
               document.getElementById("specijalizacijaI2").value = 0;   
              
               this.setState({ datumLicence: "" });
               document.getElementById("datumLicenceI").value = "";
              
               let bb = [];
               this.setState({ baza: [] });
              
               dajSveClanove().then((snapshot) => {

                 let najvecaLicenca=0;

                 snapshot.forEach((ss) => {
                   let uid = ss.val().uid;
                   let adresa = ss.val().adr;
                
                  let bodoviX=[];
                   ss.child("bodovi").forEach((gg) => {
                 
                     let idd=gg.val().id;
                     let seminar=gg.val().seminar;
                     let dat=gg.val().dat;
                     let cif=gg.val().cif;
                     let dr_id=gg.val().dr_id;
                     let slika=gg.val().slika;
       
                   bodoviX.push({
       
                     id: idd,
                     seminar: seminar,
                     dat: dat,
                     cif: cif,
                     dr_id: dr_id,
                     slika: slika,
       
                   })
       
                   })
       
                   let uplateX=[];
                   ss.child("uplate").forEach((gg) => {
                 
                     let idd=gg.val().id;
                     let datumUplate=gg.val().datumUplate;            
                     let iznosUplate=gg.val().iznosUplate;
                     let dr_id=gg.val().dr_id;
                     let period=gg.val().period;
       
                     uplateX.push({
       
                     id: idd,
                     datumUplate: datumUplate,
                     iznosUplate: iznosUplate,
                     dr_id: dr_id,
                     period: period,
       
                   })
       
                   })
       
                   let broj = ss.val().broj;

                   let brojNumber=parseInt(broj);
                   if(brojNumber>najvecaLicenca){najvecaLicenca=brojNumber};

                   let datumLicence = ss.val().dat_lic.substring(0, 10);
                   let disciplinski = ss.val().disciplinski;
                   let id = ss.val().id;
                   let ime = ss.val().ime;
                   let izvrsni = ss.val().izvrsni;
                   let jmbg = ss.val().jmbg;
                   let komisija1 = ss.val().komisija1;
                   let komisija2 = ss.val().komisija2;
                   let mail = ss.val().mail;
                   let mejestoID = ss.val().mes_id;
                   let regijaID = ss.val().regijaId;
                   let nadzorni = ss.val().nadzorni;
                   let ordinacijaId = ss.val().ord_id;
                   let pol = ss.val().pol;
                   let prezime = ss.val().prez;
                   let skupstina = ss.val().skupstina;
                   let specijalizacijaId = ss.val().spec_id;
                   let specijalizacijaId2 = ss.val().spec_id2;
                   let status = ss.val().status;
                   let statusZaposlenje = ss.val().statusZaposlenje;
                   let sud = ss.val().sud;
                   let telefon = ss.val().tel;
                   let titula = ss.val().tit;
                   let uplate = ss.val().uplate;
                   let uplateNove = ss.val().uplateNove;
                   let androidStatus = ss.val().androidStatus;
                   let androidDozvola = ss.val().androidDozvola;
                   let slika = ss.val().slika;
       
                
       
                   let disciplinskiS = "";
                   this.state.disciplinskiTuzilacO.map((sss) => {
                     if (sss.broj == disciplinski) {
                       disciplinskiS = sss.naziv;
                     }
                   });
                   
                   let izvrsniS = "";
                   this.state.izvrsniOdborO.map((sss) => {
                     if (sss.broj == izvrsni) {
                       izvrsniS = sss.naziv;
                     }
                   });
                   let komisija1S = "";
                   this.state.komisijeO.map((sss) => {
                     if (sss.broj == komisija1) {
                       komisija1S = sss.naziv;
                     }
                   });
       
                   let komisija2S = "";
                   this.state.komisijeO.map((sss) => {
                     if (sss.broj == komisija2) {
                       komisija2S = sss.naziv;
                     }
                   });
                   let mejestoIDS = "";
                   this.state.mjestaO.map((sss) => {
                     if (sss.id == mejestoID) {
                       mejestoIDS = sss.mjesto;
                     }
                   });
                   let regijaIDS = "";
                   this.state.regijeO.map((sss) => {
                     if (sss.broj == regijaID) {
                       regijaIDS = sss.naziv;
                     }
                   });
                   let nadzorniS = "";
                   this.state.nadzorniOdborO.map((sss) => {
                     if (sss.broj == nadzorni) {
                       nadzorniS = sss.naziv;
                     }
                   });
                   let ordinacijaIdS = "";
                   this.state.ordinacijeO.map((sss) => {
                     if (sss.id == ordinacijaId) {
                       ordinacijaIdS = sss.naziv;
                     }
                   });
                   let skupstinaS = "";
                   this.state.skupstinaO.map((sss) => {
                     if (sss.broj == skupstina) {
                       skupstinaS = sss.naziv;
                     }
                   });
                   let specijalizacijaIdS = "";
                   this.state.specijalizacijeO.map((sss) => {
                     if (sss.id == specijalizacijaId) {
                       specijalizacijaIdS = sss.spec;
                     }
                   });
                   let specijalizacijaIdS2 = "";
                   this.state.specijalizacijeO.map((sss) => {
                     if (sss.id == specijalizacijaId2) {
                       specijalizacijaIdS2 = sss.spec;
                     }
                   });
                   let statusS = "";
                   this.state.statusiO.map((sss) => {
                     if (sss.broj == status) {
                       statusS = sss.naziv;
                     }
                   });
                   let statusZaposlenjeS = "";
                   this.state.statusiZaposlenjeO.map((sss) => {
                     if (sss.broj == statusZaposlenje) {
                       statusZaposlenjeS = sss.naziv;
                     }
                   });
                   
                   let sudS = "";
                   this.state.sudCastiO.map((sss) => {
                     if (sss.broj == sud) {
                       sudS = sss.naziv;
                     }
                   });
                   let titulaS = "";
                   this.state.tituleO.map((sss) => {
                     if (sss.broj == titula) {
                       titulaS = sss.naziv;
                     }
                   });
       
                  //alert(uplate[0]);
       
                   bb.push({
                     adresa: adresa,
                     bodovi: bodoviX,
                     broj: broj,
                     datumLicence: datumLicence,
                     disciplinski: disciplinski,
                     id: id,
                     ime: ime,
                     izvrsni: izvrsni,
                     jmbg: jmbg,
                     komisija1: komisija1,
                     komisija2: komisija2,
                     mail: mail,
                     mejestoID: mejestoID,
                     regijaID: regijaID,
                     nadzorni: nadzorni,
                     ordinacijaId: ordinacijaId,
                     pol: pol,
                     prezime: prezime,
                     skupstina: skupstina,
                     specijalizacijaId: specijalizacijaId, 
                     specijalizacijaId2: specijalizacijaId2,            
                     status: status,
                     statusZaposlenje: statusZaposlenje,
                     sud: sud,
                     telefon: telefon,
                     titula: titula,
                     uid: uid,
                     uplate: uplateX,
                     uplateNove: uplateNove,
                     androidStatus: androidStatus,
                     androidDozvola: androidDozvola,
                     slika: slika,
                     disciplinskiS: disciplinskiS,
                     izvrsniS: izvrsniS,
                     komisija1S: komisija1S,
                     komisija2S: komisija2S,
                     mejestoIDS: mejestoIDS,
                     regijaIDS: regijaIDS,
                     nadzorniS, nadzorniS,
                     ordinacijaIdS: ordinacijaIdS,
                     skupstinaS: skupstinaS,
                     specijalizacijaIdS: specijalizacijaIdS,
                     specijalizacijaIdS2: specijalizacijaIdS2,
                     statusS: statusS,
                     statusZaposlenjeS: statusZaposlenjeS,
                     sudS: sudS,
                     titulaS: titulaS,
                   });
                 });

                 bb= bb.sort((a,b) => parseInt(a.broj) > parseInt(b.broj) ? 1 : parseInt(b.broj) > parseInt(a.broj) ? -1 : 0);

                 this.setState({ baza: bb });
                 this.setState({ najvecaLicenca: najvecaLicenca });
       
               }).then((snapshot) => {
            
                dugme.disabled = false;
       
               })
       

              });

            }
              })
  
           
            }
          } else {
            alert("Neispravan ili dupli jmbg");
          }
        };

          dodajEdukaciju =(ev)=>{
            let dugme = document.getElementById("dodajEdukacijuI");
            dugme.disabled = true;
            if (window.confirm("Dodajte novu edukaciju?")) {
                ev.preventDefault();
                
                
            let datumEdukacije=this.state.dateEdukacije;

            let nazivEdukacije = document.getElementById("nazivEdukacijeI").value;
            let brojBodova = document.getElementById("brojBodovaI").value;
            let drzavaEdukacije = document.getElementById("drzavaEdukacijeI").value;
            let gradEdukacije = document.getElementById("gradEdukacijeI").value;

            let sd = document.getElementById("sdEdukacijeI").options[
              document.getElementById("sdEdukacijeI").selectedIndex
            ].value;
              
                upisiEdukaciju(datumEdukacije,nazivEdukacije,brojBodova,gradEdukacije,drzavaEdukacije,sd).then(() => {

                    kongresiSifre().then((snapshot) => {
                        let ff = [];
                         snapshot.forEach((kongres) => {
                           
                           let key =kongres.key;
                           let naziv = kongres.val().naziv;
                           let datum = kongres.val().datum;
                           let bodovi = kongres.val().bodovi;
                           let sd = kongres.val().sd;
                           let grad = kongres.val().grad;
                           let drzava = kongres.val().drzava;
                   
                           ff.push({
                           
                             key: key,
                             naziv: naziv,
                             datum: datum,
                             bodovi: bodovi,
                             sd: sd,
                             grad: grad,
                             drzava: drzava,
                           
                         });
                   
                       })
                         this.setState({ kongresiSifreO: ff });
                         
                 
                     }).then(() => {


                        let datumEdukacije=this.state.dateEdukacije;
                       document.getElementById("datumEdukacijeI").value=this.state.day;
                       document.getElementById("nazivEdukacijeI").value="";
                       document.getElementById("brojBodovaI").value="";
                       document.getElementById("drzavaEdukacijeI").value="";
                       document.getElementById("gradEdukacijeI").value="";

                        
                        document.getElementById("sdEdukacijeI").selectedIndex=0
                       
                         dugme.disabled = false;
                         this.kongresiForma();
                     });

                   
                })
               

           }else{
            ev.preventDefault();
            dugme.disabled = false;
           }

          }

          brisiEdukaciju = () => {
            let v = document.getElementById("kongresSifraI");
            let selektovanDogadjaj = v.options[v.selectedIndex].value.split(";*");
        
            let key = selektovanDogadjaj[6];
            let dugme = document.getElementById("brisiEdukacijuI");
            dugme.disabled = true;
            
            if(key=="-" || key==undefined  || key==""|| key==null){
                    alert("Niste selektovali ni jedanu edukaciju!")
                    dugme.disabled = false;
            }else{
                if (window.confirm("Obriši selektovanu edukaciju?")) {
              obrisiEdukaciju(key).then(() => {
                kongresiSifre().then((snapshot) => {
                    let ff = [];
                     snapshot.forEach((kongres) => {
                       
                       let key =kongres.key;
                       let naziv = kongres.val().naziv;
                       let datum = kongres.val().datum;
                       let bodovi = kongres.val().bodovi;
                       let sd = kongres.val().sd;
                       let grad = kongres.val().grad;
                       let drzava = kongres.val().drzava;
               
                       ff.push({
                       
                         key: key,
                         naziv: naziv,
                         datum: datum,
                         bodovi: bodovi,
                         sd: sd,
                         grad: grad,
                         drzava: drzava,
                       
                     });
               
                   })
                     this.setState({ kongresiSifreO: ff });
                    
             
                 }).then(() => {
                     dugme.disabled = false;
                     this.kongresiForma();
                 });

            })
        }else{
            dugme.disabled = false;

        }
        }


      
    

          }


          potvrdiDogadjaj = () => {

            let v = document.getElementById("kongresSifraI");
            let selektovanDogadjaj = v.options[v.selectedIndex].value.split(";*");
            let datum = selektovanDogadjaj[0];
            let naziv = selektovanDogadjaj[1];
            let bodovi = selektovanDogadjaj[2];
            let grad = selektovanDogadjaj[3];
            let drzava = selektovanDogadjaj[4];
            let sd = selektovanDogadjaj[5];
            let id = selektovanDogadjaj[6];
    
            let vrstaDogadjaja ="";
            let kljucDogadjaja ="";
            let uid="";
            try{
            let f = document.getElementById("selektovanDogadjajI");
            let selektovanDogadjaj = f.options[f.selectedIndex].value.split(";");
            vrstaDogadjaja = selektovanDogadjaj[0];
            kljucDogadjaja = selektovanDogadjaj[1];
            uid = selektovanDogadjaj[3];
            
            }catch(ero){
              //alert("Selektujte događaj za potvrdu!")
            }
    
            if (window.confirm("Potvrdi edukaciju?")) {
            if(naziv=="-" || naziv==undefined || naziv==null || vrstaDogadjaja=="" || vrstaDogadjaja=="stare" || kljucDogadjaja=="" || uid==""){
              alert("Selektujte edukaciju za potvrdu!")
            }else{
            potvrdiBodove(     
            uid,
            kljucDogadjaja,
            id,
            datum,
            naziv,
            bodovi,
            grad,
            drzava,
            sd,
            "da",
            ).then(() => {
            
    
            bodoviN().then((snapshot) => {
                let ff = [];
                snapshot.forEach((clan) => {
                  var uid = clan.key;
                  clan.forEach((bod) => {
                    let ime="";
                    let prezime="";
                    let licenca="";
                    this.state.baza.forEach((ccc) => {
                    if(ccc.uid==uid){
                        ime=ccc.ime;
                        prezime=ccc.prezime;
                        licenca=ccc.broj;
                    }
    
                    })
                  
                    let key =bod.key;
                     let id =bod.val().id;
                     let datum = bod.val().datum;
                     let naziv = bod.val().naziv;
                     let bodovi = bod.val().bodovi;
                     let grad = bod.val().grad;
                     let drzava = bod.val().drzava;
                     let sd = bod.val().sd;
                     let slika = bod.val().slika;
                     let potvrda= bod.val().potvrda;
                   
                     ff.push({
                      ime: ime, 
                      prezime: prezime,
                      licenca: licenca, 
                       uid: uid,
                       key: key,
                       id: id,
                       datum: datum,
                       naziv: naziv,
                       bodovi: bodovi,
                       grad: grad,
                       drzava: drzava,
                       sd: sd,
                       slika: slika,
                       potvrda: potvrda,
                  
                 });
               
               });
              
              
             })
               v.selectedIndex=0;
               this.setState({ bodoviNoviO: ff });
     
              }).then(() => {
                this.kongresiForma()
               });
         
    
            });
          }
        }
    
          }


          brisiBodove = () =>{

            if (window.confirm("Obriši selektovanu edukaciju?")) {
              let dugme = document.getElementById("brisiBodove");
              dugme.disabled = true;
              try{
              let v = document.getElementById("selektovanDogadjajI");
              let selektovanDogadjaj = v.options[v.selectedIndex].value.split(";");
              let vrstaDogadjaja = selektovanDogadjaj[0];
              let kljucDogadjaja = selektovanDogadjaj[1];
              let slikaDogadjaja = selektovanDogadjaj[2];
              let uid = selektovanDogadjaj[3];
           
              if(vrstaDogadjaja=="nove"){
    
              obrisiDogadjajN(uid, kljucDogadjaja).then(() => {
           
              dugme.disabled = false;
    
            bodoviN().then((snapshot) => {
                let ff = [];
                snapshot.forEach((clan) => {
                  var uid = clan.key;
                  clan.forEach((bod) => {
                    let ime="";
                    let prezime="";
                    let licenca="";
                    this.state.baza.forEach((ccc) => {
                    if(ccc.uid==uid){
                        ime=ccc.ime;
                        prezime=ccc.prezime;
                        licenca=ccc.broj;
                    }
    
                    })
                  
                    let key =bod.key;
                     let id =bod.val().id;
                     let datum = bod.val().datum;
                     let naziv = bod.val().naziv;
                     let bodovi = bod.val().bodovi;
                     let grad = bod.val().grad;
                     let drzava = bod.val().drzava;
                     let sd = bod.val().sd;
                     let slika = bod.val().slika;
                     let potvrda= bod.val().potvrda;
                   
                     ff.push({
                      ime: ime, 
                      prezime: prezime,
                      licenca: licenca, 
                       uid: uid,
                       key: key,
                       id: id,
                       datum: datum,
                       naziv: naziv,
                       bodovi: bodovi,
                       grad: grad,
                       drzava: drzava,
                       sd: sd,
                       slika: slika,
                       potvrda: potvrda,
                  
                 });
    
               });
              
             })
       
               this.setState({ bodoviNoviO: ff });
     
               }).then(() => {
                this.kongresiForma()
                 })
                
    
              });
              } 
    
    
            if (slikaDogadjaja == null || slikaDogadjaja == "" || slikaDogadjaja == undefined || slikaDogadjaja == "1" ||slikaDogadjaja == "undefined") {
    
            }
            else{
              
              var ref1 = firebase.storage().refFromURL(slikaDogadjaja);    
              ref1
                .delete()
                .then(function () {
                  
                })
                .catch(function (error) {
                  alert("Greska pri brisanju slike");
                });
            }
           
            } catch (err) {
              dugme.disabled = false;
              alert("Niste selektovali ni jedanu edukaciju!");
            }
     
            }
          }

          dateEdukacije = (date) => {

          var mm=(date.getMonth() + 1).toString();
          var dd=date.getDate().toString();
          if(mm.length==1){mm="0"+mm}
          if(dd.length==1){dd="0"+dd}
         
            let ddd =
              date.getFullYear() + "-" + mm + "-" + dd;
            this.setState({ dateEdukacije: ddd });
         
          };
    

          dajSveClanoveF = () => {
    
            let bb = [];
            this.setState({ baza: [] });
           
            dajSveClanove().then((snapshot) => {
              let najvecaLicenca=0;
              snapshot.forEach((ss) => {
                let uid = ss.val().uid;
                let adresa = ss.val().adr;
             
               let bodoviX=[];
                ss.child("bodovi").forEach((gg) => {
              
                  let idd=gg.val().id;
                  let seminar=gg.val().seminar;
                  let dat=gg.val().dat;
                  let cif=gg.val().cif;
                  let dr_id=gg.val().dr_id;
                  let slika=gg.val().slika;
    
                bodoviX.push({
    
                  id: idd,
                  seminar: seminar,
                  dat: dat,
                  cif: cif,
                  dr_id: dr_id,
                  slika: slika,
    
                })
    
                })


                let uplateX=[];
                ss.child("uplate").forEach((gg) => {
              
                  let idd=gg.val().id;
                  let datumUplate=gg.val().datumUplate;            
                  let iznosUplate=gg.val().iznosUplate;
                  let dr_id=gg.val().dr_id;
                  let period=gg.val().period;
    
                  uplateX.push({
    
                  id: idd,
                  datumUplate: datumUplate,
                  iznosUplate: iznosUplate,
                  dr_id: dr_id,
                  period: period,
    
                })
    
                })
    
                let broj = ss.val().broj;

                let brojNumber=parseInt(broj);
                if(brojNumber>najvecaLicenca){najvecaLicenca=brojNumber};

                let datumLicence = ss.val().dat_lic.substring(0, 10);
                let disciplinski = ss.val().disciplinski;
                let id = ss.val().id;
                let ime = ss.val().ime;
                let izvrsni = ss.val().izvrsni;
                let jmbg = ss.val().jmbg;
                let komisija1 = ss.val().komisija1;
                let komisija2 = ss.val().komisija2;
                let mail = ss.val().mail;
                let mejestoID = ss.val().mes_id;
                let regijaID = ss.val().regijaId;
                let nadzorni = ss.val().nadzorni;
                let ordinacijaId = ss.val().ord_id;
                let pol = ss.val().pol;
                let prezime = ss.val().prez;
                let skupstina = ss.val().skupstina;
                let specijalizacijaId = ss.val().spec_id;
                let specijalizacijaId2 = ss.val().spec_id2;
                let status = ss.val().status;
                let statusZaposlenje = ss.val().statusZaposlenje;
                let sud = ss.val().sud;
                let telefon = ss.val().tel;
                let titula = ss.val().tit;
                let uplate = ss.val().uplate;
                let uplateNove = ss.val().uplateNove;
                let androidStatus = ss.val().androidStatus;
                let androidDozvola = ss.val().androidDozvola;
                let slika = ss.val().slika;
    
             
    
                let disciplinskiS = "";
                this.state.disciplinskiTuzilacO.map((sss) => {
                  if (sss.broj == disciplinski) {
                    disciplinskiS = sss.naziv;
                  }
                });
                
                let izvrsniS = "";
                this.state.izvrsniOdborO.map((sss) => {
                  if (sss.broj == izvrsni) {
                    izvrsniS = sss.naziv;
                  }
                });
                let komisija1S = "";
                this.state.komisijeO.map((sss) => {
                  if (sss.broj == komisija1) {
                    komisija1S = sss.naziv;
                  }
                });
    
                let komisija2S = "";
                this.state.komisijeO.map((sss) => {
                  if (sss.broj == komisija2) {
                    komisija2S = sss.naziv;
                  }
                });
                let mejestoIDS = "";
                this.state.mjestaO.map((sss) => {
                  if (sss.id == mejestoID) {
                    mejestoIDS = sss.mjesto;
                  }
                });
                let regijaIDS = "";
                this.state.regijeO.map((sss) => {
                  if (sss.broj == regijaID) {
                    regijaIDS = sss.naziv;
                  }
                });
                let nadzorniS = "";
                this.state.nadzorniOdborO.map((sss) => {
                  if (sss.broj == nadzorni) {
                    nadzorniS = sss.naziv;
                  }
                });
                let ordinacijaIdS = "";
                this.state.ordinacijeO.map((sss) => {
                  if (sss.id == ordinacijaId) {
                    ordinacijaIdS = sss.naziv;
                  }
                });
                let skupstinaS = "";
                this.state.skupstinaO.map((sss) => {
                  if (sss.broj == skupstina) {
                    skupstinaS = sss.naziv;
                  }
                });
                let specijalizacijaIdS = "";
                this.state.specijalizacijeO.map((sss) => {
                  if (sss.id == specijalizacijaId) {
                    specijalizacijaIdS = sss.spec;
                  }
                });
                let specijalizacijaIdS2 = "";
                this.state.specijalizacijeO.map((sss) => {
                  if (sss.id == specijalizacijaId2) {
                    specijalizacijaIdS2 = sss.spec;
                  }
                });
                let statusS = "";
                this.state.statusiO.map((sss) => {
                  if (sss.broj == status) {
                    statusS = sss.naziv;
                  }
                });
                let statusZaposlenjeS = "";
                this.state.statusiZaposlenjeO.map((sss) => {
                  if (sss.broj == statusZaposlenje) {
                    statusZaposlenjeS = sss.naziv;
                  }
                });
                
                let sudS = "";
                this.state.sudCastiO.map((sss) => {
                  if (sss.broj == sud) {
                    sudS = sss.naziv;
                  }
                });
                let titulaS = "";
                this.state.tituleO.map((sss) => {
                  if (sss.broj == titula) {
                    titulaS = sss.naziv;
                  }
                });
    
               //alert(uplate[0]);
    
                bb.push({
                  adresa: adresa,
                  bodovi: bodoviX,
                  broj: broj,
                  datumLicence: datumLicence,
                  disciplinski: disciplinski,
                  id: id,
                  ime: ime,
                  izvrsni: izvrsni,
                  jmbg: jmbg,
                  komisija1: komisija1,
                  komisija2: komisija2,
                  mail: mail,
                  mejestoID: mejestoID,
                  regijaID: regijaID,
                  nadzorni: nadzorni,
                  ordinacijaId: ordinacijaId,
                  pol: pol,
                  prezime: prezime,
                  skupstina: skupstina,
                  specijalizacijaId: specijalizacijaId, 
                  specijalizacijaId2: specijalizacijaId2,            
                  status: status,
                  statusZaposlenje: statusZaposlenje,
                  sud: sud,
                  telefon: telefon,
                  titula: titula,
                  uid: uid,
                  uplate: uplateX,
                  uplateNove: uplateNove,
                  androidStatus: androidStatus,
                  androidDozvola: androidDozvola,
                  slika: slika,
                  disciplinskiS: disciplinskiS,
                  izvrsniS: izvrsniS,
                  komisija1S: komisija1S,
                  komisija2S: komisija2S,
                  mejestoIDS: mejestoIDS,
                  regijaIDS: regijaIDS,
                  nadzorniS, nadzorniS,
                  ordinacijaIdS: ordinacijaIdS,
                  skupstinaS: skupstinaS,
                  specijalizacijaIdS: specijalizacijaIdS,
                  specijalizacijaIdS2: specijalizacijaIdS2,
                  statusS: statusS,
                  statusZaposlenjeS: statusZaposlenjeS,
                  sudS: sudS,
                  titulaS: titulaS,
                });
              });

              bb= bb.sort((a,b) => parseInt(a.broj) > parseInt(b.broj) ? 1 : parseInt(b.broj) > parseInt(a.broj) ? -1 : 0);

              this.setState({ najvecaLicenca: najvecaLicenca });
              this.setState({ baza: bb },()=>{this.dajBodoveNove()});
 

    
            }).then((snapshot) => {
          
                //this.dajBodoveNove();
            })
    
           
          };


    
          
        dajBodoveNove = () => {
    
          bodoviN().then((snapshot) => {
           let ff = [];
            snapshot.forEach((clan) => {
              var uid = clan.key;
              clan.forEach((bod) => {
                let ime="";
                let prezime="";
                let licenca="";
                this.state.baza.forEach((ccc) => {
                if(ccc.uid==uid){
                    ime=ccc.ime;
                    prezime=ccc.prezime;
                    licenca=ccc.broj;
                }

                })
              
                let key =bod.key;
                 let id =bod.val().id;
                 let datum = bod.val().datum;
                 let naziv = bod.val().naziv;
                 let bodovi = bod.val().bodovi;
                 let grad = bod.val().grad;
                 let drzava = bod.val().drzava;
                 let sd = bod.val().sd;
                 let slika = bod.val().slika;
                 let potvrda= bod.val().potvrda;
               
                 ff.push({
                  ime: ime, 
                  prezime: prezime,
                  licenca: licenca, 
                   uid: uid,
                   key: key,
                   id: id,
                   datum: datum,
                   naziv: naziv,
                   bodovi: bodovi,
                   grad: grad,
                   drzava: drzava,
                   sd: sd,
                   slika: slika,
                   potvrda: potvrda,
              
              });
    
            
            });
      
           
           
          })

           this.setState({ bodoviNoviO: ff });
           
        }).then((snapshot) => {
            this.kongresiForma();
            this.noviClanForma();
            //this.ordinacijeForma();
            
        });
      }
    
        dajKongresiSifre = () => {
    
          kongresiSifre().then((snapshot) => {
           let ff = [];
            snapshot.forEach((kongres) => {
              
              let key =kongres.key;
              let naziv = kongres.val().naziv;
              let datum = kongres.val().datum;
              let bodovi = kongres.val().bodovi;
              let sd = kongres.val().sd;
              let grad = kongres.val().grad;
              let drzava = kongres.val().drzava;
      
              ff.push({
              
                key: key,
                naziv: naziv,
                datum: datum,
                bodovi: bodovi,
                sd: sd,
                grad: grad,
                drzava: drzava,
              
            });
      
          })
            this.setState({ kongresiSifreO: ff });
           
    
        });
      }
    
    
    
          tituleF = () => {
            titule().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                let broj = ss.val().broj;
                let naziv = ss.val().naziv;
        
                ff.push({
                  broj: broj,
                  naziv: naziv,
                });
              });
        
              this.setState({ tituleO: ff });
            });
          };
    
          statusiF = () => {
            statusi().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
    
                let broj = ss.val().broj;
                let naziv = ss.val().naziv;
        
                ff.push({
                  broj: broj,
                  naziv: naziv,
                });
              });
        
              this.setState({ statusiO: ff });
            });
          };
    
          statusiZaposlenjeF = () => {
            statusiZaposlenje().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                let broj = ss.val().broj;
                let naziv = ss.val().naziv;
        
                ff.push({
                  broj: broj,
                  naziv: naziv,
                });
              });
        
              this.setState({ statusiZaposlenjeO: ff });
            });
          };
    
          mjestaF = () => {
            mjesta().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                   
                let broj = ss.val().broj;
                let id = ss.val().id;
                let mjesto = ss.val().mjesto;
                let ptt = ss.val().ptt;
                let regija = ss.val().regija;
        
                ff.push({
                  broj: broj, 
                  id: id,
                  mjesto: mjesto,
                  ptt: ptt,
                  regija: regija,            
                });
              });
        
              this.setState({ mjestaO: ff });
              //this.ordinacijeF();
            });
          };
    
          regijeF = () => {
            regije().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                   
                let broj = ss.val().broj;
                let naziv = ss.val().naziv;
        
                ff.push({
                  broj: broj,
                  naziv: naziv,
                });
              });
        
              this.setState({ regijeO: ff });
            });
          };
    
          ordinacijeF = () => {
            ordinacije().then((snapshot) => {
              let ff = [];
              //let zz = [];
              let ff2 = [];
              snapshot.forEach((ss) => {
                let key =ss.key; 
                let adresa = ss.val().adresa;
                let id = ss.val().id;
                let mjestoId = ss.val().mjestoId;
                let naziv = ss.val().naziv;
                let nosioc = ss.val().nosioc;
                let sektor = ss.val().sektor;
                let telefon = ss.val().telefon;
                let vrsta = ss.val().vrsta;
                let email = ss.val().email;
                let regijaId ="";
                let mjestoS ="";
                let regijaS ="";

                this.state.mjestaO.forEach((rr) => {                
                if(mjestoId==rr.id){
                    regijaId=rr.broj;
                    mjestoS=rr.mjesto;
                    regijaS=rr.regija;
                } 
                })

                this.state.baza.forEach((pp) => {
                if(pp.ordinacijaId==id){

                  ff2.push({
                    adresa: adresa, 
                    id: id,
                    mjestoId: mjestoId,
                    naziv: naziv,
                    nosioc: nosioc,
                    sektor: sektor,
                    telefon: telefon,
                    vrsta: vrsta, 
                    key: key,
                    ime: pp.ime,
                    prezime: pp.prezime,
                    licenca: pp.broj,
                    broj: pp.broj,
                    regijaId: regijaId,
                    regijaS: regijaS, 
                    mjestoS: mjestoS,
                    adresaC: pp.adresa,
                    mjestoC: pp.mejestoIDS,
                    regijaC: pp.regijaIDS,
                    email: email,
                                                  
                  });

                }
                })
     
                ff.push({
                  adresa: adresa, 
                  id: id,
                  mjestoId: mjestoId,
                  naziv: naziv,
                  nosioc: nosioc,
                  sektor: sektor,
                  telefon: telefon,
                  vrsta: vrsta, 
                  key: key,
                  regijaId: regijaId,
                  regijaS: regijaS, 
                  mjestoS: mjestoS,
                  email: email,       
                });
              });
        
              this.setState({ ordinacijeO: ff });
              this.setState({ ordinacijeOfilter: ff });
              this.setState({ ordinacijeOZaposleni: ff2 });
              this.setState({ ordinacijeOfilterZaposleni: ff2 });
              this.setState({ zaposleniO: [] });

              this.setState({
                brojOrdinacija: (
                  <div >
                    <label >{ff.length}</label>
                    </div>
      
                    )});

            });
          };
    

    
          specijalizacijeF = () => {
            specijalizacije().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                
        
                let id = ss.val().id;
                let spec = ss.val().spec;
        
                ff.push({
                  id: id,
                  spec: spec,
                });
              });
        
              this.setState({ specijalizacijeO: ff });
            });
          };
    


          slikeUbaci = (slika) => {
       
            let v = document.getElementById("sliketina");
            let sss=logoa4
           if(slika==undefined || slika==null || slika==""|| slika=="undefined" ){
            this.setState({ uriDokumenta: sss});
            v.src = sss;
           }else{
           
              v.src = slika;
              this.setState({ uriDokumenta: slika});
              
          }
         
           
          
          };
          handleShowDialog = () => {
            this.setState({ zoomSlika: (<div>
    
                <dialog           
                //style={this.stylesZoom}
                className="popup"
                open
                onClick={this.ukiniSliku}
              >
                <img
                  className="image"
                  src={this.state.uriDokumenta}
                  onClick={this.ukiniSliku}
                  alt="no image"
                />
              </dialog>
    
    
            </div>) });
         
          };
    
          ukiniSliku = () => {
            this.setState({ zoomSlika:""});
    
          }


          dajZaposlene = () => {

            let v = document.getElementById("selektovanaOrdinacijaI");
            let selektovanDogadjaj = v.options[v.selectedIndex].value.split(";*");
            let idOrdinacije = selektovanDogadjaj[1];
            idOrdinacije=idOrdinacije.trim();
            

            let zap = [];
            this.setState({ zaposleniO: [] });
            
            this.state.baza.forEach(function (ss) {
             
              try {
               
                let ordinacijaId= ss.ordinacijaId;
               
                
                if (ordinacijaId == idOrdinacije )
                {
                zap.push(
                    ss
                  );
               
                }
              } catch (er) {
                alert(ss.key);
              }
            });
        
            this.setState({ zaposleniO: zap });
            this.popuni();
          }

          popuni = () => {

            //d.naziv+";"+d.id+";"+d.adresa+";"+d.mjestoId+";"+d.telefon+";"+d.sektor+";"+d.vrsta+";"+d.nosioc

            let v = document.getElementById("selektovanaOrdinacijaI");
            let selektovanDogadjaj = v.options[v.selectedIndex].value.split(";*");
            let nazivOrdinacije = selektovanDogadjaj[0].trim();
            let idOrdinacije = selektovanDogadjaj[1].trim();
            let adresaOrdinacije = selektovanDogadjaj[2].trim();
            let mjestoIdOrdinacije = selektovanDogadjaj[3].trim();
            let telefonOrdinacije = selektovanDogadjaj[4];
            let sektorOrdinacije = selektovanDogadjaj[5].trim();
            let vrstaOrdinacije = selektovanDogadjaj[6].trim();
            let nosiocOrdinacije = selektovanDogadjaj[7].trim();
            let emailOrdinacije = selektovanDogadjaj[9].trim();

            if(telefonOrdinacije=="undefined"){telefonOrdinacije=""}
            if(adresaOrdinacije=="undefined"){adresaOrdinacije=""}
            if(emailOrdinacije=="undefined"){emailOrdinacije=""}
            
            document.getElementById("nazivOrdinacijeI").value=nazivOrdinacije;
            document.getElementById("gradI").value=mjestoIdOrdinacije;
            document.getElementById("adresaOrdinacijeI").value=adresaOrdinacije;
            document.getElementById("telefonOrdinacijeI").value=telefonOrdinacije;
            document.getElementById("sektorI").value=sektorOrdinacije;
            document.getElementById("vrstaI").value=vrstaOrdinacije;
            document.getElementById("nosilacI").value=nosiocOrdinacije;
            document.getElementById("emailOrdinacijeI").value=emailOrdinacije;
           
            //alert(selektovanDogadjaj);
    
          }

          promjeniOrdinaciju= (ev) => {

            ev.preventDefault();

            try{
            let v = document.getElementById("selektovanaOrdinacijaI");
            let selektovanDogadjaj = v.options[v.selectedIndex].value.split(";*");
            let key = selektovanDogadjaj[8].trim();
                      
           let nazivOrdinacije= document.getElementById("nazivOrdinacijeI").value;
           let mjestoIdOrdinacije=document.getElementById("gradI").value;
           let adresaOrdinacije= document.getElementById("adresaOrdinacijeI").value;
           let telefonOrdinacije= document.getElementById("telefonOrdinacijeI").value;
           let sektorOrdinacije= document.getElementById("sektorI").value;
           let vrstaOrdinacije= document.getElementById("vrstaI").value;
           let nosiocOrdinacije= document.getElementById("nosilacI").value;
           let emailOrdinacije= document.getElementById("emailOrdinacijeI").value;
          

           if (window.confirm("Izmijeni podatke ordinacije "+nazivOrdinacije+"?")) {
           izmjeniOrdinaciju(
            adresaOrdinacije,
            mjestoIdOrdinacije,
            nazivOrdinacije,
            nosiocOrdinacije,
            sektorOrdinacije,
            telefonOrdinacije,
            vrstaOrdinacije,
            emailOrdinacije,
            key
          ) .then(() => {


            ordinacije().then((snapshot) => {
              let ff = [];
              //let zz = [];
              let ff2 = [];
              snapshot.forEach((ss) => {
                let key =ss.key; 
                let adresa = ss.val().adresa;
                let id = ss.val().id;
                let mjestoId = ss.val().mjestoId;
                let naziv = ss.val().naziv;
                let nosioc = ss.val().nosioc;
                let sektor = ss.val().sektor;
                let telefon = ss.val().telefon;
                let vrsta = ss.val().vrsta;
                let email = ss.val().email;
                let regijaId ="";
                let mjestoS ="";
                let regijaS ="";

                this.state.mjestaO.forEach((rr) => {                
                if(mjestoId==rr.id){
                    regijaId=rr.broj;
                    mjestoS=rr.mjesto;
                    regijaS=rr.regija;
                } 
                })

                this.state.baza.forEach((pp) => {
                if(pp.ordinacijaId==id){

                  ff2.push({
                    adresa: adresa, 
                    id: id,
                    mjestoId: mjestoId,
                    naziv: naziv,
                    nosioc: nosioc,
                    sektor: sektor,
                    telefon: telefon,
                    vrsta: vrsta, 
                    key: key,
                    ime: pp.ime,
                    prezime: pp.prezime,
                    licenca: pp.broj,
                    broj: pp.broj,
                    regijaId: regijaId,
                    regijaS: regijaS, 
                    mjestoS: mjestoS,
                    adresaC: pp.adresa,
                    mjestoC: pp.mejestoIDS,
                    regijaC: pp.regijaIDS,
                    email: email
                                                  
                  });

                }
                })
     
                ff.push({
                  adresa: adresa, 
                  id: id,
                  mjestoId: mjestoId,
                  naziv: naziv,
                  nosioc: nosioc,
                  sektor: sektor,
                  telefon: telefon,
                  vrsta: vrsta, 
                  key: key,
                  regijaId: regijaId,
                  regijaS: regijaS, 
                  mjestoS: mjestoS,
                  email: email         
                });
              });
        
              this.setState({ ordinacijeO: ff });
              this.setState({ ordinacijeOfilter: ff });
              this.setState({ ordinacijeOZaposleni: ff2 });
              this.setState({ ordinacijeOfilterZaposleni: ff2 });
              this.setState({ zaposleniO: [] });

              this.setState({
                brojOrdinacija: (
                  <div >
                    <label >{ff.length}</label>
                    </div>
      
                    )});

            document.getElementById("nazivOrdinacijeI").value="";
            document.getElementById("gradI").value="0";
            document.getElementById("adresaOrdinacijeI").value="";
            document.getElementById("telefonOrdinacijeI").value="";
            document.getElementById("sektorI").value="P";
            document.getElementById("vrstaI").value="O";
            document.getElementById("nosilacI").value="0";

          document.getElementById("filterMjesto").value="sve";
          document.getElementById("filterSektor").value="sve";
          document.getElementById("filterVrsta").value="sve";
          document.getElementById("pretragaI").value="";

          alert("Ordinacija "+nazivOrdinacije+" je izmjenjena!")

            });

          })
        }

      }catch(ero){
          alert("Niste selektovali ni jednu ordinaciju!")
      }
          }

          brisiOrdinaciju= () => { 

            try{
            let v = document.getElementById("selektovanaOrdinacijaI");
            let selektovanDogadjaj = v.options[v.selectedIndex].value.split(";*");
            let key = selektovanDogadjaj[8].trim();
                      
           let nazivOrdinacije= document.getElementById("nazivOrdinacijeI").value;
           let mjestoIdOrdinacije=document.getElementById("gradI").value;
           let adresaOrdinacije= document.getElementById("adresaOrdinacijeI").value;
           let telefonOrdinacije= document.getElementById("telefonOrdinacijeI").value;
           let sektorOrdinacije= document.getElementById("sektorI").value;
           let vrstaOrdinacije= document.getElementById("vrstaI").value;
           let nosiocOrdinacije= document.getElementById("nosilacI").value;
          

           if (window.confirm("Izbriši ordinaciju "+nazivOrdinacije+"?")) {

            if(this.state.zaposleniO.length==0){



            obrisiOrdinaciju(key) .then(() => {

              ordinacije().then((snapshot) => {
                let ff = [];
                //let zz = [];
                let ff2 = [];
                snapshot.forEach((ss) => {
                  let key =ss.key; 
                  let adresa = ss.val().adresa;
                  let id = ss.val().id;
                  let mjestoId = ss.val().mjestoId;
                  let naziv = ss.val().naziv;
                  let nosioc = ss.val().nosioc;
                  let sektor = ss.val().sektor;
                  let telefon = ss.val().telefon;
                  let vrsta = ss.val().vrsta;
                  let email = ss.val().email;
                  let regijaId ="";
                  let mjestoS ="";
                  let regijaS ="";
  
                  this.state.mjestaO.forEach((rr) => {                
                  if(mjestoId==rr.id){
                      regijaId=rr.broj;
                      mjestoS=rr.mjesto;
                      regijaS=rr.regija;
                  } 
                  })
  
                  this.state.baza.forEach((pp) => {
                  if(pp.ordinacijaId==id){
  
                    ff2.push({
                      adresa: adresa, 
                      id: id,
                      mjestoId: mjestoId,
                      naziv: naziv,
                      nosioc: nosioc,
                      sektor: sektor,
                      telefon: telefon,
                      vrsta: vrsta, 
                      key: key,
                      ime: pp.ime,
                      prezime: pp.prezime,
                      licenca: pp.broj,
                      broj: pp.broj,
                      regijaId: regijaId,
                      regijaS: regijaS, 
                      mjestoS: mjestoS,
                      adresaC: pp.adresa,
                      mjestoC: pp.mejestoIDS,
                      regijaC: pp.regijaIDS,
                      email: email
                                                    
                    });
  
                  }
                  })
       
                  ff.push({
                    adresa: adresa, 
                    id: id,
                    mjestoId: mjestoId,
                    naziv: naziv,
                    nosioc: nosioc,
                    sektor: sektor,
                    telefon: telefon,
                    vrsta: vrsta, 
                    key: key,
                    regijaId: regijaId,
                    regijaS: regijaS, 
                    mjestoS: mjestoS,
                    email: email         
                  });
                });
          
                this.setState({ ordinacijeO: ff });
                this.setState({ ordinacijeOfilter: ff });
                this.setState({ ordinacijeOZaposleni: ff2 });
                this.setState({ ordinacijeOfilterZaposleni: ff2 });
                this.setState({ zaposleniO: [] });
  
                this.setState({
                  brojOrdinacija: (
                    <div >
                      <label >{ff.length}</label>
                      </div>
        
                      )});
            document.getElementById("nazivOrdinacijeI").value="";
            document.getElementById("gradI").value="0";
            document.getElementById("adresaOrdinacijeI").value="";
            document.getElementById("telefonOrdinacijeI").value="";
            document.getElementById("sektorI").value="P";
            document.getElementById("vrstaI").value="O";
            document.getElementById("nosilacI").value="0";

          document.getElementById("filterMjesto").value="sve";
          document.getElementById("filterSektor").value="sve";
          document.getElementById("filterVrsta").value="sve";
          document.getElementById("pretragaI").value="";
          alert("Ordinacija "+nazivOrdinacije+" je obrisana")

            });

          })

        }else{
             
          alert("Brisanje ordinacije nije moguće jer posjeduje zaposlene članove!")

           }
        }

      }catch(ero){
          alert("Niste selektovali ni jednu ordinaciju!")
      }
          }


          dodajOrdinaciju= (ev) => {

            ev.preventDefault();

            try{
           
                      
           let nazivOrdinacije= document.getElementById("nazivOrdinacijeIN").value;
           let mjestoIdOrdinacije=document.getElementById("gradIN").value;
           let adresaOrdinacije= document.getElementById("adresaOrdinacijeIN").value;
           let telefonOrdinacije= document.getElementById("telefonOrdinacijeIN").value;
           let sektorOrdinacije= document.getElementById("sektorIN").value;
           let vrstaOrdinacije= document.getElementById("vrstaIN").value;
           let nosiocOrdinacije= document.getElementById("nosilacIN").value;
           let emailOrdinacije= document.getElementById("emailOrdinacijeIN").value;
          

           if (window.confirm("Dodaj novu ordinaciju "+nazivOrdinacije)) {

            upisiOrdinaciju(

            adresaOrdinacije,
            mjestoIdOrdinacije,
            nazivOrdinacije,
            nosiocOrdinacije,
            sektorOrdinacije,
            telefonOrdinacije,
            vrstaOrdinacije,
            emailOrdinacije,
            
          ) .then(() => {


            ordinacije().then((snapshot) => {
              
              let ff = [];
              //let zz = [];
              let ff2 = [];
              snapshot.forEach((ss) => {
                let key =ss.key; 
                let adresa = ss.val().adresa;
                let id = ss.val().id;
                let mjestoId = ss.val().mjestoId;
                let naziv = ss.val().naziv;
                let nosioc = ss.val().nosioc;
                let sektor = ss.val().sektor;
                let telefon = ss.val().telefon;
                let vrsta = ss.val().vrsta;
                let email = ss.val().email;
                let regijaId ="";
                let mjestoS ="";
                let regijaS ="";

                this.state.mjestaO.forEach((rr) => {                
                if(mjestoId==rr.id){
                    regijaId=rr.broj;
                    mjestoS=rr.mjesto;
                    regijaS=rr.regija;
                } 
                })

                this.state.baza.forEach((pp) => {
                if(pp.ordinacijaId==id){

                  ff2.push({
                    adresa: adresa, 
                    id: id,
                    mjestoId: mjestoId,
                    naziv: naziv,
                    nosioc: nosioc,
                    sektor: sektor,
                    telefon: telefon,
                    vrsta: vrsta, 
                    key: key,
                    ime: pp.ime,
                    prezime: pp.prezime,
                    licenca: pp.broj,
                    broj: pp.broj,
                    regijaId: regijaId,
                    regijaS: regijaS, 
                    mjestoS: mjestoS,
                    adresaC: pp.adresa,
                    mjestoC: pp.mejestoIDS,
                    regijaC: pp.regijaIDS,
                    email: email
                                                  
                  });

                }
                })
     
                ff.push({
                  adresa: adresa, 
                  id: id,
                  mjestoId: mjestoId,
                  naziv: naziv,
                  nosioc: nosioc,
                  sektor: sektor,
                  telefon: telefon,
                  vrsta: vrsta, 
                  key: key,
                  regijaId: regijaId,
                  regijaS: regijaS, 
                  mjestoS: mjestoS,
                  email: email     
                });
              });
        
              this.setState({ ordinacijeO: ff });
              this.setState({ ordinacijeOfilter: ff });
              this.setState({ ordinacijeOZaposleni: ff2 });
              this.setState({ ordinacijeOfilterZaposleni: ff2 });
              this.setState({ zaposleniO: [] });

              this.setState({
                brojOrdinacija: (
                  <div >
                    <label >{ff.length}</label>
                    </div>
      
                    )});


            document.getElementById("nazivOrdinacijeIN").value="";
            document.getElementById("gradIN").value="0";
            document.getElementById("adresaOrdinacijeIN").value="";
            document.getElementById("telefonOrdinacijeIN").value="";
            document.getElementById("sektorIN").value="P";
            document.getElementById("vrstaIN").value="O";
            document.getElementById("nosilacIN").value="0";
            document.getElementById("emailOrdinacijeIN").value="";

            document.getElementById("nazivOrdinacijeI").value="";
            document.getElementById("gradI").value="0";
            document.getElementById("adresaOrdinacijeI").value="";
            document.getElementById("telefonOrdinacijeI").value="";
            document.getElementById("sektorI").value="P";
            document.getElementById("vrstaI").value="O";
            document.getElementById("nosilacI").value="0";
            document.getElementById("emailOrdinacijeI").value="";

          document.getElementById("filterMjesto").value="sve";
          document.getElementById("filterSektor").value="sve";
          document.getElementById("filterVrsta").value="sve";
          document.getElementById("pretragaI").value="";

          alert("Nova ordinacija "+nazivOrdinacije+" je dodana")
            });

          })
        }

      }catch(ero){
          alert("Niste selektovali ni jednu ordinaciju!")
      }
          }


          dodajMjesto =(ev)=>{
            ev.preventDefault();
            let nazivMjesta= document.getElementById("mjestoNI").value;
            let postanskiBroj=document.getElementById("postanskiI").value;
            let v = document.getElementById("regijaNI");
            let regija = v.options[v.selectedIndex].value.split(";");
            let regijaBroj = regija[0];
            let regijaNaziv = regija[1];
            //alert(nazivMjesta+" "+postanskiBroj+" "+regijaBroj+" "+regijaNaziv)
            if (window.confirm("Dodaj novo mjesto "+nazivMjesta)) {
            upisiMjesto(
              nazivMjesta,
              postanskiBroj,
              regijaBroj,
              regijaNaziv,
             
            ).then((snapshot) => {
              alert("mjesto "+nazivMjesta+" je dodano!")

            })
          }
          }
    
     render() { 

     var i=1;
     var z=1;

        return (  <div>

        <div style={this.stylesG}>{this.state.kongresiEkran}</div>
        <div style={this.stylesG2}>{this.state.noviClanEkran}</div>
        <div style={this.stylesG25}><h4>{this.state.najvecaLicenca}</h4></div>
        <div style={this.stylesG3}>
        <div className=" mt-3 ps-2 pe-2 pb-2 pt-1" style={this.styles8}>     
            
            <Form  className="text-center mt-1" onSubmit={this.promjeniOrdinaciju}>
            <h3>Ordinacije</h3>

            <Row>

            <Col sm="1">
            <div  id="brojOrdinacija" style={this.styleBroj} className="ms-2">
                {this.state.brojOrdinacija}
              </div>
            </Col>

              <Col sm="2">
            <Form.Control
              type="text"
              id="pretragaI"
              placeholder="pretraga ordinacija"
              
              className="ms-2 mb-1"
              />
              </Col>

              <Col sm="2">
              <Input id="filterRegija" as="select" style={this.styleHH}
               className="ms-2 mt-1">
               <option value="sve">SVE REGIJE</option>
               <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              {this.state.regijeO.map((d) => {

               return (
                 <option
             
                   value={ d.broj}              
                 >
                   {d.naziv}
                 </option>
               );        
             }) }  
            </Input>
              </Col>
              <Col sm="2">
              <Input id="filterMjesto" as="select" style={this.styleHH}
               className="ms-2 mt-1">
               <option value="sve">SVI GRADOVI</option>
               <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              {this.state.mjestaO.map((d) => {

               return (
                 <option
             
                   value={ d.id}              
                 >
                   {d.mjesto}
                 </option>
               );        
             }) }  
            </Input>
              </Col>

              <Col sm="2">
              <Input id="filterSektor" as="select" style={this.styleHH}
               className="ms-2 mt-1">
               <option value="sve">SVI SEKTORI</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="P">Privatni</option>
              <option value="D">Društveni</option>             
            </Input>
              </Col>

              <Col sm="2">
              <Input id="filterVrsta" as="select" style={this.styleHH}
               className="ms-2 mt-1">
               <option value="sve">SVE VRSTE</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="O">Opšta</option>
              <option value="S">Specijalistička</option>             
            </Input>
              </Col>

            </Row>


              <Row>
              <Col sm="8">
                  <Form.Control
                    id="selektovanaOrdinacijaI"
                    as="select"

                    multiple
                    style={{ height: "200px",   overflow: "scroll", }}
                    className="ms-2 mt-1"
                  >                 
                    {this.state.ordinacijeOfilter.map((d) => {
               
           
                      return (
                        <option
                          className="text-danger"
                          onClick={() => this.dajZaposlene()}
                          value={
                            d.naziv+";*"+d.id+";*"+d.adresa+";*"+d.mjestoId+";*"+d.telefon+";*"+d.sektor+";*"+d.vrsta+";*"+d.nosioc+";*"+d.key+";*"+d.email
                          }
                        >
                          {i++ }.{d.naziv+ " | " +d.adresa+ " | " +d.mjestoS}
                        </option>
                      );

                    
                    }) }

                  </Form.Control>
               </Col>
   
                <Col sm="4">
               
                <Input id="zaposleniSelI" as="select" style={{ height: "200px" , overflow: "scroll"}} className="mr-1 mb-3"  multiple defaultValue={"-"}>
                 
                {this.state.zaposleniO.map((d) => {

                    return (
                      
                      <option
                    
                        value={
                          d.ime+";" + d.prezime
                        }
                      >
                        {z++ }.{d.titulaS+" "+d.ime+" " +d.prezime+ " | " +d.broj}
                      </option>
                    );
                })}
              </Input>
              </Col>
              </Row>



              <div className="mt-2">

                <Row>

                <Col sm="3"><label>naziv</label>
                </Col>

                <Col sm="3"><label>grad</label>
                </Col>

                <Col sm="3"><label>adresa</label>
                </Col>

                <Col sm="3"><label>telefon</label>
                </Col>

                </Row>
       
          <Row>
              <Col sm="3">
              <Form.Control
              type="text"
              id="nazivOrdinacijeI"
              placeholder="Naziv ordinacije"
              required
              />
              </Col>

              <Col sm="3">
              <Input id="gradI" as="select" style={this.styleHH}
               >          
              {this.state.mjestaO.map((d) => {
               return (
                 <option           
                   value={ d.id}              
                 >
                   {d.mjesto}
                 </option>
               );        
             }) }  
            </Input>
              </Col>

              <Col sm="3">
              <Form.Control
              type="text"
              id="adresaOrdinacijeI"
              placeholder="Adresa ordinacije"
              required
              />
              </Col>

              <Col sm="3">
              <Form.Control
              type="text"
              id="telefonOrdinacijeI"
              placeholder="Telefon ordinacije"
              required
              />
              </Col>

          </Row>

            <Row className="mt-2">

                <Col sm="3"><label>e-mail</label>
                </Col>

                <Col sm="3"><label>sektor</label>
                </Col>

                <Col sm="3"><label>vrsta</label>
                </Col>

                <Col sm="3"><label>nosilac</label>
                </Col>


              

                </Row>
       

          <Row className="mb-2">
          <Col sm="3">
          <Form.Control
              type="text"
              id="emailOrdinacijeI"
              placeholder="E-mail ordinacije"
              
              />
              </Col>

              <Col sm="3">
              <Input id="sektorI" as="select" style={this.styleHH}
               >         
              <option value="P">Privatni</option>
              <option value="D">Društveni</option>             
            </Input>
              </Col>

              <Col sm="3">
              <Input id="vrstaI" as="select" style={this.styleHH}
               >
              <option value="O">Opšta</option>
              <option value="S">Specijalistička</option>             
            </Input>
              </Col>

              <Col sm="3">
              <Input id="nosilacI" as="select" style={this.styleHH}
               >     
                <option value="0">-</option>     
              {this.state.baza.map((d) => {
                if(parseInt(d.broj)==0){}
                else
                {return (
                 <option           
                   value={d.id}              
                 >
                   {d.broj+" "+d.ime+" "+d.prezime}
                 </option>
               );   }     
             }) }  
            </Input>
              </Col>
          </Row>

              </div>

                  <div>
   <ExcelFile
            filename={
             "Ordinacije"
            }
            element={
              <Button
              className="btn btn-primary centerButton"
              //type="submit"
              style={{ width: "200px" }}
              className="me-3"
              size="sm"
              variant="success"
               
              >
                Snimi tabelu ordinacija
              </Button>
            }
          >
            <ExcelSheet
              //data={this.state.ordinacijeOfilterZaposleni}
              data={this.state.ordinacijeOfilter}
              name={"Ordinacije"}
            >
              <ExcelColumn label="Naziv" value="naziv" />
              <ExcelColumn label="Adresa" value="adresa" />
              <ExcelColumn label="Mjesto" value="mjestoS" />
              <ExcelColumn label="Regija" value="regijaS" />
              <ExcelColumn label="Telefon" value="telefon" />        
              <ExcelColumn label="Email" value="email" />
              <ExcelColumn label="Vrsta" value="vrsta" />
              <ExcelColumn label="Sektor" value="sektor" />
              
                     
   
            </ExcelSheet>

          </ExcelFile>

          <ExcelFile
            filename={
             "Ordinacije sa zaposlenim"
            }
            element={
              <Button
              className="btn btn-primary centerButton"
              //type="submit"
              style={{ width: "250px" }}
              className="me-3"
              size="sm"
              variant="success"
               
              >
                Snimi tabelu ordinacija sa zaposlenim
              </Button>
            }
          >
            <ExcelSheet
              data={this.state.ordinacijeOfilterZaposleni}
              //data={this.state.ordinacijeOfilter}
              name={"Ordinacije"}
            >
              <ExcelColumn label="Naziv" value="naziv" />
              <ExcelColumn label="Adresa" value="adresa" />
              <ExcelColumn label="Mjesto" value="mjestoS" />
              <ExcelColumn label="Regija" value="regijaS" />
              <ExcelColumn label="Telefon" value="telefon" />        
              <ExcelColumn label="Email" value="email" />
              <ExcelColumn label="Vrsta" value="vrsta" />
              <ExcelColumn label="Sektor" value="sektor" />
              
                                  
              <ExcelColumn label="Ime" value="ime" />
              <ExcelColumn label="Prezime" value="prezime" />
              <ExcelColumn label="Licenca" value="broj" />
              <ExcelColumn label="Regija Člana" value="regijaC" />
              <ExcelColumn label="Mjesto Člana" value="mjestoC" />
              <ExcelColumn label="Adresa Člana" value="adresaC" />
              
              
   
            </ExcelSheet>

          </ExcelFile>
         

                  <Button
                    id="izmjeniOrdinaciju"
                    className="btn btn-danger"
                    //onClick={() => this.izmjeniOrdinaciju()}     
                    size="sm" 
                    type="submit"                 
                  >
                    Izmijeni ordinaciju
                  </Button>
               

                <Button
                id="brisiordinaciju"
                className="btn btn-outline-danger  centerButton ms-2"
                onClick={() => this.brisiOrdinaciju()}       
                variant="outline-dark"
                size="sm"
                >
                Briši ordinaciju
                </Button>
              
                </div>

            </Form>
            <Form>
        

          
            </Form>

            <Form  className="text-center" onSubmit={this.dodajOrdinaciju}>
               <div className="mt-4" style={this.styleRR}>

                <Row>

                <Col sm="3"><label>naziv</label>
                </Col>

                <Col sm="3"><label>grad</label>
                </Col>

                <Col sm="3"><label>adresa</label>
                </Col>

                <Col sm="3"><label>telefon</label>
                </Col>

                </Row>
       
          <Row>
              <Col sm="3">
              <Form.Control
              type="text"
              id="nazivOrdinacijeIN"
              placeholder="Naziv ordinacije"
              required
              />
              </Col>

              <Col sm="3">
              <Input id="gradIN" as="select" style={this.styleHH}
               >          
              {this.state.mjestaO.map((d) => {
               return (
                 <option           
                   value={ d.id}              
                 >
                   {d.mjesto}
                 </option>
               );        
             }) }  
            </Input>
              </Col>

              <Col sm="3">
              <Form.Control
              type="text"
              id="adresaOrdinacijeIN"
              placeholder="Adresa ordinacije"
              required
              />
              </Col>

              <Col sm="3">
              <Form.Control
              type="text"
              id="telefonOrdinacijeIN"
              placeholder="Telefon ordinacije"
              required
              />
              </Col>

          </Row>

            <Row className="mt-2">

            <Col sm="3"><label>e-mail</label>
                </Col>

                <Col sm="3"><label>sektor</label>
                </Col>

                <Col sm="3"><label>vrsta</label>
                </Col>

                <Col sm="3"><label>nosilac</label>
                </Col>

              

                </Row>
       

          <Row className="mb-2">

          <Col sm="3">
          <Form.Control
              type="text"
              id="emailOrdinacijeIN"
              placeholder="E-mail ordinacije"
              
              />
              </Col>

              <Col sm="3">
              <Input id="sektorIN" as="select" style={this.styleHH}
               >         
              <option value="P">Privatni</option>
              <option value="D">Društveni</option>             
            </Input>
              </Col>

              <Col sm="3">
              <Input id="vrstaIN" as="select" style={this.styleHH}
               >
              <option value="O">Opšta</option>
              <option value="S">Specijalistička</option>             
            </Input>
              </Col>

              <Col sm="3">
              <Input id="nosilacIN" as="select" style={this.styleHH}
               >     
                <option value="0">-</option>     
              {this.state.baza.map((d) => {
                    if(parseInt(d.broj)==0){}
                    else{
               return (
                 <option           
                   value={d.id}              
                 >
                   {d.broj+" "+d.ime+" "+d.prezime}
                 </option>
               );  }      
             }) }  
            </Input>
              </Col>
          </Row>
                   <div >

                  <Button
                    id="dodajOrdinaciju"
                    className="btn btn-danger" 
                    size="sm" 
                    type="submit"                 
                  >
                    Dodaj ordinaciju
                  </Button>
               

              
                </div>
              </div>
              </Form>

                
          </div>

        </div>
        <div style={this.stylesG4}>
        <div className=" mt-3 ps-2 pe-2 pb-2 pt-1" style={this.styles9}>     
            
            <Form  className="text-center mt-1" onSubmit={this.dodajMjesto}>
            <h3>Novo mjesto</h3>
              <Row className="mt-2">

                <Col sm="4"><label>naziv mjesta</label>
                </Col>

                <Col sm="4"><label>postanski broj</label>
                </Col>

                <Col sm="4"><label>regija</label>
                </Col>

              

                </Row>
            <Row>

            <Col sm="4">
            <Form.Control
              type="text"
              id="mjestoNI"
              placeholder="naziv mjesta"
              required
              className="ms-2 mb-1"
              />
          </Col>
          <Col sm="4">
            <Form.Control
              type="text"
              id="postanskiI"
              placeholder="poštanski broj"            
              className="ms-2 mb-1"
              required
              />
          </Col>
          <Col sm="4">
          <Input id="regijaNI" as="select" style={this.styleHH}
               >     
            
              {this.state.regijeO.map((ss) => {
               return (
                 <option value={ss.broj+";"+ss.naziv}>{ss.naziv}</option>
               );        
             }) }  
            </Input>
            </Col>
          </Row>
            <div className="mt-3">

                  <Button
                    id="dodajMjesto"
                    className="btn btn-danger" 
                    size="sm" 
                    type="submit"                 
                  >
                    Dodaj novo mjesto
                  </Button>
               

              
                </div>
          </Form>
          </div>
        </div>
       
        
        <div style={{  position: 'absolute' }}>{this.state.zoomSlika}</div>

        </div>);
    }
}
 
export default Edukacije;

