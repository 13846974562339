import React, { Component, useEffect, useRef  } from 'react';
import Form from "react-bootstrap/Form";
import firebase from "../firebase.js";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Input from "react-bootstrap/InputGroup";
import Resizer from "react-image-file-resizer";
import ReactToPrint from "react-to-print";
import stomatologLogo from "../stomlogo.png";
import documentlogo from "../documentlogo.png";
import logoa4 from "../logoa4.png";
import "moment/locale/sr";
import { DatePickerRC, DatePickerInput } from "rc-datepicker";
import "./myStyles.css";
import "rc-datepicker/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-medium-image-zoom/dist/styles.css';
import { getStorage, ref, deleteObject } from "firebase/storage";

import {

    dajSveClanove,
    sudCasti,
    ordinacije,
    skupstina,
    disciplinskiTuzilac,
    izvrsniOdbor,
    komisije,
    mjesta,
    regije,
    nadzorniOdbor,
    specijalizacije,
    titule,
    statusi,
    statusiZaposlenje,
    izmjeniClana,
    izbrisiClana,
    upisiSliku,
    upisiSlikuL,
    obrisiUplatu,
    upisiUplatu,
    uplateSifre,
    napomene,
    upisiNapomenu,
    obrisiNapomenu,
    obrisiDogadjajN,
    obrisiDogadjajS,
    uplateN,
    bodoviN,
    kongresiSifre,
    upisiBodove,
    potvrdiBodove,
    upisiSlikuDok,
    obrisiUplatuS,
    lozinke,
    obrisiSlikuEdukacijeN,
    obrisiSlikuEdukacijeS,
  } from "../funkcije.js";

import ReactExport from "react-data-export";
var ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



class Clanovi extends Component {


    
        state = { 
            kongresiPretraga: [],
            brojClanova: "",
            baza: [],
            bazaP: [],
            lista: "",
            clan: "",
            profil: "",
            uplate: "",
            uplateN: "",
            uplateO: [],
            oruzjeObjekat: [],         
            uplateSifre: [],
            datumUplate: "",
            datumLicence: "",
            datumRodjenja: "",
            day: "",
            kongresiEkran: "",
            zanimanje: [],
            funkcija: [],
            bodoviNoviO: [],
            kongresiSifreO: [],
            sprema: [],
            dokumenti: [],
            im: "",
            pictures: [],
            uriSlike:"",
            uriDokumenta: logoa4,
            pocetak: "",
            kraj: "",
            month: 10,
            year: 2021,
            statusiO: [],
            statusiZaposlenjeO: [],
            tituleO: [],
            specijalizacijeO: [],
            sudCastiO: [],
            skupstinaO: [],
            nadzorniOdborO: [],
            komisijeO: [],
            izvrsniOdborO: [],
            disciplinskiTuzilacO: [],
            ordinacijeO: [],
            mjestaO: [],
            regijeO: [],
            zoomSlika: "",
            lozinkeO: [],
          };

          styleListaClanova = {
            border: "1px solid red",
            height: "435px",
            width: "420px",
            background: "#f7f7ed",
            overflow: "scroll",
            float: "left",
            "vertical-align": "middle",
            "text-align": "center",
          };


          stylePretraga = {
            border: "1px solid red",
            width: "345px",
            height: "35px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
          };

          stylePretraga2 = {
            border: "1px solid red",
            width: "345px",
            height: "35px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
          };
        
          styleSelekcija = {
            border: "1px solid red",
            width: "207px",
            height: "30px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
          };

          styleSelekcija2 = {
            border: "1px solid red",
            width: "418px",
            height: "30px",
            "margin-top": "40px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            //"text-align": "center",
          };

          styles3 = {
            border: "1px solid red",
            width: "350px",
            height: "550px",
            overflow: "scroll",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
          };

          styles6 = {
            border: "1px solid red",
            width: "680px",
            height: "550px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
          };

          slikaDok = {
            width: "250px",
            height: "353px",
            "object-fit": "scale-down",
          };

          slikaDok2 = {
            width: "1100px",
            height: "1500px",
            "object-fit": "scale-up",
          };

          stylesProfil = {
            border: "1px solid red",
            width: "350px",
            height: "550px",
            overflow: "scroll",
            padding: "15px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
          };

          styles4 = {
            border: "1px solid red",
            width: "420px",
            height: "550px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
          };
        

          styleSlika = {
            width: "200px",
            height: "200px",
            "border-radius": "50%",
            "object-fit": "cover",
            "object-position": "top",
            border: "2px solid red",
            
          };

          styleCrvena = {
            background: "#dc3545",
          };

          styleBroj = {
            border: "1px solid red",
            width: "70px",
            height: "35px",
            background: "#f7f7ed",
            float: "left",
            "text-align": "center",
            "vertical-align": "middle",
            padding: "5px",
          };

          stylesD = {
            float: "left",
            "margin-left": "20px",
          };

          styles22 = {
            width: "100px",
            "margin-left": "10px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
            float: "left",
          };

          styles28 = {
            width: "90px",
            "margin-left": "10px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
            float: "left",
          };

          stylesDDD = {
            width: "210px",
          
          };

          stylesZoom = {
            position: "absolute",
            top: "50%",
            left: "50%",
            "margin-top": "-50px",
            "margin-left": "-50px",
            width: "100px",
            height: "100px",
          };

          licenca = {
            border: "1px solid red",
            width: "300px",
           
          };

          koverta = {
           
            "margin-top": "300px",
            "margin-left": "400px",         
            //"text-align": "center",
            //"writing-mode": "tb-rl",
            transform: "rotate(90deg)",
            transformOrigin: "0 0"       
           
          };

          koverta2 = {
            position: "absolute",
            top: "50%",
            left: "50%",
            "margin-top": "-150px",
            "margin-left": "-150px",         
            //"text-align": "center",
            //"writing-mode": "tb-rl",
            transform: "rotate(90deg)",
            //transformOrigin: "0 0"       
           
          };

   

          logoStyle = {
            height: "90px",
            width: "350px",
            "margin-left": "50px",
            "margin-top": "50px",
          };


          danas = () => {
            let da = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
            
            this.setState({ day: da });
        
            this.setState({ datumUplate: da });
           
          };

    componentDidMount() {

        this.dajLozinke();
        this.danas();
        this.sifreUplata();
        this.dajUplateNove();
        this.dajBodoveNove();
        this.dajKongresiSifre();
        this.statusiF();
        this.statusiZaposlenjeF();
        this.tituleF();
        this.specijalizacijeF();
        this.ordinacijeF();
        this.mjestaF();
        this.regijeF();
        this.sudCastiF();
        this.skupstinaF();
        this.nadzorniOdborF();
        this.komisijeF();
        this.izvrsniOdborF();
        this.disciplinskiTuzilacF();
        this.dajSveClanoveF();
        

        let filterSlova = document.getElementById("filterSlova");
        let filterRS = document.getElementById("filterRS");
        let filterST = document.getElementById("filterST");
        let filterORD = document.getElementById("filterORD");

        filterSlova.addEventListener("keyup", (ff) => {
          if (filterSlova.value.length > 2 || filterSlova.value == "") {
            let filter=filterRS.value;
            let filter2=filterST.value;
            let slova=filterSlova.value;
            let ord=filterORD.value;
            this.pretraga(slova,filter,filter2,ord);
             //alert(filter+slova);
          }
        });

        filterRS.addEventListener("change", (ff) => {
          let filter=filterRS.value;
          let filter2=filterST.value;
          let slova=filterSlova.value;
          let ord=filterORD.value;
          this.pretraga(slova,filter,filter2,ord);
          //alert(filter+slova);
         });
     
     
         filterST.addEventListener("change", (ff) => {
           let filter=filterRS.value;
           let filter2=filterST.value;
           let slova=filterSlova.value;
           let ord=filterORD.value;
            this.pretraga(slova,filter,filter2,ord);
           //alert(filter+slova);
          });

          filterORD.addEventListener("change", (ff) => {
            let filter=filterRS.value;
            let filter2=filterST.value;
            let slova=filterSlova.value;
            let ord=filterORD.value;
            this.pretraga(slova,filter,filter2,ord);
            //alert(ord);
           });

         

        
      }


    dajSveClanoveF = () => {
    
        let bb = [];
        this.setState({ baza: [] });
        this.setState({ bazaP: [] });
        dajSveClanove().then((snapshot) => {
          snapshot.forEach((ss) => {
            let uid = ss.val().uid;
            let adresa = ss.val().adr;
            let slikaLicence=ss.val().slikaLicence;
           let bodoviX=[];
            ss.child("bodovi").forEach((gg) => {
          
              let idd=gg.val().id;
              let seminar=gg.val().seminar;
              let dat=gg.val().dat;
              let cif=gg.val().cif;
              let dr_id=gg.val().dr_id;
              let slika=gg.val().slika;
              

            bodoviX.push({

              id: idd,
              seminar: seminar,
              dat: dat,
              cif: cif,
              dr_id: dr_id,
              slika: slika,

            })

            })

            let uplateX=[];
            ss.child("uplate").forEach((gg) => {
          
              let idd=gg.val().id;
              let datumUplate=gg.val().datumUplate;            
              let iznosUplate=gg.val().iznosUplate;
              let dr_id=gg.val().dr_id;
              let period=gg.val().period;

              uplateX.push({

              id: idd,
              datumUplate: datumUplate,
              iznosUplate: iznosUplate,
              dr_id: dr_id,
              period: period,

            })

            })

            let broj = ss.val().broj;
            let datumLicence = ss.val().dat_lic.substring(0, 10);
            let disciplinski = ss.val().disciplinski;
            let id = ss.val().id;
            let ime = ss.val().ime;
            let izvrsni = ss.val().izvrsni;
            let jmbg = ss.val().jmbg;
            let komisija1 = ss.val().komisija1;
            let komisija2 = ss.val().komisija2;
            let mail = ss.val().mail;
            let mejestoID = ss.val().mes_id;
            let regijaID = ss.val().regijaId;
            let nadzorni = ss.val().nadzorni;
            let ordinacijaId = ss.val().ord_id;
            let pol = ss.val().pol;
            let prezime = ss.val().prez;
            let skupstina = ss.val().skupstina;
            let specijalizacijaId = ss.val().spec_id;
            let specijalizacijaId2 = ss.val().spec_id2;
            let status = ss.val().status;
            let statusZaposlenje = ss.val().statusZaposlenje;
            let sud = ss.val().sud;
            let telefon = ss.val().tel;
            let titula = ss.val().tit;
            let uplate = ss.val().uplate;
            let uplateNove = ss.val().uplateNove;
            let androidStatus = ss.val().androidStatus;
            let androidDozvola = ss.val().androidDozvola;
            let slika = ss.val().slika;

            
            let lozinka = "";
            this.state.lozinkeO.map((sss) => {
              if (sss.uid == uid) {
                lozinka = sss.lozinka;
              }
            });

            let disciplinskiS = "";
            this.state.disciplinskiTuzilacO.map((sss) => {
              if (sss.broj == disciplinski) {
                disciplinskiS = sss.naziv;
              }
            });
            
            let izvrsniS = "";
            this.state.izvrsniOdborO.map((sss) => {
              if (sss.broj == izvrsni) {
                izvrsniS = sss.naziv;
              }
            });
            let komisija1S = "";
            this.state.komisijeO.map((sss) => {
              if (sss.broj == komisija1) {
                komisija1S = sss.naziv;
              }
            });

            let komisija2S = "";
            this.state.komisijeO.map((sss) => {
              if (sss.broj == komisija2) {
                komisija2S = sss.naziv;
              }
            });
            let mejestoIDS = "";
            let postanskiBroj = "";
            this.state.mjestaO.map((sss) => {
              if (sss.id == mejestoID) {
                mejestoIDS = sss.mjesto;
                postanskiBroj = sss.ptt;
              }
            });
            let regijaIDS = "";
            this.state.regijeO.map((sss) => {
              if (sss.broj == regijaID) {
                regijaIDS = sss.naziv;
              }
            });
            let nadzorniS = "";
            this.state.nadzorniOdborO.map((sss) => {
              if (sss.broj == nadzorni) {
                nadzorniS = sss.naziv;
              }
            });

            let ordinacijaIdS = "";
            let ordinacijaAdresa = "";
            let ordinacijaMjestoId = "";
            let ordinacijaMjestoIdS = "";
            this.state.ordinacijeO.map((sss) => {
              if (sss.id == ordinacijaId) {
                ordinacijaIdS = sss.naziv;
                ordinacijaAdresa = sss.adresa;
                ordinacijaMjestoId = sss.mjestoId

              }
            });

                 this.state.mjestaO.map((fff) => {
                  if (fff.id == ordinacijaMjestoId) {
                    ordinacijaMjestoIdS = fff.mjesto;
                    
                  }
                });

            let skupstinaS = "";
            this.state.skupstinaO.map((sss) => {
              if (sss.broj == skupstina) {
                skupstinaS = sss.naziv;
              }
            });
            let specijalizacijaIdS = "";
            this.state.specijalizacijeO.map((sss) => {
              if (sss.id == specijalizacijaId) {
                specijalizacijaIdS = sss.spec;
              }
            });
            let specijalizacijaIdS2 = "";
            this.state.specijalizacijeO.map((sss) => {
              if (sss.id == specijalizacijaId2) {
                specijalizacijaIdS2 = sss.spec;
              }
            });
            let statusS = "";
            this.state.statusiO.map((sss) => {
              if (sss.broj == status) {
                statusS = sss.naziv;
              }
            });
            let statusZaposlenjeS = "";
            this.state.statusiZaposlenjeO.map((sss) => {
              if (sss.broj == statusZaposlenje) {
                statusZaposlenjeS = sss.naziv;
              }
            });
            
            let sudS = "";
            this.state.sudCastiO.map((sss) => {
              if (sss.broj == sud) {
                sudS = sss.naziv;
              }
            });
            let titulaS = "";
            this.state.tituleO.map((sss) => {
              if (sss.broj == titula) {
                titulaS = sss.naziv;
              }
            });

           //alert(uplate[0]);

            bb.push({
              lozinka:lozinka,
              adresa: adresa,
              bodovi: bodoviX,
              broj: broj,
              datumLicence: datumLicence,
              disciplinski: disciplinski,
              id: id,
              ime: ime,
              izvrsni: izvrsni,
              jmbg: jmbg,
              komisija1: komisija1,
              komisija2: komisija2,
              mail: mail,
              mejestoID: mejestoID,
              regijaID: regijaID,
              nadzorni: nadzorni,
              ordinacijaId: ordinacijaId,
              pol: pol,
              prezime: prezime,
              skupstina: skupstina,
              specijalizacijaId: specijalizacijaId, 
              specijalizacijaId2: specijalizacijaId2,            
              status: status,
              statusZaposlenje: statusZaposlenje,
              sud: sud,
              telefon: telefon,
              titula: titula,
              uid: uid,
              uplate: uplateX,
              uplateNove: uplateNove,
              androidStatus: androidStatus,
              androidDozvola: androidDozvola,
              slika: slika,
              slikaLicence: slikaLicence,
              disciplinskiS: disciplinskiS,
              izvrsniS: izvrsniS,
              komisija1S: komisija1S,
              komisija2S: komisija2S,
              mejestoIDS: mejestoIDS,
              postanskiBroj: postanskiBroj,
              regijaIDS: regijaIDS,
              nadzorniS, nadzorniS,
              ordinacijaIdS: ordinacijaIdS,
              ordinacijaAdresa: ordinacijaAdresa,
              ordinacijaMjestoId: ordinacijaMjestoId,
              ordinacijaMjestoIdS: ordinacijaMjestoIdS,
              skupstinaS: skupstinaS,
              specijalizacijaIdS: specijalizacijaIdS,
              specijalizacijaIdS2: specijalizacijaIdS2,
              statusS: statusS,
              statusZaposlenjeS: statusZaposlenjeS,
              sudS: sudS,
              titulaS: titulaS,
            });
          });
          bb= bb.sort((a,b) => parseInt(a.broj) > parseInt(b.broj) ? 1 : parseInt(b.broj) > parseInt(a.broj) ? -1 : 0);

          this.setState({ baza: bb });
          this.setState({ bazaP: bb });
         
          this.setState({
            brojClanova: (
              <div >
                <label >{bb.length}</label>
                </div>

                )});

        }).then((snapshot) => {

          document.getElementById("filterST").value=1;
          document.getElementById("filterST").dispatchEvent(new Event("change"));
           this.akcija(this.state.bazaP[0]);

        })

       
      };

      pretraga = (slova,filter,filter2,ord) => {
        let bbP = [];
        this.setState({ bazaP: [] });
        var frt = 1;
        this.state.baza.forEach(function (ss) {
          frt++;
          try {

            let broj= ss.broj.toString();

            let ime= ss.ime.toString();
      
            let jmbg= ss.jmbg.toString();
      
            let regijaID = ss.regijaID;
       
            let ordinacijaId= ss.ordinacijaId;
        
            let prezime= ss.prezime.toString();
              
            let status= ss.status.toString();
        
            if (
              (ime.toLowerCase().includes(slova.toLowerCase()) ||
              prezime.toLowerCase().includes(slova.toLowerCase()) ||
              broj.toLowerCase()==(slova.toLowerCase().replace("lc","")) 
              || jmbg.toLowerCase().includes(slova.toLowerCase())
              )
    
              && ( filter == "sve" ||
              filter == regijaID
             )
    
              && ( filter2 == "sve" ||
              filter2 == status
             )

             && ( ord == "sve" ||
             ord == ordinacijaId
            )

            )
              bbP.push(
                ss
                );

          } catch (er) {
            alert(ss.key);
          }
        });
    
        this.setState({ bazaP: bbP });

        this.setState({
          brojClanova: (
            <div >
              <label >{bbP.length}</label>
              </div>

              )});
      
      };

      dajLozinke = () => {
        lozinke().then((snapshot) => {
          let ff = [];
          snapshot.forEach((loz) => {
            let uid = loz.key;
            let lozinka = loz.val().lozinka;

            ff.push({
              uid: uid,
              lozinka: lozinka,
              
            });       

          })
          this.setState({ lozinkeO: ff });
        })

      }

  
      dajUplateNove = () => {

        uplateN().then((snapshot) => {
         let ff = [];
          snapshot.forEach((clan) => {
            var uid = clan.key;
            clan.forEach((uplata) => {
            
            let key =uplata.key;
            let vrstaUplate = uplata.val().vrstaUplate;
            let iznosUplate = uplata.val().iznosUplate;
            let datumUplate = uplata.val().datumUplate;
            let datumOD = uplata.val().datumOD;
            let datumDO = uplata.val().datumDO;
    
            ff.push({
              uid: uid,
              key: key,
              vrstaUplate: vrstaUplate,
              iznosUplate: iznosUplate,
              datumUplate: datumUplate,
              datumOD: datumOD,
              datumDO: datumDO,
            
            });       

          });
             
        })

      this.setState({ uplateN: ff });

      });
    }

      
    dajBodoveNove = () => {

      bodoviN().then((snapshot) => {
       let ff = [];
        snapshot.forEach((clan) => {
          var uid = clan.key;
          clan.forEach((bod) => {
          
            let key =bod.key;
             let id =bod.val().id;
             let datum = bod.val().datum;
             let naziv = bod.val().naziv;
             let bodovi = bod.val().bodovi;
             let grad = bod.val().grad;
             let drzava = bod.val().drzava;
             let sd = bod.val().sd;
             let slika = bod.val().slika;
             let potvrda= bod.val().potvrda;
           
             ff.push({
               uid: uid,
               key: key,
               id: id,
               datum: datum,
               naziv: naziv,
               bodovi: bodovi,
               grad: grad,
               drzava: drzava,
               sd: sd,
               slika: slika,
               potvrda: potvrda,
          
          });

        
        });
  
        this.setState({ bodoviNoviO: ff });
       
      })
    });
  }

    dajKongresiSifre = () => {

      kongresiSifre().then((snapshot) => {
       let ff = [];
        snapshot.forEach((kongres) => {
          
          let key =kongres.key;
          let naziv = kongres.val().naziv;
          let datum = kongres.val().datum;
          let bodovi = kongres.val().bodovi;
          let sd = kongres.val().sd;
          let grad = kongres.val().grad;
          let drzava = kongres.val().drzava;
  
          ff.push({
          
            key: key,
            naziv: naziv,
            datum: datum,
            bodovi: bodovi,
            sd: sd,
            grad: grad,
            drzava: drzava,
          
        });
  
      })
        this.setState({ kongresiSifreO: ff });
        this.setState({ kongresiPretraga: ff });

    });
  }



      tituleF = () => {
        titule().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ tituleO: ff });
        });
      };

      statusiF = () => {
        statusi().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {

            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ statusiO: ff });
        });
      };

      statusiZaposlenjeF = () => {
        statusiZaposlenje().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ statusiZaposlenjeO: ff });
        });
      };

      mjestaF = () => {
        mjesta().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
               
            let broj = ss.val().broj;
            let id = ss.val().id;
            let mjesto = ss.val().mjesto;
            let ptt = ss.val().ptt;
            let regija = ss.val().regija;
    
            ff.push({
              broj: broj, 
              id: id,
              mjesto: mjesto,
              ptt: ptt,
              regija: regija,            
            });
          });
    
          this.setState({ mjestaO: ff });
        });
      };

      regijeF = () => {
        regije().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
               
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ regijeO: ff });
        });
      };

      ordinacijeF = () => {
        ordinacije().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
               
            let adresa = ss.val().adresa;
            let id = ss.val().id;
            let mjestoId = ss.val().mjestoId;
            let naziv = ss.val().naziv;
            let nosioc = ss.val().nosioc;
            let sektor = ss.val().sektor;
            let telefon = ss.val().telefon;
            let vrsta = ss.val().vrsta;
    
            ff.push({
              adresa: adresa, 
              id: id,
              mjestoId: mjestoId,
              naziv: naziv,
              nosioc: nosioc,
              sektor: sektor,
              telefon: telefon,
              vrsta: vrsta,                
            });
          });
    
          this.setState({ ordinacijeO: ff });
        });
      };

      disciplinskiTuzilacF = () => {
        disciplinskiTuzilac().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ disciplinskiTuzilacO: ff });
        });
      };

      izvrsniOdborF = () => {
        izvrsniOdbor().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ izvrsniOdborO: ff });
        });
      };

      komisijeF = () => {
        komisije().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ komisijeO: ff });
        });
      };

      nadzorniOdborF = () => {
        nadzorniOdbor().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ nadzorniOdborO: ff });
        });
      };

      skupstinaF = () => {
        skupstina().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ skupstinaO: ff });
        });
      };

      sudCastiF = () => {
        sudCasti().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });

            
          });
    
          this.setState({ sudCastiO: ff });
        });
      };

      specijalizacijeF = () => {
        specijalizacije().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            
    
            let id = ss.val().id;
            let spec = ss.val().spec;
    
            ff.push({
              id: id,
              spec: spec,
            });
          });
    
          this.setState({ specijalizacijeO: ff });
        });
      };

      sifreUplata = () => {
        uplateSifre().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let sifra = ss.key;
    
            let cijena = ss.val().cijena;
            let dozvola = ss.val().dozvola;
            ff.push({
              sifra: sifra,
              cijena: cijena,
              dozvola: dozvola,
            });

          });
            ff.reverse();
    
          this.setState({ uplateO: ff });
        });
      };


      akcija = (clan) => {
        this.setState({ uriSlike: "" });
        try {


          let slika = clan.slika;
          if (clan.slika == null || clan.slika == "" || clan.slika == "1") {
            slika = stomatologLogo;
          }

          let slikaLicence ="";
          slikaLicence=clan.slikaLicence;
          
          if (clan.slikaLicence == null || clan.slikaLicence == "" || clan.slikaLicence == "1" || clan.slikaLicence === undefined) {
            slikaLicence = logoa4;
          
          }

          napomene(clan.uid).then((snapshot) => {
        
            let nap = [];
            snapshot.forEach((ss) => {
            let kljucNapomene=ss.key;
              let datumNapomene = ss.val().datumNapomene
              
              let napomena = ss.val().napomena
                nap.push({
              datumNapomene: datumNapomene,
              napomena: napomena,
              kljucNapomene: kljucNapomene,
              uid: clan.uid
            })
             
    
            })

          let datumLicString= clan.datumLicence.split("-")[2]+"."+clan.datumLicence.split("-")[1]+"."+clan.datumLicence.split("-")[0]

           let kovertaIme=clan.titulaS+" "+clan.prezime+" "+clan.ime;
           let kovertaAdresaC=clan.adresa;
           let kovertaGradC=clan.postanskiBroj+" "+clan.mejestoIDS;

           let kovertaOrdinacija=clan.ordinacijaIdS;
           let kovertaAdresaO=clan.ordinacijaAdresa;          
           let kovertaGradO="";
 

           this.state.mjestaO.map((ooo) => {
            if(ooo.id==clan.ordinacijaMjestoId){
              kovertaGradO=ooo.ptt+" "+ooo.mjesto;
              
            }

           })
          

          this.setState({ clan: clan });
          this.setState({
            profil: (
              <div id="stampaProfil" className="ms-2 mt-3" style={this.styles3}>
                <label class="labelMali2">Status </label>
                {":  "}
                <label class="labelVeliki2">{clan.statusS}</label>
                <br />  
                <label class="labelMali">Licenca </label>
                {":  "}
                <label class="labelVeliki">{clan.broj}</label>
                <br />
                <label class="labelMali2">Datum licence </label>
                {":  "}
                <label class="labelVeliki2">{datumLicString}</label>
                <br /> 
                <img className="m-2" src={slika} style={this.styleSlika} />
                <br />
               
                <label class="labelMali2">Titula </label>
                {":  "}
                <label class="labelVeliki2">{clan.titulaS}</label>
                <br /> 
               
                <label class="labelMali">Ime </label>
                {":  "}
                <label class="labelVeliki">{clan.ime}</label>
                <br />
                <label class="labelMali">Prezime </label>
                {":  "}
                <label class="labelVeliki">{clan.prezime}</label>
                <br /> 
                 <label class="labelMali2">Specijalizacija 1</label>
                {":  "}
                <label class="labelVeliki2">{clan.specijalizacijaIdS}</label>
                <br />
                <label class="labelMali2">Specijalizacija 2</label>
                {":  "}
                <label class="labelVeliki2">{clan.specijalizacijaIdS2}</label>
                <br />
                <label class="labelMali2">Jmbg </label>
                {":  "}
                <label class="labelVeliki2">{clan.jmbg}</label>
                <hr />

                <label class="labelMali2">Adresa</label>
                {":  "}
                <label class="labelVeliki2">{clan.adresa}</label>
                <br />
                <label class="labelMali2">Mjesto</label>
                {":  "}
                <label class="labelVeliki2">{clan.mejestoIDS}</label>
                <br />
                <label class="labelMali2">Regija</label>
                {":  "}
                <label class="labelVeliki2">{clan.regijaIDS}</label>
                <hr />

                <label class="labelMali2">Email </label>
                {":  "}
                <label class="labelVeliki2">{clan.mail}</label>
                <br />
                <label class="labelMali2">Telefon </label>
                {":  "}
                <label class="labelVeliki2">{clan.telefon}</label>
                <br />
                <label class="labelMali2">Status zaposlenje</label>
                {":  "}
                <label class="labelVeliki2">{clan.statusZaposlenjeS}</label>
                <br />
                <label class="labelMali2">Ordinacija</label>
                {":  "}
                <label class="labelVeliki2">{clan.ordinacijaIdS}</label>
                <br />  
                <label class="labelVeliki2">{clan.ordinacijaMjestoIdS}</label>
                <br />                               
                <label class="labelMali2">Pol</label>
                {":  "}
                <label class="labelVeliki2">{clan.pol}</label>
                <br />
                <label class="labelMali2">Skupština</label>
                {":  "}
                <label class="labelVeliki2">{clan.skupstinaS}</label>
                <br />
                <label class="labelMali2">Izvršni odbor</label>
                {":  "}
                <label class="labelVeliki2">{clan.izvrsniS}</label>
                <br />
                <label class="labelMali2">Nadzorni odbor</label>
                {":  "}
                <label class="labelVeliki2">{clan.nadzorniS}</label>
                <hr />

                <label class="labelMali2">Komisije 1</label>
                {":  "}
                <label class="labelVeliki2">{clan.komisija1S}</label>
                <br />
                <label class="labelMali2">Komisije 2</label>
                {":  "}
                <label class="labelVeliki2">{clan.komisija2S}</label>
                <br />
                <label class="labelMali2">Disciplinski tužioc</label>
                {":  "}
                <label class="labelVeliki2">{clan.disciplinskiS}</label>
                <br />
                <label class="labelMali2">Sud časti</label>
                {":  "}
                <label class="labelVeliki2">{clan.sudS}</label>
                <br />


                <label class="labelMali2">Android status</label>
                {":  "}
                <label class="labelVeliki2">{clan.androidStatus}</label>
                <br />
                <label class="labelMali2">Android dozvola</label>
                {":  "}
                <label class="labelVeliki2">{clan.androidDozvola}</label>
                <br />
                <label class="labelMali2">Uid</label>
                {":  "}
                <label class="labelVeliki2">{clan.uid}</label>
                <br />
                <label class="labelMali2">Lozinka</label>
                {":  "}
                <label class="labelVeliki2">{clan.lozinka}</label>
    
                <br />

                <div  style={{ padding: "10px" }}>
              <Form.Control
              id="napomeneI"
              as="select"
              onClick={() => this.prikaziNapomenu(clan)}
              multiple
              className="p-1 m-1 mt-2 mr-2"
              style={{ height: "160px" }}
            >
              {nap.map((ss) => {
             var datumNapomene=ss.datumNapomene.split("-")[2]+"."+ss.datumNapomene.split("-")[1]+"."+ss.datumNapomene.split("-")[0]
                  return (
                    <option className="text-success" value={ss.uid + ":" + ss.kljucNapomene+ ":" + ss.napomena+ ":" + datumNapomene}>
                      {datumNapomene}  {ss.napomena}  
                    </option>
                  );
              })}

            </Form.Control>
              </div>


                <div>
                <Button
                  className="btn btn-outline-danger centerButton mt-1  mr-3"
                  size="sm"
                  variant="outline-danger"
                  onClick={() => this.dodajNapomenu(clan)}
                >
                  Dodaj napomenu
                </Button>
                <Button
                  className="btn btn-outline-danger centerButton mt-1 ms-3"
                  size="sm"
                  id= "brisiNapomenu"
                  variant="outline-danger"
                  onClick={() => this.brisiNapomenu(clan)}
                >
                  Briši napomenu
                </Button>
                </div>

                        
             
                <Button
                  className="btn btn-danger centerButton m-3"
                  size="sm"
                  onClick={() => this.izmjena(clan)}
                >
                  Izmijeni podatke
                </Button>
               <div>
               <img className="m-2" id="slikaLicence" src={slikaLicence} style={this.licenca} />

               </div>

               <div>
               <label for="file-upload2" class="meme"   style={{
                    border: "1px solid red",
                    width: "180px",
                    color: "#dc3545",
                    "font-weight": "bold",
                  }}>
                  Dodaj sliku licence
                </label>
                <input
                  id="file-upload2"
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={this.fileChangedHandlerLic}
                />
                 <Button
                  className="m-2 btn btn-danger centerButton"
                  size="sm"
                  onClick={()=>this.izbrisiSlikuLicence(slikaLicence)}
                >
                  Izbriši sliku licence
                </Button>
                </div>

                
                <div
                  className="d-none d-print-block"
                  ref={(el) => (this.sss = el)}
                >
                  <img id="slikaLicenceS" className="m-2" src={slikaLicence} style={this.slikaDok2} />
                </div>

                <ReactToPrint
              trigger={() => (
                <Button
                  size="sm"
                  className="m-2 btn btn-success centerButton m-2"
                  href="#"
                >
                  Štampaj licencu
                </Button>
              )}
              content={() => this.sss}
            />
             <div>
                <div
                  className="d-none d-print-block"
                  ref={(ne) => (this.mmm = ne)}
                >
                  <div style={this.koverta}>
                 
                  <h5 >
                  {kovertaIme}
                  </h5>
                  <h5 >
                  {kovertaAdresaC}
                  </h5>
                  <h5 >
                  {kovertaGradC} 
                  </h5>

                  </div>
                 

                </div>
             
                <ReactToPrint
                trigger={() => (
                <Button
                  size="sm"
                  className="m-2 btn btn-success centerButton m-2"
                  href="#"
                >
                  Print koverte
                </Button>
              )}
              content={() => this.mmm}
            />
         
                  <div
                  className="d-none d-print-block"
                  ref={(re) => (this.ttt = re)}
                >
                  <div style={this.koverta}>
                  <h5 >
                  {kovertaOrdinacija}
                  </h5>
                  <h5 >
                  {kovertaIme}
                  </h5>
                  <h5 >
                  {kovertaAdresaO}
                  </h5>
                  <h5 >
                  {kovertaGradO} 
                  </h5>

                  </div>
                 

                </div>
             
                <ReactToPrint
                trigger={() => (
                <Button
                  size="sm"
                  className="m-2 btn btn-success centerButton m-2"
                  href="#"
                >
                  Print koverte ordinacija
                </Button>
              )}
              content={() => this.ttt}
            />


          </div>
           
                 
              </div>
            ),
          },);
    
        });
          
    
    
    
        } catch (err) {}

        this.uplateForma(clan);
        this.kongresiForma(clan);
      };

       prikaziNapomenu = (clan) => {
        let v = document.getElementById("napomeneI");
        try{
        let selektovananap = v.options[v.selectedIndex].value.split(":");
        let napomena =selektovananap[3] +" "+ selektovananap[2];
        alert(napomena);}
        catch{}
       }

       dodajNapomenu = (clan) => {
         var uid=clan.uid;
       var napomena = window.prompt("Unesite napomenu?");
        try{
          
          var datumNapomene=this.state.day;
          upisiNapomenu(uid,napomena,datumNapomene);
          this.akcija(clan);
        }catch{}
       }


       brisiNapomenu = (clan) => {
        if (window.confirm("Obriši selektovanu napomenu")) {
          let dugme = document.getElementById("brisiNapomenu");
          dugme.disabled = true;
    
          try {
            let v = document.getElementById("napomeneI");
            let selektovanaUplata = v.options[v.selectedIndex].value.split(":");
            let uid = selektovanaUplata[0];
            let kljucNapomene = selektovanaUplata[1];
    
                  
            obrisiNapomenu(uid, kljucNapomene).then(() => {
            dugme.disabled = false;
             this.akcija(clan);
         
              
              });
         
          } catch (err) {
            dugme.disabled = false;
            alert("Niste selektovali ni jednu napomenu");
          }
        } else {
        }
      };


      izmjena = (clan) => {
        let slika = clan.slika;
        let slika1 = clan.slika;
      
        if (clan.slika == null || clan.slika == ""|| clan.slika == "1") {
          slika = stomatologLogo;
        }

        try {
          if (clan.datumLicence == "" || clan.datumLicence.includes("undefined")) {
          } else {
          
            this.setState({ datumLicence: clan.datumLicence });
          }
        } catch {
          alert("greska datum licence");
        }
    
       // filterRS.addEventListener("click", (ff) => {   
          //this.pretraga(slova,filter,filter2);
        // });
       
        let ordi=[];
        this.state.ordinacijeO.map((d) => {
          var naziv=d.naziv;
          naziv=naziv.replace("\"","")
          naziv=naziv.replace("\"","")
          naziv=naziv.replace("ZU stom.ambu.","")
          naziv=naziv.replace("ZU spec.stom.ambu.","")
          naziv=naziv.trim();
          naziv=naziv.trimStart();
          var id=d.id;

          ordi.push({
            naziv: naziv,
            id: id,
          })

         ordi= ordi.sort((a,b) => a.naziv > b.naziv ? 1 : b.naziv > a.naziv ? -1 : 0);
        })


        this.setState({ clan: clan });
        this.setState({
          profil: (
            <div id="izmjeniProfil" className="ms-2 mt-3" style={this.stylesProfil}>
              <Form onSubmit={this.snimiPromjene}>
                <h6>Status:</h6>
                <Input id="statusI" as="select">
                  {this.state.statusiO.map((ss) => {
                    if (ss.broj == clan.status) {
                      return (
                        <option selected value={ss.broj}>
                          {ss.naziv}
                        </option>
                      );
                    } else {
                      return <option value={ss.broj}>{ss.naziv}</option>;
                    }
                  })}
                </Input>
                <br />
                <h6>Broj licence:</h6>
                <Form.Control
                  type="text"
                  id="brojLicenceI"
                  placeholder="Broj licence"
                  defaultValue={clan.broj}
                />
                <br />
                <h6>Datum licence:</h6>
                <DatePickerInput
                  id="datumLicenceI"
                  defaultValue={clan.datumLicence}
                  //locale="es"
                  onChange={this.datumLicence}
                />
   
              <img className="m-2" id="slikaProfil" src={slika} style={this.styleSlika} />
        
                <label for="file-upload" class="meme"   style={{
                    border: "1px solid red",
                    width: "180px",
                    color: "#dc3545",
                    "font-weight": "bold",
                  }}>
                  Promijeni sliku
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={this.fileChangedHandler}
                />
                 <Button
                  className="m-2 btn btn-danger centerButton"
                  size="sm"
                  onClick={()=>this.izbrisiSliku(slika1)}
                >
                  Izbriši sliku
                </Button>

            
            <br />
            <br />
                <h6>Titula:</h6>
                <Input id="titulaI" as="select">
                  {this.state.tituleO.map((ss) => {
                    if (ss.broj == clan.titula) {
                      return (
                        <option selected value={ss.broj}>
                          {ss.naziv}
                        </option>
                      );
                    } else {
                      return <option value={ss.broj}>{ss.naziv}</option>;
                    }
                  })}
                </Input>
                <br />
                <h6>Ime: </h6>
                <Form.Control
                  required
                  type="text"
                  id="imeI"
                  placeholder="Ime člana"
                  defaultValue={clan.ime}
                />
               <br />
                <h6>Prezime:</h6>
                <Form.Control
                  required
                  type="text"
                  id="prezimeI"
                  placeholder="Prezime člana"
                  defaultValue={clan.prezime}
                />
                <br />
                <h6>Specijalizacija:</h6>
                <Input id="specijalizacijaI" as="select">
                  {this.state.specijalizacijeO.map((ss) => {
                    if (ss.id == clan.specijalizacijaId) {
                      return (
                        <option selected value={ss.id}>
                          {ss.spec}
                        </option>
                      );
                    } else {
                      return <option value={ss.id}>{ss.spec}</option>;
                    }
                  })}
                </Input>
                <br />
                <h6>Specijalizacija2:</h6>
                <Input id="specijalizacijaI2" as="select">
                  {this.state.specijalizacijeO.map((ss) => {
                    if (ss.id == clan.specijalizacijaId2) {
                      return (
                        <option selected value={ss.id}>
                          {ss.spec}
                        </option>
                      );
                    } else {
                      return <option value={ss.id}>{ss.spec}</option>;
                    }
                  })}
                </Input>
                <br />
                <h6>JMBG:</h6>
                <Form.Control
                  required
                  type="text"
                  id="jmbgI"
                  placeholder="JMBG člana"
                  defaultValue={clan.jmbg}
                />
              <br />
              <h6>Adresa:</h6>
                <Form.Control
                  required
                  type="text"
                  id="adresaI"
                  placeholder="Adresa člana"
                  defaultValue={clan.adresa}
                />
              <br />
              <h6>Mjesto:</h6>
                <Input id="mjestoI" as="select">
                  {this.state.mjestaO.map((ss) => {
                    if (ss.id == clan.mejestoID) {
                      return (
                        <option selected value={ss.id}>
                          {ss.mjesto}
                        </option>
                      );
                    } else {
                      return <option value={ss.id}>{ss.mjesto}</option>;
                    }
                  })}
                </Input>

                <br />
              <h6>Regija:</h6>
                <Input id="regijaI" as="select">
                  {this.state.regijeO.map((ss) => {
                    if (ss.broj == clan.regijaID) {
                      return (
                        <option selected value={ss.broj}>
                          {ss.naziv}
                        </option>
                      );
                    } else {
                      return <option value={ss.broj}>{ss.naziv}</option>;
                    }
                  })}
                </Input>
                <br />
                <h6>E-mail:</h6>
                <Form.Control
                  required
                  type="text"
                  id="emailI"
                  placeholder="E-mail"
                  defaultValue={clan.mail}
                />
              <br />
               <h6>Telefon:</h6>
                <Form.Control
                  required
                  type="text"
                  id="telefonI"
                  placeholder="Telefon"
                  defaultValue={clan.telefon}
                />
              <br />
              <h6>Status zaposlenja:</h6>
                <Input id="statusZaposlenjaI" as="select">
                  {this.state.statusiZaposlenjeO.map((ss) => {
                    if (ss.broj == clan.statusZaposlenje) {
                      return (
                        <option selected value={ss.broj}>
                          {ss.naziv}
                        </option>
                      );
                    } else {
                      return <option value={ss.broj}>{ss.naziv}</option>;
                    }
                  })}
                </Input>
                <br />
                <h6>Ordinacija:</h6>
                <Input id="ordinacijaI" as="select">
                  {ordi.map((ss) => {
                    if (ss.id == clan.ordinacijaId) {
                      return (
                        <option selected value={ss.id}>
                          {ss.naziv}
                        </option>
                      );
                    } else {
                      return <option value={ss.id}>{ss.naziv}</option>;
                    }
                  })}
                </Input>
                <br />
                <h6>Pol:</h6>
                <Input defaultValue={clan.pol} id="polI" as="select">
                <option value="M">Muški</option>
                <option value="Z">Ženski</option>
                </Input>

                <br />
                <h6>Skupština:</h6>
                <Input id="skupstinaI" as="select">
                  {this.state.skupstinaO.map((ss) => {
                    if (ss.broj == clan.skupstina) {
                      return (
                        <option selected value={ss.broj}>
                          {ss.naziv}
                        </option>
                      );
                    } else {
                      return <option value={ss.broj}>{ss.naziv}</option>;
                    }
                  })}
                </Input>
                <br />
                <h6>Izvršni odbor:</h6>
                <Input id="izvrsniI" as="select">
                  {this.state.izvrsniOdborO.map((ss) => {
                    if (ss.broj == clan.izvrsni) {
                      return (
                        <option selected value={ss.broj}>
                          {ss.naziv}
                        </option>
                      );
                    } else {
                      return <option value={ss.broj}>{ss.naziv}</option>;
                    }
                  })}
                </Input>

                <br />
                <h6>Nadzorni odbor:</h6>
                <Input id="nadzorniI" as="select">
                  {this.state.nadzorniOdborO.map((ss) => {
                    if (ss.broj == clan.nadzorni) {
                      return (
                        <option selected value={ss.broj}>
                          {ss.naziv}
                        </option>
                      );
                    } else {
                      return <option value={ss.broj}>{ss.naziv}</option>;
                    }
                  })}
                </Input>

                <br />
                <h6>Komisija 1:</h6>
                <Input id="komisija1I" as="select">
                  {this.state.komisijeO.map((ss) => {
                    if (ss.broj == clan.komisija1) {
                      return (
                        <option selected value={ss.broj}>
                          {ss.naziv}
                        </option>
                      );
                    } else {
                      return <option value={ss.broj}>{ss.naziv}</option>;
                    }
                  })}
                </Input>
                <br />
                <h6>Komisija 2:</h6>
                <Input id="komisija2I" as="select">
                  {this.state.komisijeO.map((ss) => {
                    if (ss.broj == clan.komisija2) {
                      return (
                        <option selected value={ss.broj}>
                          {ss.naziv}
                        </option>
                      );
                    } else {
                      return <option value={ss.broj}>{ss.naziv}</option>;
                    }
                  })}
                </Input>
                <br />
                <h6>Disciplinski tužilac:</h6>
                <Input id="disciplinskiI" as="select">
                  {this.state.disciplinskiTuzilacO.map((ss) => {
                    if (ss.broj == clan.disciplinski) {
                      return (
                        <option selected value={ss.broj}>
                          {ss.naziv}
                        </option>
                      );
                    } else {
                      return <option value={ss.broj}>{ss.naziv}</option>;
                    }
                  })}
                </Input>
                <br />
                <h6>Sud časti:</h6>
                <Input id="sudI" as="select">
                  {this.state.sudCastiO.map((ss) => {
                    if (ss.broj == clan.sud) {
                      return (
                        <option selected value={ss.broj}>
                          {ss.naziv}
                        </option>
                      );
                    } else {
                      return <option value={ss.broj}>{ss.naziv}</option>;
                    }
                  })}
                </Input>
                <br />
                <h6>Android dozvola:</h6>
                <Input defaultValue={clan.androidDozvola} id="androidDozvolaI" as="select">
                <option value="da">da</option>
                <option value="ne">ne</option>
                </Input>

                <Button
                  className="m-2 btn btn-success centerButton"
                  size="sm"
                  onClick={() => this.akcija(clan)}
                >
                  Nazad
                </Button>
                <Button
                  className="m-2 btn btn-danger centerButton"
                  size="sm"
                  type="submit"
                >
                  Snimi promjene
                </Button>
              </Form>
            </div>
          ),
        });
      };
//*
      snimiPromjene = (ev) => {
        ev.preventDefault();
        let clan = this.state.clan;
        let uid = clan.uid;
        let broj = document.getElementById("brojLicenceI").value;

        let tit = document.getElementById("titulaI").options[
          document.getElementById("titulaI").selectedIndex
        ].value;

        let ime = document.getElementById("imeI").value;
        ime = ime.toLowerCase();
        let ime1 = ime.slice(0, 1);
        let ime2 = ime.slice(1);
        ime = ime1.toUpperCase() + ime2;
    
        let prez = document.getElementById("prezimeI").value;    
        //prez = prez.toLowerCase();
        //let prezime1 = prez.slice(0, 1);
        //let prezime2 = prez.slice(1);
        //prez = prezime1.toUpperCase() + prezime2;
    
        let status = document.getElementById("statusI").options[
          document.getElementById("statusI").selectedIndex
        ].value;

        let spec_id = document.getElementById("specijalizacijaI").options[
          document.getElementById("specijalizacijaI").selectedIndex
        ].value;

        let spec_id2 = document.getElementById("specijalizacijaI2").options[
          document.getElementById("specijalizacijaI2").selectedIndex
        ].value;

        let jmbg = document.getElementById("jmbgI").value;
        let adr = document.getElementById("adresaI").value;
        let mail = document.getElementById("emailI").value;
        let tel = document.getElementById("telefonI").value;
    
        let mes_id = document.getElementById("mjestoI").options[
          document.getElementById("mjestoI").selectedIndex
        ].value;

        let regijaId = document.getElementById("regijaI").options[
          document.getElementById("regijaI").selectedIndex
        ].value;

        let statusZaposlenje = document.getElementById("statusZaposlenjaI").options[
          document.getElementById("statusZaposlenjaI").selectedIndex
        ].value;

        let ord_id = document.getElementById("ordinacijaI").options[
          document.getElementById("ordinacijaI").selectedIndex
        ].value;
    
        let pol = document.getElementById("polI").value;

        let skupstina = document.getElementById("skupstinaI").options[
          document.getElementById("skupstinaI").selectedIndex
        ].value;

        let izvrsni = document.getElementById("izvrsniI").options[
          document.getElementById("izvrsniI").selectedIndex
        ].value;

        let nadzorni = document.getElementById("nadzorniI").options[
          document.getElementById("nadzorniI").selectedIndex
        ].value;

        let disciplinski = document.getElementById("disciplinskiI").options[
          document.getElementById("disciplinskiI").selectedIndex
        ].value;


        let komisija1 = document.getElementById("komisija1I").options[
          document.getElementById("komisija1I").selectedIndex
        ].value;

        let komisija2 = document.getElementById("komisija2I").options[
          document.getElementById("komisija2I").selectedIndex
        ].value;

        let sud = document.getElementById("sudI").options[
          document.getElementById("sudI").selectedIndex
        ].value;
    

        let dat_lic =
          this.state.datumLicence;
          //let idF=clan.id;

        var dane = true;
        this.state.baza.forEach((ll) => {
          if (ll.jmbg == jmbg && ll.uid != uid) {
            dane = false;
          }
        });
    
        let androidDozvola = document.getElementById("androidDozvolaI").value;
      
        
        if (jmbg.length == 13 && dane) {
          if (window.confirm("Izmijeni podatke o članu?")) {


            izmjeniClana(
              adr,
              androidDozvola,
              broj,
              dat_lic,
              disciplinski,             
              ime,
              izvrsni,
              jmbg,
              komisija1,
              komisija2,
              mail,
              mes_id,
              regijaId,
              nadzorni,
              ord_id,
              pol,
              prez,
              skupstina,
              spec_id,
              spec_id2,
              status,
              statusZaposlenje,
              sud,
              tel,
              tit,
              uid
            )
  
            .then(() => {

              let lol = document.getElementById("filterSlova");
              lol.value = "";
  
              let bb = [];
              this.setState({ baza: [] });
              this.setState({ bazaP: [] });
              
              dajSveClanove().then((snapshot) => {
                snapshot.forEach((ss) => {
                  let uid = ss.val().uid;
                  let adresa = ss.val().adr;
                  let slikaLicence=ss.val().slikaLicence;
                 let bodoviX=[];
                  ss.child("bodovi").forEach((gg) => {
                
                    let idd=gg.val().id;
                    let seminar=gg.val().seminar;
                    let dat=gg.val().dat;
                    let cif=gg.val().cif;
                    let dr_id=gg.val().dr_id;
                    let slika=gg.val().slika;
      
                  bodoviX.push({
      
                    id: idd,
                    seminar: seminar,
                    dat: dat,
                    cif: cif,
                    dr_id: dr_id,
                    slika: slika,
      
                  })
      
                  })

                  let uplateX=[];
                  ss.child("uplate").forEach((gg) => {
                
                    let idd=gg.val().id;
                    let datumUplate=gg.val().datumUplate;            
                    let iznosUplate=gg.val().iznosUplate;
                    let dr_id=gg.val().dr_id;
                    let period=gg.val().period;
      
                    uplateX.push({
      
                    id: idd,
                    datumUplate: datumUplate,
                    iznosUplate: iznosUplate,
                    dr_id: dr_id,
                    period: period,
      
                  })
      
                  })
      
                  let broj = ss.val().broj;
                  let datumLicence = ss.val().dat_lic.substring(0, 10);
                  let disciplinski = ss.val().disciplinski;
                  let id = ss.val().id;
                  let ime = ss.val().ime;
                  let izvrsni = ss.val().izvrsni;
                  let jmbg = ss.val().jmbg;
                  let komisija1 = ss.val().komisija1;
                  let komisija2 = ss.val().komisija2;
                  let mail = ss.val().mail;
                  let mejestoID = ss.val().mes_id;
                  let regijaID = ss.val().regijaId;
                  let nadzorni = ss.val().nadzorni;
                  let ordinacijaId = ss.val().ord_id;
                  let pol = ss.val().pol;
                  let prezime = ss.val().prez;
                  let skupstina = ss.val().skupstina;
                  let specijalizacijaId = ss.val().spec_id;
                  let specijalizacijaId2 = ss.val().spec_id2;
                  let status = ss.val().status;
                  let statusZaposlenje = ss.val().statusZaposlenje;
                  let sud = ss.val().sud;
                  let telefon = ss.val().tel;
                  let titula = ss.val().tit;
                  let uplate = ss.val().uplate;
                  let uplateNove = ss.val().uplateNove;
                  let androidStatus = ss.val().androidStatus;
                  let androidDozvola = ss.val().androidDozvola;
                  let slika = ss.val().slika;
      
                  let lozinka = "";
                  this.state.lozinkeO.map((sss) => {
                    if (sss.uid == uid) {
                      lozinka = sss.lozinka;
                    }
                  });
      
                  let disciplinskiS = "";
                  this.state.disciplinskiTuzilacO.map((sss) => {
                    if (sss.broj == disciplinski) {
                      disciplinskiS = sss.naziv;
                    }
                  });
                  
                  let izvrsniS = "";
                  this.state.izvrsniOdborO.map((sss) => {
                    if (sss.broj == izvrsni) {
                      izvrsniS = sss.naziv;
                    }
                  });
                  let komisija1S = "";
                  this.state.komisijeO.map((sss) => {
                    if (sss.broj == komisija1) {
                      komisija1S = sss.naziv;
                    }
                  });
      
                  let komisija2S = "";
                  this.state.komisijeO.map((sss) => {
                    if (sss.broj == komisija2) {
                      komisija2S = sss.naziv;
                    }
                  });
                  let mejestoIDS = "";
                  let postanskiBroj = "";
                  this.state.mjestaO.map((sss) => {
                  if (sss.id == mejestoID) {
                    mejestoIDS = sss.mjesto;
                    postanskiBroj = sss.ptt;
                    }
                  });
                  let regijaIDS = "";
                  this.state.regijeO.map((sss) => {
                    if (sss.broj == regijaID) {
                      regijaIDS = sss.naziv;
                    }
                  });
                  let nadzorniS = "";
                  this.state.nadzorniOdborO.map((sss) => {
                    if (sss.broj == nadzorni) {
                      nadzorniS = sss.naziv;
                    }
                  });
                  let ordinacijaIdS = "";
                  let ordinacijaAdresa = "";
                  let ordinacijaMjestoId = "";
                  let ordinacijaMjestoIdS = "";
                  this.state.ordinacijeO.map((sss) => {
                    if (sss.id == ordinacijaId) {
                      ordinacijaIdS = sss.naziv;
                      ordinacijaAdresa = sss.adresa;
                      ordinacijaMjestoId = sss.mjestoId
                    }
                  });

                  this.state.mjestaO.map((fff) => {
                    if (fff.id == ordinacijaMjestoId) {
                      ordinacijaMjestoIdS = fff.mjesto;
                      
                    }
                  });


                  let skupstinaS = "";
                  this.state.skupstinaO.map((sss) => {
                    if (sss.broj == skupstina) {
                      skupstinaS = sss.naziv;
                    }
                  });
                  let specijalizacijaIdS = "";
                  this.state.specijalizacijeO.map((sss) => {
                    if (sss.id == specijalizacijaId) {
                      specijalizacijaIdS = sss.spec;
                    }
                  });
                  let specijalizacijaIdS2 = "";
                  this.state.specijalizacijeO.map((sss) => {
                    if (sss.id == specijalizacijaId2) {
                      specijalizacijaIdS2 = sss.spec;
                    }
                  });
                  let statusS = "";
                  this.state.statusiO.map((sss) => {
                    if (sss.broj == status) {
                      statusS = sss.naziv;
                    }
                  });
                  let statusZaposlenjeS = "";
                  this.state.statusiZaposlenjeO.map((sss) => {
                    if (sss.broj == statusZaposlenje) {
                      statusZaposlenjeS = sss.naziv;
                    }
                  });
                  let sudS = "";
                  this.state.sudCastiO.map((sss) => {
                    if (sss.broj == sud) {
                      sudS = sss.naziv;
                    }
                  });
                  let titulaS = "";
                  this.state.tituleO.map((sss) => {
                    if (sss.broj == titula) {
                      titulaS = sss.naziv;
                    }
                  });
      
                 //alert(uplate[0].id);
      
                 bb.push({
                  lozinka: lozinka,
                  adresa: adresa,
                  bodovi: bodoviX,
                  broj: broj,
                  datumLicence: datumLicence,
                  disciplinski: disciplinski,
                  id: id,
                  ime: ime,
                  izvrsni: izvrsni,
                  jmbg: jmbg,
                  komisija1: komisija1,
                  komisija2: komisija2,
                  mail: mail,
                  mejestoID: mejestoID,
                  regijaID: regijaID,
                  nadzorni: nadzorni,
                  ordinacijaId: ordinacijaId,
                  pol: pol,
                  prezime: prezime,
                  skupstina: skupstina,
                  specijalizacijaId: specijalizacijaId, 
                  specijalizacijaId2: specijalizacijaId2,            
                  status: status,
                  statusZaposlenje: statusZaposlenje,
                  sud: sud,
                  telefon: telefon,
                  titula: titula,
                  uid: uid,
                  uplate: uplateX,
                  uplateNove: uplateNove,
                  androidStatus: androidStatus,
                  androidDozvola: androidDozvola,
                  slika: slika,
                  slikaLicence: slikaLicence,
                  disciplinskiS: disciplinskiS,
                  izvrsniS: izvrsniS,
                  komisija1S: komisija1S,
                  komisija2S: komisija2S,
                  mejestoIDS: mejestoIDS,
                  postanskiBroj: postanskiBroj,
                  regijaIDS: regijaIDS,
                  nadzorniS, nadzorniS,
                  ordinacijaIdS: ordinacijaIdS,
                  ordinacijaAdresa: ordinacijaAdresa,
                  ordinacijaMjestoId: ordinacijaMjestoId,
                  ordinacijaMjestoIdS: ordinacijaMjestoIdS,
                  skupstinaS: skupstinaS,
                  specijalizacijaIdS: specijalizacijaIdS,
                  specijalizacijaIdS2: specijalizacijaIdS2,
                  statusS: statusS,
                  statusZaposlenjeS: statusZaposlenjeS,
                  sudS: sudS,
                  titulaS: titulaS,
                });
                });

                bb= bb.sort((a,b) => parseInt(a.broj) > parseInt(b.broj) ? 1 : parseInt(b.broj) > parseInt(a.broj) ? -1 : 0);
                this.setState({ baza: bb });
                this.setState({ bazaP: bb });


                //this.state.bazaP.forEach((novi) => {
                  bb.forEach((novi) => {
                  if (novi.uid == clan.uid) {
                    clan = novi;
                    alert("Podaci o članu su izmijenjeni");
                    this.setState({ clan: clan });            
                    this.akcija(clan);

                    this.setState({
                      brojClanova: (
                        <div >
                          <label >{bb.length}</label>
                          </div>
          
                          )});

                document.getElementById("filterST").value=1;
                document.getElementById("filterST").dispatchEvent(new Event("change"));



                  }
                });


              });
            });
          }
        } else {
          alert("Neispravan ili dupli jmbg");
        }
      };
     // */

      datumLicence = (date) => {
        let ddd =
          date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        this.setState({ datumLicence: ddd });
        
      };

     

      datumUplate = (date) => {

        var mm=(date.getMonth() + 1).toString();
        var dd=date.getDate().toString();
        if(mm.length==1){mm="0"+mm}
        if(dd.length==1){dd="0"+dd}
       
          let ddd =
            date.getFullYear() + "-" + mm + "-" + dd;
          this.setState({ datumUplate: ddd });
       
        };
  


      izmjeniSliku = (uri) => {
        let uid = this.state.clan.uid.toString();
        let slika=this.state.clan.slika;
        let clan = this.state.clan;  

        if (clan.slika == null || clan.slika == "" || clan.slika == undefined || clan.slika == "1") {}
        else{
          var ref1 = firebase.storage().refFromURL(slika);
          ref1
            .delete()
            .then(function () {
              //alert("Dokument " + naziv + " obrisan!");
            })
            .catch(function (error) {
              alert("Greska pri brisanju slike");
            });
        }
        var storageRef = firebase.storage().ref();
        var ref = storageRef.child("SlikeClanova");
    
 
          var d = new Date();
          var n = d.getTime();
    
          var array, binary, i;
          binary = atob(uri.split(",")[1]);
          array = [];
          i = 0;
          while (i < binary.length) {
            array.push(binary.charCodeAt(i));
            i++;
          }
          let file = new Blob([new Uint8Array(array)], {
            type: "image/jpeg",
          });
          
          const uploadTask = ref.child(uid).child(n.toString()).put(file);
    
          uploadTask.on(
            "state_changed",
            function (snapshot) {},
            function (error) {
         
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                upisiSliku(uid, downloadURL.toString()).then(() => {

                    clan.slika=downloadURL.toString();

                 let bazaI=this.state.baza
                 for (let i = 0; i < bazaI.length; i++) {
                  if(bazaI[i].uid==clan.uid){

                    bazaI[i].slika=downloadURL.toString();

                  }
                }
                this.setState({ clan: clan});
                this.setState({ baza: bazaI },()=>{ this.izmjena(clan);});
                 alert("slika je promjenjena");
                
              });
            }
            
          );
            

          });
        

          
      };

      izmjeniSlikuLicence = (uri) => {
        let uid = this.state.clan.uid.toString();
        let slikaLicence=this.state.clan.slikaLicence;
        let clan = this.state.clan;  

        if (clan.slikaLicence == null || clan.slikaLicence == "" || clan.slikaLicence == undefined || clan.slikaLicence == "1" || clan.slikaLicence == "undefined") {}
        else{
          var ref1 = firebase.storage().refFromURL(slikaLicence);
          ref1
            .delete()
            .then(function () {
              //alert("Dokument " + naziv + " obrisan!");
            })
            .catch(function (error) {
              alert("Greska pri brisanju slike");
            });
        }
        var storageRef = firebase.storage().ref();
        var ref = storageRef.child("SlikeLicenci");
    
 
          var d = new Date();
          var n = d.getTime();
    
          var array, binary, i;
          binary = atob(uri.split(",")[1]);
          array = [];
          i = 0;
          while (i < binary.length) {
            array.push(binary.charCodeAt(i));
            i++;
          }
          let file = new Blob([new Uint8Array(array)], {
            type: "image/jpeg",
          });
          
          const uploadTask = ref.child(uid).child(n.toString()).put(file);
    
          uploadTask.on(
            "state_changed",
            function (snapshot) {},
            function (error) {
         
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                upisiSlikuL(uid, downloadURL.toString()).then(() => {

                    clan.slikaLicence=downloadURL.toString();

                 let bazaI=this.state.baza
                 for (let i = 0; i < bazaI.length; i++) {
                  if(bazaI[i].uid==clan.uid){

                    bazaI[i].slikaLicence=downloadURL.toString();

                  }
                }
                this.setState({ clan: clan});
                this.setState({ baza: bazaI },()=>{ this.akcija(clan);});
                 alert("Slika licence je promjenjena");
                
              });
            }
            
          );
            

          });
        

          
      };

      izbrisiSliku = (slika) => {
        let uid = this.state.clan.uid.toString();
        let clan = this.state.clan;         
        let bazaII=this.state.baza;
        if(slika=="" || slika==null || slika==undefined || slika=="1"){}
        else{
          if (window.confirm("Izbriši sliku člana?")) {
            var ref = firebase.storage().refFromURL(slika);
          ref
            .delete()
            .then(() =>{

              upisiSliku(uid, "").then(() => {

                  clan.slika="";
                 for (let i = 0; i < bazaII.length; i++) {
                  if(bazaII[i].uid==clan.uid){
  
                    bazaII[i].slika="1";         
  
                  }
                }
                
                this.setState({ clan: clan});
                this.setState({ baza: bazaII },()=>{ this.izmjena(clan);});
                 alert("slika je izbrisana");
                
                 //let v = document.getElementById("slikaProfil");
                // v.src = stomatologLogo;
  
              });


            })
            .catch(function (error) {
              alert("Greska pri brisanju slike");
            });

          }
        }

      }

      izbrisiSlikuLicence = (slikaLicence) => {
        let uid = this.state.clan.uid.toString();
        let clan = this.state.clan;         
        let bazaII=this.state.baza;
        if(slikaLicence=="" || slikaLicence==null || slikaLicence==undefined || slikaLicence=="1"){}
        else{
          if (window.confirm("Izbriši sliku licence?")) {
            var ref = firebase.storage().refFromURL(slikaLicence);
          ref
            .delete()
            .then(() =>{

              upisiSlikuL(uid, "").then(() => {

                  clan.slikaLicence="";
                 for (let i = 0; i < bazaII.length; i++) {
                  if(bazaII[i].uid==clan.uid){
  
                    bazaII[i].slikaLicence="1";         
  
                  }
                }
                
                this.setState({ clan: clan});
                this.setState({ baza: bazaII },()=>{ this.akcija(clan);});
                 alert("Slika licence je izbrisana");
                
                 //let v = document.getElementById("slikaProfil");
                // v.src = stomatologLogo;
  
              });


            })
            .catch(function (error) {
              alert("Greska pri brisanju slike licence!");
            });

          }
        }

      }




      sleep = (milliseconds) => {
                return new Promise(resolve => setTimeout(resolve, milliseconds))
            }





      fileChangedHandler = (event) => {
        var fileInput = false;
        if (event.target.files[0]) {
          fileInput = true;
        }
        if (fileInput) {
          Resizer.imageFileResizer(
            event.target.files[0],
            800,
            1200,
            "JPEG",
            90,
            0,
            (uri) => {
              this.setState({ uriSlike: uri });
              let v = document.getElementById("slikaProfil");
              let staro=v.src;
              v.src = uri;

               this.sleep(500).then(r => {
                       if (window.confirm("Promijeni sliku člana?")) {
                        this.izmjeniSliku(uri);
                       }else{
                        v.src = staro;
                          }
                    })
             
            },
            "base64"
          );
        }
      };

      fileChangedHandlerLic = (event) => {
        var fileInput = false;
        if (event.target.files[0]) {
          fileInput = true;
        }
        if (fileInput) {
          Resizer.imageFileResizer(
            event.target.files[0],
            960,
            1440,
            "JPEG",
            90,
            0,
            (uri) => {
              
              let v = document.getElementById("slikaLicence");
              let staro=v.src;
              v.src = uri;

               this.sleep(500).then(r => {
                       if (window.confirm("Dodaj/Promijeni sliku licence?")) {
                        this.izmjeniSlikuLicence(uri);
                       }else{
                        v.src = staro;
                          }
                    })
             
            },
            "base64"
          );
        }
      };


      uplateForma = (clan) => {
        this.setState({ kraj: "" });
        this.setState({ pocetak: "" });
        //this.setState({ datumUplate: "" });

        let uplate = clan.uplate;
        if(uplate=="" || uplate==null || uplate==undefined)
        { 
          uplate=[];
        }else{
          uplate=clan.uplate.slice().reverse();
        }
      
        
        var uplateNoveX=[];
        var uplateNove=[];
        try{
        this.state.uplateN.forEach((dok) => {
        if(dok.uid==clan.uid){
          uplateNoveX.push(dok)
         }
      
        });
        uplateNove=uplateNoveX.slice().reverse();

      } catch{

      }


       if(uplateNove.length>0){

        uplateNove.every(uplaaa => {
          if(uplaaa.vrstaUplate=="Članarina" || uplaaa.vrstaUplate=="Članarina2"){
            let ddnn=uplaaa.datumDO;
            this.setState({ pocetak: ddnn });
            return false;
          }
          return true;

        })

        
     
 
        }

        this.setState({
          uplateSifre: (
            <div className=" mt-3 ms-2 px-2 pb-2 pt-1" style={this.styles4}>
              <Form onSubmit={this.handleUplate} className="text-center mt-1">
                <Form.Control
                  id="selektovanaUplataI"
                  as="select"
                  multiple
                  style={{ height: "200px" }}
                  
                >

             { uplateNove.map((d) => {
               if(d.vrstaUplate=="Članarina" || d.vrstaUplate=="Članarina2"){

           
               var datU =d.datumUplate.substring(0, 10);
               var d1=d.datumOD.substring(0, 10);
               var d2=d.datumDO.substring(0, 10);
               datU=datU.split("-")[2]+"."+datU.split("-")[1]+"."+datU.split("-")[0]

                d1=d1.split("-")[1] +"."+ d1.split("-")[0];
                d2=d2.split("-")[1] +"."+ d2.split("-")[0];
               
                      return (
                        <option
                          className="text-dark"
                          value={
                            "nove"
                            +";" +
                            d.key
                            +";" +
                            d.vrstaUplate
                          }
                                               
                        >

                          {datU}  {" | "+d1+"-"+d2+" | "}
                          {d.iznosUplate},00KM {" | " +  d.vrstaUplate}
                        </option>
                      );
                

                    }
                    else{


                      var datU =d.datumUplate.substring(0, 10);
                    
                      datU=datU.split("-")[2]+"."+datU.split("-")[1]+"."+datU.split("-")[0]
       
                     
                      
                             return (
                               <option
                                 className="text-primary"
                                 value={
                                  "nove"
                                  +";" +
                                  d.key
                                  +";" +
                                  d.vrstaUplate
                                 }
                                                      
                               >
       
                                 {datU}  {" | "}
                                 {d.iznosUplate},00KM {" | " +  d.vrstaUplate}
                               </option>
                             );
                       

     
                    }
                  })}

           {uplate.map((d) => {
               var datU =d.datumUplate.substring(0, 10);
            
               datU=datU.split("-")[2]+"."+datU.split("-")[1]+"."+datU.split("-")[0]
                      return (
                        <option
                          className="text-secondary"
                          value={
                            "stare"
                            +";" +
                            d.id
                           
                          }
                                              
                        >
                          {datU}  {" | "+d.period+" | "}
                          {d.iznosUplate},00KM
                        </option>
                      );
                
                  })}
                </Form.Control>
                <Form.Label className="mt-4">Vrsta uplate</Form.Label>
                <Form.Control id="vrstaUplate" as="select">
                  {this.state.uplateO.map((ss) => {
                  return (
                  <option value={ss.sifra + ";" + ss.cijena}>
                    {ss.sifra} {ss.cijena},00KM
                  </option>
                  );
                  })}
                </Form.Control>

                
                <Form.Label className="mt-2">{this.state.xzy}</Form.Label>   
              

                <div style={{ clear: "both" }}>
             <Form.Label className="mt-2 me-3">Zadnji plaćeni mjesec</Form.Label>   
             <Form.Label className="mt-2 ms-3">Period nove uplate</Form.Label>  

                </div>
              
              <div style={{ clear: "both" }}>
              
              
              <Form.Control defaultValue="" id="mjesecStart" as="select" style={this.styles28}  className="ms-3 mt-1">
              <option value=""></option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </Form.Control>

             <Form.Control defaultValue="" id="godinaStart" as="select" style={this.styles28} className="ms-1 mt-1">
             <option value=""></option>
             <option value="2010">2010</option>
              <option value="2011">2011</option>
              <option value="2012">2012</option>
              <option value="2013">2013</option>
              <option value="2014">2014</option>
              <option value="2015">2015</option>
              <option value="2016">2016</option>
              <option value="2017">2017</option>
              <option value="2018">2018</option>
              <option value="2019">2019</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2028">2028</option>
              <option value="2029">2029</option>
              <option value="2030">2030</option>
              <option value="2031">2031</option>
              <option value="2032">2032</option>
              <option value="2033">2033</option>
              <option value="2034">2034</option>
              <option value="2035">2035</option>
              <option value="2036">2036</option>
              <option value="2037">2037</option>
              <option value="2038">2038</option>
              <option value="2039">2039</option>
              <option value="2040">2040</option>
          
            </Form.Control>
                
               
              

              <Form.Control id="godineI" as="select" style={this.styles28}  className="ms-3 mt-1">
              <option value="0">0 godina</option>
              <option value="1">1 godina</option>
              <option value="2">2 godine</option>
              <option value="3">3 godine</option>
              <option value="4">4 godine</option>
              <option value="5">5 godina</option>
            </Form.Control>

             <Form.Control id="mjeseciI" as="select" style={this.styles28} className="ms-1 mt-1">
            <option value="0">0 mjeseci</option>
              <option value="1">1 mjesec</option>
              <option value="2">2 mjeseca</option>
              <option value="3">3 mjeseca</option>
              <option value="4">4 mjeseca</option>
              <option value="5">5 mjeseci</option>
              <option value="6">6 mjeseci</option>
              <option value="7">7 mjeseci</option>
              <option value="8">8 mjeseci</option>
              <option value="9">9 mjeseci</option>
              <option value="10">10 mjeseci</option>
              <option value="11">11 mjeseci</option>
          
            </Form.Control>
          

            </div>

 
          <div style={{ clear: "both" }}>
              <Form.Label className="mt-2">Datum uplate</Form.Label>              
               <DatePickerInput
               
                 id="datumUplate"
                 defaultValue={this.state.day}
                 //locale="es"
                 onChange={this.datumUplate}   
                 
               />
               

              </div>
                
            
                <Button
                  id="subUplata"
                  className="btn btn-outline-success centerButton"
                  type="submit"
                  style={{ width: "100px" }}
                  className="mt-4"
                  size="sm"
                  variant="danger"
                >
                  Upiši uplatu
                </Button>
      
                <Button
                  id="brisiUplatu"
                  className="btn btn-outline-danger  centerButton"
                  onClick={this.brisiUplatu}
                  style={{ width: "100px" }}
                  className="mt-4 ms-3"
                  variant="outline-dark"
                  size="sm"
                >
                  Briši uplatu
                </Button>
             
              </Form>
            </div>
          ),
        },() => {

        let godine = document.getElementById("godineI");
        let mjesecI = document.getElementById("mjeseciI");
        let mjesecStart = document.getElementById("mjesecStart");
        let godinaStart = document.getElementById("godinaStart");
       
        mjesecStart.disabled=false;
        godinaStart.disabled=false;
        mjesecStart.value="";
        godinaStart.value="";

        if(this.state.pocetak==""){
          mjesecStart.disabled=false;
          godinaStart.disabled=false;

        }else{
          godinaStart.value=this.state.pocetak.trim().split("-")[0]
          mjesecStart.value=this.state.pocetak.trim().split("-")[1]
          mjesecStart.disabled=true;
          godinaStart.disabled=true;  

        }
        

        let vrstaU = document.getElementById("vrstaUplate");
        vrstaU.addEventListener("change", (ff) => {
        let vrstaS=vrstaU.value.split(";")[0];
      
        if(vrstaS=="Članarina" || vrstaS=="Članarina2"){
          godine.disabled = false;
          mjesecI.disabled = false;  


          if(this.state.pocetak==""){
            mjesecStart.disabled=false;
            godinaStart.disabled=false;
  
          }else{
            godinaStart.value=this.state.pocetak.trim().split("-")[0]
            mjesecStart.value=this.state.pocetak.trim().split("-")[1]
            mjesecStart.disabled=true;
            godinaStart.disabled=true;  
  
          }
          
       
          //alert("upaljeno");
        }
        else{
         
          godine.disabled = true;
          mjesecI.disabled = true;
          mjesecStart.disabled=true;
          godinaStart.disabled=true;  

        //alert("ugaseno");

        }
          
         });


        });

      

      };

      handleUplate = (ev) => {
        ev.preventDefault();
        let v = document.getElementById("vrstaUplate");
        let vrstaUplateIznosUplate = v.options[v.selectedIndex].value.split(";");
        let vrstaUplate = vrstaUplateIznosUplate[0];
        if(vrstaUplate=="Članarina" || vrstaUplate=="Članarina2"){
         this.handleUplateC(ev);
        }else{
          this.handleUplateO(ev);
        }
      }



      handleUplateO = (ev) => {
        if (window.confirm("Upiši uplatu?")) {
        ev.preventDefault();
        
        let dugme = document.getElementById("subUplata");
        dugme.disabled = true;
        let v = document.getElementById("vrstaUplate");
        let vrstaUplateIznosUplate = v.options[v.selectedIndex].value.split(";");
        let vrstaUplate = vrstaUplateIznosUplate[0];
        let iznosUplate = vrstaUplateIznosUplate[1];
        upisiUplatu(
          this.state.clan.uid,
          vrstaUplate,
          this.state.datumUplate,
          iznosUplate,
          "1900-1-1",
          "1900-1-1",
  
        ).then(() => {
          dugme.disabled = false;
          alert("Uplata je upisana ")
          uplateN().then((snapshot) => {
            let ff = [];
             snapshot.forEach((clan) => {
               var uid = clan.key;
               clan.forEach((uplata) => {
               
               let key =uplata.key;
               let vrstaUplate = uplata.val().vrstaUplate;
               let iznosUplate = uplata.val().iznosUplate;
               let datumUplate = uplata.val().datumUplate;
               let datumOD = uplata.val().datumOD;
               let datumDO = uplata.val().datumDO;
       
               ff.push({
                 uid: uid,
                 key: key,
                 vrstaUplate: vrstaUplate,
                 iznosUplate: iznosUplate,
                 datumUplate: datumUplate,
                 datumOD: datumOD,
                 datumDO: datumDO,
               
               });
   
             
   
             });
       
             
           })
          this.setState({ uplateN: ff });

         }).then((snapshot) => {

         this.akcija(this.state.clan);

         });
       

        });
      } else{
        ev.preventDefault();
      }
      }

      handleUplateC = (ev) => {

        let datumOD=this.state.pocetak;
       
        let mjesecStart = document.getElementById("mjesecStart").options[
          document.getElementById("mjesecStart").selectedIndex
        ].value;
        let godinaStart = document.getElementById("godinaStart").options[
          document.getElementById("godinaStart").selectedIndex
        ].value;

        let v = document.getElementById("vrstaUplate");
          let vrstaUplateIznosUplate = v.options[v.selectedIndex].value.split(";");
          let vrstaUplate = vrstaUplateIznosUplate[0];
          let iznosUplate = vrstaUplateIznosUplate[1];

       if(datumOD=="" && (mjesecStart=="" || godinaStart=="")){
        ev.preventDefault();
        alert("Unesite zadnji uplaćen mjesec!")
       }
       else{
        datumOD=godinaStart+"-"+mjesecStart+"-1"

        let godine1 = document.getElementById("godineI").options[
          document.getElementById("godineI").selectedIndex
        ].value;

        let mjeseci1 = document.getElementById("mjeseciI").options[
          document.getElementById("mjeseciI").selectedIndex
        ].value;
        let mjeseci=parseInt(mjeseci1);
        let godine=parseInt(godine1);

        if(datumOD=="" || (mjeseci==0 && godine==0))
        {  ev.preventDefault();
          alert("Unesite period nove uplate!")
        }
        else{
        if (window.confirm("Upiši uplatu?")) {
          ev.preventDefault();
          let dugme = document.getElementById("subUplata");
          dugme.disabled = true;
    
         

        if(godine==0){

            iznosUplate=parseInt(iznosUplate)*mjeseci;

        }else{

            iznosUplate=parseInt(iznosUplate)*(mjeseci+(godine*12));

        }
         

          let date1=new Date();
          date1.setFullYear(datumOD.trim().split("-")[0]);
          date1.setDate(1);
          date1.setMonth(datumOD.trim().split("-")[1] - 1);
          
         
          let date2=new Date();
          date2.setFullYear(date1.getFullYear()+godine);
          date2.setDate(1);
          date2.setMonth(((date1.getMonth())+mjeseci));
           

          let date3=new Date();
          date3.setFullYear(date1.getFullYear());
          date3.setDate(1);
          date3.setMonth(date1.getMonth()+1);
        
         

         datumOD= date3.getFullYear() + "-" + (date3.getMonth() + 1) + "-" + date3.getDate();
       
         let datumDO=  date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();

   
   


            upisiUplatu(
              this.state.clan.uid,
              vrstaUplate,
              this.state.datumUplate,
              iznosUplate,
              datumOD,
              datumDO
            ).then(() => {
              dugme.disabled = false;
             
              document.getElementById("mjeseciI").selectedIndex=0;
              document.getElementById("godineI").selectedIndex=0;
             
              uplateN().then((snapshot) => {
                let ff = [];
                 snapshot.forEach((clan) => {
                   var uid = clan.key;
                   clan.forEach((uplata) => {
                   
                   let key =uplata.key;
                   let vrstaUplate = uplata.val().vrstaUplate;
                   let iznosUplate = uplata.val().iznosUplate;
                   let datumUplate = uplata.val().datumUplate;
                   let datumOD = uplata.val().datumOD;
                   let datumDO = uplata.val().datumDO;
           
                   ff.push({
                     uid: uid,
                     key: key,
                     vrstaUplate: vrstaUplate,
                     iznosUplate: iznosUplate,
                     datumUplate: datumUplate,
                     datumOD: datumOD,
                     datumDO: datumDO,
                   
                   });
       
                 
       
                 });
           
                 
               })

                this.setState({ uplateN: ff });

             }).then((snapshot) => {

             this.akcija(this.state.clan);
             this.sleep(500).then(r => {alert("Uplata je upisana!")});
             });
           

            });
                
        } else {
          ev.preventDefault();
        }
      }
    }

      };

      brisiUplatu = () =>{

        if (window.confirm("Obriši selektovanu uplatu?")) {
          let dugme = document.getElementById("brisiUplatu");
          dugme.disabled = true;
          try{
          let v = document.getElementById("selektovanaUplataI");
          let selektovanaUplata = v.options[v.selectedIndex].value.split(";");
          let vrstaUplate = selektovanaUplata[0];
          let kljucUplate = selektovanaUplata[1];
          let uplateQ = selektovanaUplata[2];
        
          if(vrstaUplate=="nove"){

            if(uplateQ=="Članarina" || uplateQ=="Članarina2"){

            if (window.confirm("Možete brisati samo zadnju uplatu,nastavi?")) {

             let kljucUplate1 = "";
             let uplaxxx=[]
             let upla= this.state.uplateN;
             upla.forEach((uuu) => {
               if(uuu.uid==this.state.clan.uid && (uuu.vrstaUplate=="Članarina" || uuu.vrstaUplate=="Članarina2") ){
                  uplaxxx.push(uuu);
               }
             })

             if(uplaxxx.length>0){
               uplaxxx.reverse();
              kljucUplate1=uplaxxx[0].key;
             }
               
                

 
          obrisiUplatu(this.state.clan.uid, kljucUplate1).then(() => {
          
          dugme.disabled = false;
          uplateN().then((snapshot) => {
            let ff = [];
             snapshot.forEach((clan) => {
               var uid = clan.key;
               clan.forEach((uplata) => {
               
               let key =uplata.key;
               let vrstaUplate = uplata.val().vrstaUplate;
               let iznosUplate = uplata.val().iznosUplate;
               let datumUplate = uplata.val().datumUplate;
               let datumOD = uplata.val().datumOD;
               let datumDO = uplata.val().datumDO;
       
               ff.push({
                 uid: uid,
                 key: key,
                 vrstaUplate: vrstaUplate,
                 iznosUplate: iznosUplate,
                 datumUplate: datumUplate,
                 datumOD: datumOD,
                 datumDO: datumDO,
               
               });
   
             });
       
            
           })
            this.setState({ uplateN: ff });

         }).then((snapshot) => {
           
         this.uplateForma(this.state.clan);
         this.sleep(500).then(r => {alert("Uplata je obrisana!")});
         

         });


          });

        }else{dugme.disabled = false;}

      }else{
        obrisiUplatu(this.state.clan.uid, kljucUplate).then(() => {
       
          dugme.disabled = false;
          uplateN().then((snapshot) => {
            let ff = [];
             snapshot.forEach((clan) => {
               var uid = clan.key;
               clan.forEach((uplata) => {
               
               let key =uplata.key;
               let vrstaUplate = uplata.val().vrstaUplate;
               let iznosUplate = uplata.val().iznosUplate;
               let datumUplate = uplata.val().datumUplate;
               let datumOD = uplata.val().datumOD;
               let datumDO = uplata.val().datumDO;
       
               ff.push({
                 uid: uid,
                 key: key,
                 vrstaUplate: vrstaUplate,
                 iznosUplate: iznosUplate,
                 datumUplate: datumUplate,
                 datumOD: datumOD,
                 datumDO: datumDO,
               
               });
   
             });
       
            
           })
            this.setState({ uplateN: ff });

         }).then((snapshot) => {
           
         this.uplateForma(this.state.clan);
         this.sleep(500).then(r => {alert("Uplata je obrisana!")});

         });


          });


      }
          } else {

          if (window.confirm("Jeste li sigurni da želite obrisati staru uplatu?")) {
          obrisiUplatuS(this.state.clan.uid, kljucUplate).then(() => {             
          let clan = this.state.clan;

          let bazaI=this.state.baza
          for (let i = 0; i < bazaI.length; i++) {
           if(bazaI[i].uid==clan.uid){
              let filter=[];
              bazaI[i].uplate.map((upl) => {
                
               if(upl.id==kljucUplate){
             
               }else{
                 filter.push(upl);
               }

              });
              clan.uplate=filter;
              bazaI[i].uplate=filter;

         }

        }

        this.setState({ clan: clan});
         this.setState({ baza: bazaI },()=>{ this.akcija(clan);});
         this.sleep(500).then(r => {alert("Uplata je obrisana!")});
              
              dugme.disabled = false;
            })
              
          
          }
        }
        } catch (err) {
          dugme.disabled = false;
          alert("Niste selektovali ni jednu uplatu!");
        }
        
        }
      }


      kongresiForma = (clan) => {
        this.setState({ uriDokumenta: ""});
        let bodovi = clan.bodovi;
      
        let bodoviNovi = this.state.bodoviNoviO;
        if(bodovi=="" || bodovi==null || bodovi == undefined)
        { 
          bodovi=[];
        }else{
          //bodovi=clan.bodovi.slice().reverse();
        }
        let bodoviNoviX=[];
        if(bodoviNovi=="" || bodoviNovi==null || bodoviNovi==undefined)
        { 
          bodoviNoviX=[];
        }else{

        let dodat = [];
         bodoviNovi.map((d) => {

        var rand1=Math.floor(Math.random() * 58)+ 1;
        var rand2=Math.floor(Math.random() * 990)+ 1;
        let datumS = new Date();
        
        datumS.setFullYear(d.datum.trim().split("-")[0]);
        datumS.setDate(d.datum.trim().split("-")[2]);
        datumS.setMonth(d.datum.trim().split("-")[1] - 1);
        datumS.setHours(0, 0, rand1, rand2);
      
        var timestamp=datumS.getTime();
        d["timestamp"] = timestamp;
      

         dodat.push(d);
       
        })

        bodoviNoviX=dodat.sort((a,b) => a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0);
        bodoviNoviX=bodoviNoviX.slice().reverse();
        //alert(dodat[0].timestamp+dodat[0].naziv);

         //bodoviNoviX=bodoviNovi.slice().reverse();
        }

       
    
       



        let kongresiSifreL=this.state.kongresiSifreO;
        let kongresiSifreX=[];
        if(kongresiSifreL=="" || kongresiSifreL==null || kongresiSifreL==undefined)
        { 
            kongresiSifreX=[];
        }else{
            kongresiSifreX=kongresiSifreL.slice().reverse();
         
        }



      let bodoviU=0;
      let bodoviP=0;
      let bodoviN=0;

      let licDate = new Date();
      licDate.setFullYear((clan.datumLicence.trim().split("-")[0]-5));
      licDate.setDate(clan.datumLicence.trim().split("-")[2]);
      licDate.setMonth(clan.datumLicence.trim().split("-")[1] - 1); 

        licDate.setHours(0, 0, 1, 1);

        let licDate2 = new Date();
        licDate2.setFullYear(clan.datumLicence.trim().split("-")[0]);
        licDate2.setDate(clan.datumLicence.trim().split("-")[2]);
        licDate2.setMonth(clan.datumLicence.trim().split("-")[1] - 1);
      
  
          licDate2.setHours(0, 0, 1, 1);

        this.setState({
          kongresiEkran: (
            <div className=" mt-3 ms-2 ps-2 pe-2 pb-2 pt-1" style={this.styles6}>     
          
              <Form  className="text-center mt-1">
                <Row>
                  
                    <Form.Control
                      id="selektovanDogadjajI"
                      as="select"

                      multiple
                      style={{ height: "180px", width: "670px",  overflow: "scroll", }}
                      className="ms-2"
                    >                 
                      {bodoviNoviX.map((d) => {
                          let uDatumu=false;
                          let eduDate = new Date();
                              eduDate.setFullYear(d.datum.trim().split("-")[0]);
                              eduDate.setDate(d.datum.trim().split("-")[2]);
                              eduDate.setMonth(d.datum.trim().split("-")[1] - 1);
                             
                                eduDate.setHours(0, 0, 1, 1);

                                if (licDate <= eduDate && eduDate <= licDate2) {
                                  uDatumu=true;
                                }
                         
                        if(d.uid==clan.uid){
                          if(uDatumu){ bodoviU=bodoviU+parseInt(d.bodovi);}
                         
                        if (d.potvrda == "da") {
                          if(uDatumu){bodoviP=bodoviP+parseInt(d.bodovi);}
                          
                        var dat=d.datum.split("-")[2]+"."+d.datum.split("-")[1]+"."+d.datum.split("-")[0]                    
                        return (
                          <option
                            className="text-dark"
                            onClick={() => this.slikeUbaci(d.slika)}
                            value={
                              "nove;"+d.key+";"+d.slika
                            }
                          >
                            {dat+" | " +d.naziv+ " | " +d.bodovi}
                          </option>
                        );
                          }else{
                            if(uDatumu){bodoviN=bodoviN+parseInt(d.bodovi);}
                            
                            var dat=d.datum.split("-")[2]+"."+d.datum.split("-")[1]+"."+d.datum.split("-")[0]                    
                            return (
                              <option
                                className="text-danger"
                                onClick={() => this.slikeUbaci(d.slika)}
                                value={
                                  "nove;"+d.key+";"+d.slika
                                }
                              >
                                {dat+" | " +d.naziv+ " | " +d.bodovi}
                              </option>
                            );



                          }


                      }
                      }) }

                      {bodovi.map((d) => {
                      
                        var dat=d.dat.substring(0, 10);
                        var dat=dat.split("-")[2]+"."+dat.split("-")[1]+"."+dat.split("-")[0]
                        
                        return (
                          <option
                            className="text-secondary"
                            onClick={() => this.slikeUbaci(d.slika)}
                            value={
                              "stare;"+d.id+";"+d.slika
                            }
                          >
                            {dat+" | " +d.seminar+ " | " +d.cif}
                          </option>
                        );
                      })}

    
                    </Form.Control>
         
     
                  <Col sm="7">
                    <div className="mt-2">
                    <label>BODOVI</label><label className="ms-2">  ukupno:{bodoviU}</label>     <label className="ms-2">potrvđeni:{bodoviP}</label>   <label className=" ms-2">nepotrvđeni:{bodoviN}</label>
                    </div>
                    <Input id="kongresSifraI" as="select" style={{ width: "400px",height: "200px" , overflow: "scroll"}} className="mr-1 ps-1 mt-3 mb-3"  multiple defaultValue={"-"}>
                    <option value="-">-</option>
                  {kongresiSifreX.map((d) => {

                  var dat=d.datum.split("-")[2]+"."+d.datum.split("-")[1]+"."+d.datum.split("-")[0]
                      return (
                        
                        <option
                      
                          value={
                            d.datum+";" + d.naziv + ";" + d.bodovi+ ";" + d.grad+ ";" + d.drzava+ ";" + d.sd+ ";" + d.key
                          }
                        >
                          {dat+" | " +d.naziv+ " | " +d.bodovi}
                        </option>
                      );
                  })}
                </Input>

                    <div>

                    <Button
                      id="dodajDokument"
                      className="btn btn-danger"
                      onClick={() => this.dodajBodove()}
                      size="sm"
                   
                    >
                      Dodaj edukaciju
                    </Button>
                 

                  <Button
                  id="brisiBodove"
                  className="btn btn-outline-danger  centerButton ms-2"
                  onClick={() => this.brisiBodove()}
              
                  variant="outline-dark"
                  size="sm"
                  >
                  Briši edukaciju
                  </Button>

                  <Button
                  id="potvrdiI"
                  className="btn btn-outline-danger  centerButton ms-2"
                  onClick={() => this.potvrdiDogadjaj()}
              
                  variant="outline-danger"
                  size="sm"
                  >
                  Potvrdi edukaciju
                  </Button>
                  </div>


                  <div>
                  <label for="file-upload"  class="meme"style={{
                    border: "1px solid red",
                    width: "210px",
                    color: "#dc3545",
                    "font-weight": "bold",
                    "margin-top": "20px",
                  }}> 
                      Dodaj sliku edukacije
                    </label>
                    <input
                    
                      id="file-upload"
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={this.fileChangedHandlerD}
                    />

                    <Button
                  id="brisiSlikuEdukacijeI"
                  className="btn btn-outline-danger  centerButton ms-2"
                  onClick={() => this.brisiSlikuEdukacije()}
              
                  variant="outline-dark"
                  size="sm"
                  >
                  Briši sliku
                  </Button>
                    </div>

                  

                  </Col>

                  <Col sm="5">
                    
                    <img
                      id="sliketina"
                      style={this.slikaDok}
                      className=" ms-2 mt-1"
                      onClick={this.handleShowDialog}
                    />
                   
               
                  </Col>
                </Row>
    

              </Form>
            </div>
          ),
        });
        
        try{
          this.slikeUbaci(bodovi[0].slika)
        }catch(ero){
          this.slikeUbaci("");
          
        }
        try{
          bodoviNovi.map((d) => {                       
            if(d.uid==clan.uid){
              this.slikeUbaci(d.slika)
            }
          });
         
        }catch(ero){
          this.slikeUbaci("");
        }


        
      };

      potvrdiDogadjaj = () => {
        let uid=this.state.clan.uid;
       
        let v = document.getElementById("kongresSifraI");
        let selektovanDogadjaj = v.options[v.selectedIndex].value.split(";");
        let datum = selektovanDogadjaj[0];
        let naziv = selektovanDogadjaj[1];
        let bodovi = selektovanDogadjaj[2];
        let grad = selektovanDogadjaj[3];
        let drzava = selektovanDogadjaj[4];
        let sd = selektovanDogadjaj[5];
        let id = selektovanDogadjaj[6];

        let vrstaDogadjaja ="";
        let kljucDogadjaja ="";
        
        try{
        let f = document.getElementById("selektovanDogadjajI");
        let selektovanDogadjaj = f.options[f.selectedIndex].value.split(";");
        vrstaDogadjaja = selektovanDogadjaj[0];
        kljucDogadjaja = selektovanDogadjaj[1];
        
        }catch(ero){
          //alert("Selektujte događaj za potvrdu!")
        }

        if (window.confirm("Potvrdi edukaciju?")) {
        if(naziv=="-" || naziv==undefined || naziv==null || vrstaDogadjaja=="" || vrstaDogadjaja=="stare"|| kljucDogadjaja==""){
          alert("Selektujte edukaciju za potvrdu!")
        }else{
        potvrdiBodove(     
        uid,
        kljucDogadjaja,
        id,
        datum,
        naziv,
        bodovi,
        grad,
        drzava,
        sd,
        "da",
        ).then(() => {
        

        bodoviN().then((snapshot) => {
          let ff = [];
           snapshot.forEach((clan) => {
             var uid = clan.key;
             clan.forEach((bod) => {
             
              let key =bod.key;
             let id =bod.val().id;
             let datum = bod.val().datum;
             let naziv = bod.val().naziv;
             let bodovi = bod.val().bodovi;
             let grad = bod.val().grad;
             let drzava = bod.val().drzava;
             let sd = bod.val().sd;
             let slika = bod.val().slika;
             let potvrda= bod.val().potvrda;
           
             ff.push({
               uid: uid,
               key: key,
               id: id,
               datum: datum,
               naziv: naziv,
               bodovi: bodovi,
               grad: grad,
               drzava: drzava,
               sd: sd,
               slika: slika,
               potvrda: potvrda,
             
             });
           
           });
           v.selectedIndex=0;
           this.setState({ bodoviNoviO: ff });       
          
         })
 
 
          }).then((snapshot) => {

          this.akcija(this.state.clan);
          this.sleep(500).then(r => {alert("Edukacija je potvrđena!")});
 
           });
     

        });
      }
    }

      }

      dodajBodove = () => {
        let uid=this.state.clan.uid;
       
        let v = document.getElementById("kongresSifraI");
        let selektovanDogadjaj = v.options[v.selectedIndex].value.split(";");
        let datum = selektovanDogadjaj[0];
        let naziv = selektovanDogadjaj[1];
        let bodovi = selektovanDogadjaj[2];
        let grad = selektovanDogadjaj[3];
        let drzava = selektovanDogadjaj[4];
        let sd = selektovanDogadjaj[5];
        let id = selektovanDogadjaj[6];
        if (window.confirm("Dodajte novu edukaciju?")) {
        if(naziv=="-" || naziv==undefined || naziv==null){
          alert("Niste selektovali ni jedanu edukaciju!")
        }else{
        upisiBodove(     
        uid,
        id,
        datum,
        naziv,
        bodovi,
        grad,
        drzava,
        sd,
        "da",
        ).then(() => {
        //alert("Događaj je dodan");

        bodoviN().then((snapshot) => {
          let ff = [];
           snapshot.forEach((clan) => {
             var uid = clan.key;
             clan.forEach((bod) => {
             
              let key =bod.key;
             let id =bod.val().id;
             let datum = bod.val().datum;
             let naziv = bod.val().naziv;
             let bodovi = bod.val().bodovi;
             let grad = bod.val().grad;
             let drzava = bod.val().drzava;
             let sd = bod.val().sd;
             let slika = bod.val().slika;
             let potvrda= bod.val().potvrda;
           
             ff.push({
               uid: uid,
               key: key,
               id: id,
               datum: datum,
               naziv: naziv,
               bodovi: bodovi,
               grad: grad,
               drzava: drzava,
               sd: sd,
               slika: slika,
               potvrda: potvrda,
             
             });
   
           
           });
           v.selectedIndex=0;
           this.setState({ bodoviNoviO: ff });
           
          
         })
 
 
          }).then((snapshot) => {
          this.sleep(500).then(r => {alert("Edukacija je dodana!")});
          this.akcija(this.state.clan);
          ;
 
           });
     

        });
      }
    }
      }

      brisiBodove = () =>{

        if (window.confirm("Obriši selektovanu edukaciju?")) {
          let dugme = document.getElementById("brisiBodove");
          dugme.disabled = true;
          try{
          let v = document.getElementById("selektovanDogadjajI");
          let selektovanDogadjaj = v.options[v.selectedIndex].value.split(";");
          let vrstaDogadjaja = selektovanDogadjaj[0];
          let kljucDogadjaja = selektovanDogadjaj[1];
          let slikaDogadjaja = selektovanDogadjaj[2];
       
          if(vrstaDogadjaja=="nove"){

          obrisiDogadjajN(this.state.clan.uid, kljucDogadjaja).then(() => {
       
          dugme.disabled = false;

        bodoviN().then((snapshot) => {
          let ff = [];
           snapshot.forEach((clan) => {
             var uid = clan.key;
             clan.forEach((bod) => {
             
             let key =bod.key;
             let id =bod.val().id;
             let datum = bod.val().datum;
             let naziv = bod.val().naziv;
             let bodovi = bod.val().bodovi;
             let grad = bod.val().grad;
             let drzava = bod.val().drzava;
             let sd = bod.val().sd;
             let slika = bod.val().slika;
             let potvrda= bod.val().potvrda;
           
             ff.push({
               uid: uid,
               key: key,
               id: id,
               datum: datum,
               naziv: naziv,
               bodovi: bodovi,
               grad: grad,
               drzava: drzava,
               sd: sd,
               slika: slika,
               potvrda: potvrda,
             
             });

           });
          
         })
   
           this.setState({ bodoviNoviO: ff },()=>{this.akcija(this.state.clan)});
           this.sleep(500).then(r => {alert("Edukacija je obrisana!")});
           })

          });
          } else {
            
          obrisiDogadjajS(this.state.clan.uid, kljucDogadjaja).then(() => {
         

          let clan = this.state.clan;

          let bazaI=this.state.baza
          for (let i = 0; i < bazaI.length; i++) {
           if(bazaI[i].uid==clan.uid){
              let filter=[];
              bazaI[i].bodovi.map((bod) => {
                
               if(bod.id==kljucDogadjaja){

               }else{
                 filter.push(bod);
               }

              });
              clan.bodovi=filter;
              bazaI[i].bodovi=filter;

         }

        }

        this.setState({ clan: clan});
         this.setState({ baza: bazaI },()=>{ this.kongresiForma(clan);});
        
         this.sleep(500).then(r => {alert("Edukacija je obrisana!")});
              dugme.disabled = false;


            });
          }


        if (slikaDogadjaja == null || slikaDogadjaja == "" || slikaDogadjaja == undefined || slikaDogadjaja == "1" ||slikaDogadjaja == "undefined") {
           alert("If uslov")
        }
        else{


        
          var ref1 = firebase.storage().refFromURL(slikaDogadjaja);    
          ref1
            .delete()
            .then(function () {
              
            })
            .catch(function (error) {
             alert("Greska pri brisanju slike");
            });



        }
       
        } catch (err) {
          dugme.disabled = false;
          alert("Niste selektovali ni jedanu edukaciju!");
        }
 
        }
      }

      brisiSlikuEdukacije = () =>{

        if (window.confirm("Obriši sliku edukacije?")) {
          let dugme = document.getElementById("brisiSlikuEdukacijeI");
          dugme.disabled = true;
          try{
          let v = document.getElementById("selektovanDogadjajI");
          let selektovanDogadjaj = v.options[v.selectedIndex].value.split(";");
          let vrstaDogadjaja = selektovanDogadjaj[0];
          let kljucDogadjaja = selektovanDogadjaj[1];
          let slikaDogadjaja = selektovanDogadjaj[2];
       
          if(vrstaDogadjaja=="nove"){

            obrisiSlikuEdukacijeN(this.state.clan.uid, kljucDogadjaja).then(() => {
       
          dugme.disabled = false;

        bodoviN().then((snapshot) => {
          let ff = [];
           snapshot.forEach((clan) => {
             var uid = clan.key;
             clan.forEach((bod) => {
             
             let key =bod.key;
             let id =bod.val().id;
             let datum = bod.val().datum;
             let naziv = bod.val().naziv;
             let bodovi = bod.val().bodovi;
             let grad = bod.val().grad;
             let drzava = bod.val().drzava;
             let sd = bod.val().sd;
             let slika = bod.val().slika;
             let potvrda= bod.val().potvrda;
           
             ff.push({
               uid: uid,
               key: key,
               id: id,
               datum: datum,
               naziv: naziv,
               bodovi: bodovi,
               grad: grad,
               drzava: drzava,
               sd: sd,
               slika: slika,
               potvrda: potvrda,
             
             });

           });
          
         })
   
           this.setState({ bodoviNoviO: ff },()=>{this.akcija(this.state.clan)});
           this.sleep(500).then(r => {alert("Slika edukacije je obrisana!")});
           })

          });
          } else {
            
            obrisiSlikuEdukacijeS(this.state.clan.uid, kljucDogadjaja).then(() => {
         

          let clan = this.state.clan;

          let bazaI=this.state.baza
          for (let i = 0; i < bazaI.length; i++) {
           if(bazaI[i].uid==clan.uid){
              let filter=[];
              bazaI[i].bodovi.map((bod) => {
                
               if(bod.id==kljucDogadjaja){
                if (slikaDogadjaja == null || slikaDogadjaja == "" || slikaDogadjaja == undefined || slikaDogadjaja == "1" ||slikaDogadjaja == "undefined") {

                }else{
                  bod.slika="";
                }
                 
                filter.push(bod);
               }else{
                 filter.push(bod);
               }

              });
              clan.bodovi=filter;
              bazaI[i].bodovi=filter;

         }

        }

        this.setState({ clan: clan});
         this.setState({ baza: bazaI },()=>{ this.kongresiForma(clan);});
        
         this.sleep(500).then(r => {alert("Slika je obrisana!")});
              dugme.disabled = false;


            });
          }


        if (slikaDogadjaja == null || slikaDogadjaja == "" || slikaDogadjaja == undefined || slikaDogadjaja == "1" ||slikaDogadjaja == "undefined") {

        }
        else{
          
          var ref1 = firebase.storage().refFromURL(slikaDogadjaja);    
          ref1
            .delete()
            .then(function () {
              
            })
            .catch(function (error) {
              alert("Greska pri brisanju slike");
            });
        }
       
        } catch (err) {
          dugme.disabled = false;
          alert("Niste selektovali ni jedanu edukaciju!");
        }
 
        }
      }


      dodajSlikuDoc = (uri) => {
        
        let clan = this.state.clan;  
        let uid = clan.uid.toString();
        let v = document.getElementById("selektovanDogadjajI");
        try{
        let selektovanDogadjaj = v.options[v.selectedIndex].value.split(";");
        let vrstaDogadjaja = selektovanDogadjaj[0];
        let kljucDogadjaja = selektovanDogadjaj[1];
        let slika = selektovanDogadjaj[2];
      
        if (slika == null || slika == "" || slika == undefined || slika == "1" ||slika == "undefined") {

        }
        else{
          
          var ref1 = firebase.storage().refFromURL(slika);    
          ref1
            .delete()
            .then(function () {
              //alert("Dokument " + naziv + " obrisan!");
            })
            .catch(function (error) {
              alert("Greska pri brisanju slike");
            });
        }



        var storageRef = firebase.storage().ref();
        var ref = storageRef.child("SlikeDogadjaja");
 
          var d = new Date();
          var n = d.getTime();
    
          var array, binary, i;
          binary = atob(uri.split(",")[1]);
          array = [];
          i = 0;
          while (i < binary.length) {
            array.push(binary.charCodeAt(i));
            i++;
          }
          let file = new Blob([new Uint8Array(array)], {
            type: "image/jpeg",
          });
          
          const uploadTask = ref.child(uid).child(n.toString()).put(file);
          
          uploadTask.on(
            "state_changed",
            function (snapshot) {},
            function (error) {
         
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        

                  if(vrstaDogadjaja=="nove"){
                  var gdje = "/bodoviNovi/"+ uid +"/"+ kljucDogadjaja;   
                  upisiSlikuDok(gdje, downloadURL.toString()).then(() => {
                    bodoviN().then((snapshot) => {
                      let ff = [];
                       snapshot.forEach((clan) => {
                         var uid = clan.key;
                         clan.forEach((bod) => {
                         
                         let key =bod.key;
                         let id =bod.val().id;
                         let datum = bod.val().datum;
                         let naziv = bod.val().naziv;
                         let bodovi = bod.val().bodovi;
                         let grad = bod.val().grad;
                         let drzava = bod.val().drzava;
                         let sd = bod.val().sd;
                         let slika = bod.val().slika;
                         let potvrda= bod.val().potvrda;
                       
                         ff.push({
                           uid: uid,
                           key: key,
                           id: id,
                           datum: datum,
                           naziv: naziv,
                           bodovi: bodovi,
                           grad: grad,
                           drzava: drzava,
                           sd: sd,
                           slika: slika,
                           potvrda: potvrda,
                         
                         });
            
                       });
                      
                     })
               
                       this.setState({ bodoviNoviO: ff },()=>{this.kongresiForma(this.state.clan)});
                       alert("Slika edukacije je dodana");
                       })


                  });
                  

                }               
                  else{               
                  var gdje = "/clan/"+ uid +"/bodovi/"+ kljucDogadjaja;  

                  upisiSlikuDok(gdje, downloadURL.toString()).then(() => {

                    for (let i = 0; i < clan.bodovi.length; i++) {
                        if(clan.bodovi[i].id==kljucDogadjaja){
                          clan.bodovi[i].slika=downloadURL.toString();
                        }

                    }
                   
                    let bazaI=this.state.baza
                    for (let i = 0; i < bazaI.length; i++) {
                     if(bazaI[i].uid==clan.uid){

                      for (let j = 0; j < bazaI[i].bodovi.length; j++) {
                        if(bazaI[i].bodovi[j].id==kljucDogadjaja){
                          bazaI[i].bodovi[j].slika=downloadURL.toString();
                        }

                    }
   
                     }
                   }
                   this.setState({ clan: clan});
                   this.setState({ baza: bazaI },()=>{ this.kongresiForma(clan);});
                    alert("Slika edukacije je dodana");

  
                  });
                }

            }
            
          );
            

          });
        
        }
        catch(ero){
          alert("Niste selektovali ni jedanu edukaciju")
        }
          
      };


      slikeUbaci = (slika) => {
       
        let v = document.getElementById("sliketina");
        let sss=logoa4
       if(slika==undefined || slika==null || slika==""|| slika=="undefined" ){
        this.setState({ uriDokumenta: sss});
        v.src = sss;
       }else{
       
          v.src = slika;
          this.setState({ uriDokumenta: slika});
          
      }
     
       
      
      };

      fileChangedHandlerD = (event) => {
        var fileInput = false;
        if (event.target.files[0]) {
          fileInput = true;
        }
        if (fileInput) {
          Resizer.imageFileResizer(
            event.target.files[0],
            800,
            1200,
            "JPEG",
            90,
            0,
            (uri) => {
            
             
              let v = document.getElementById("sliketina");
              let staro=v.src;
              v.src = uri;
              
               this.sleep(500).then(r => {
                       if (window.confirm("Dodaj sliku edukacije?")) {
                        this.dodajSlikuDoc(uri);
                        //this.setState({ uriDokumenta: uri});
                       }else{
                        v.src = staro;
                          }
                    })

            },
            "base64"
          );
        }
      };

      handleShowDialog = () => {
        this.setState({ zoomSlika: (<div>

            <dialog           
            //style={this.stylesZoom}
            className="popup"
            open
            onClick={this.ukiniSliku}
          >
            <img
              className="image"
              src={this.state.uriDokumenta}
              onClick={this.ukiniSliku}
              alt="no image"
            />
          </dialog>


        </div>) });
     
      };

      ukiniSliku = () => {
        this.setState({ zoomSlika:""});

      }



    render() { 
        let i = 1;
        let ordi=[];
        this.state.ordinacijeO.map((d) => {
          var naziv=d.naziv;
          naziv=naziv.replace("\"","")
          naziv=naziv.replace("\"","")
          naziv=naziv.replace("ZU stom.ambu.","")
          naziv=naziv.replace("ZU spec.stom.ambu.","")
          naziv=naziv.trim();
          naziv=naziv.trimStart();
          var id=d.id;

          ordi.push({
            naziv: naziv,
            id: id,
          })

         ordi= ordi.sort((a,b) => a.naziv > b.naziv ? 1 : b.naziv > a.naziv ? -1 : 0);
        })
        return (
        <div>
         <div style={{ float: "left" }}>
          <div>
            <div>
              <div  id="brojClanova" style={this.styleBroj} className="ms-2 mt-3">
                {this.state.brojClanova}
              </div>
            <input
              style={this.stylePretraga}
              className="ms-1 mt-3"
              type="text"
              name="enter"
              id="filterSlova"
              placeholder=" pretraži po imenu,prezimenu,jmbg,broju licence"
            />
            </div>
            <div style={{float: "left"}}>
              <Input id="filterRS" as="select" style={this.styleSelekcija}
               className="ms-2 mt-1">
               <option value="sve">SVE</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="1">BANJA LUKA</option>
              <option value="2">PRIJEDOR</option>
              <option value="3">BIJELJINA</option>
              <option value="4">DOBOJ</option>
              <option value="5">ZVORNIK</option>
              <option value="6">ISTOČNO SARAJEVO</option>
              <option value="7">FOČA</option>
              <option value="8">TREBINJE</option>
              <option value="9">OSTALO</option>
              <option value="10">BRČKO</option>
              
              
            </Input>
            </div>

            <div style={{float: "left"}}>
              <Input id="filterST" as="select" style={this.styleSelekcija}
               className="ms-1 mt-1">
               <option value="sve">SVE</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="1">AKTIVAN ČLAN</option>
              <option value="2">NEAKTIVAN ČLAN</option>
              <option value="3">ISKLJUČEN ČLAN</option>
              <option value="4">UMRLI ČLAN</option>
             
              

            </Input>
            </div>

            <div >
            <Input id="filterORD" as="select" style={this.styleSelekcija2} className="ms-2" >
            <option value="sve">SVE</option>
            {ordi.map((d) => {
                     
                        return (
                          <option
                           
                            value={
                              d.id
                            }
                          >
                            {d.naziv}
                          </option>
                        );
                      })}
            </Input>

            </div>

          </div>
          <div className="ms-2 mt-2" style={this.styleListaClanova}>
            {this.state.bazaP.map((clan) => (
              <div>
                <button
                  className="btn btn-outline-danger m-1 clanoviButton"

                  onClick={() => this.akcija(clan)}
                  key={clan.ime}
                >
                  {i++ }. {clan.ime} {clan.prezime} {clan.broj} {clan.titulaS}
                  
                </button>
              </div>
            ))}
            <ExcelFile
            filename={
             "Clanovi KDS RS"
            }
            element={
              <Button
              className="btn btn-primary centerButton"
              type="submit"
              style={{ width: "200px" }}
              className="m-3"
              size="sm"
              variant="danger"
               
              >
                Snimi tabelu članova
              </Button>
            }
          >
            <ExcelSheet
              data={this.state.bazaP}
              name={"Clanovi"}
            >
              <ExcelColumn label="Ime" value="ime" />
              <ExcelColumn label="Prezime" value="prezime" />
              <ExcelColumn label="Broj" value="broj" />
              <ExcelColumn label="JMBG" value="jmbg" />
              <ExcelColumn label="Postanski broj" value="postanskiBroj" />
              <ExcelColumn label="Grad" value="mejestoIDS" />
              <ExcelColumn label="Adresa" value="adresa" />
              <ExcelColumn label="Telefon" value="telefon" />
              <ExcelColumn label="Email" value="mail" />
              <ExcelColumn label="Ordinacija" value="ordinacijaIdS" />
   
            </ExcelSheet>

          </ExcelFile>
          </div>
        </div>
    
        <div style={{ float: "left" }}>{this.state.profil}</div>
        <div style={{ float: "left" }}>{this.state.uplateSifre}</div>
        <div style={{ float: "left" }}>{this.state.kongresiEkran}</div>
        <div style={{  position: 'absolute' }}>{this.state.zoomSlika}</div>

        </div>);
    }
}


 
export default Clanovi;